// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

@mixin clearfix {
  &::after {
      content: "";
      display: table;
      clear: both;
  }
}
@mixin respond($breakpoint) {
  @if $breakpoint == sm {
      @media only screen and (max-width: 575px) { @content };    
  }
  @if $breakpoint == md {
      @media only screen and (max-width: 767px) { @content };   
  }
  @if $breakpoint == lg {
      @media only screen and (max-width: 991px) { @content };   
  }
  @if $breakpoint == xl {
      @media only screen and (max-width: 1199px) { @content };    
  }
  @if $breakpoint == xxl {
    @media only screen and (max-width: 1599px) { @content };    
  }
}

@mixin text-shorten {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@mixin arrow($direction: down, $size: 5px, $color: #555) {
  width: 0;
  height: 0;
  @if ($direction == left) {
     border-top: $size solid transparent;
     border-bottom: $size solid transparent;
     border-right: $size solid $color;
  }
  @else if ($direction == right) {
     border-top: $size solid transparent;
     border-bottom: $size solid transparent;
     border-left: $size solid $color;
  }
  @else if ($direction == down) {
     border-left: $size solid transparent;
     border-right: $size solid transparent;
     border-top: $size solid $color;
  }
  @else {
     border-left: $size solid transparent;
     border-right: $size solid transparent;
     border-bottom: $size solid $color;
  }
}


@mixin customScroll($direction:vertical, $trackColor: #f1f1f1, $thumbColor: #888, $width: 8px, $hoverWidth: 3px, $height: 5px){

  ::-webkit-scrollbar-track {
    background: $trackColor;
  }
  ::-webkit-scrollbar-thumb {
    background: $thumbColor;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @if ($direction == vertical) {
    ::-webkit-scrollbar {
      width: $hoverWidth;
    }
    *:hover::-webkit-scrollbar {
      width: $width;
    }
  }
  @else {
    ::-webkit-scrollbar {
      height: $height;
    }
    *:hover::-webkit-scrollbar {
      height: $height;
    }
  }
}
@mixin chevron($direction:up, $color: #333, $size: 10px){
  content: "";
  display: inline-block;
  width: $size;
  height: $size;
  border-bottom: 2px solid;
  border-left: 2px solid;
  color: $color;
  @if ($direction == left) {
    transform: rotate(45deg);
  }
  @else if ($direction == right) {
    transform: rotate(-135deg);
  }
  @else if ($direction == down) {
    transform: rotate(-45deg);
  }
  @else {
    transform: rotate(135deg);
  }
}