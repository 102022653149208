.custom_overlay_panel_column{
    width: 40%;
    height: 230px;
    margin-left: 10px;
    padding-left: 20px;
}

.gear-icon{
    max-width: 20px;
    min-width: 20px;
}
.settings-icon {
    width: 20px;
    height: 20px    ;
}

.table-position{
    position: relative;
}

.gear-position{
    z-index: 9;
    position: absolute;
    margin-top: 40px;
    margin-left: 1020px;
}