.form-row {
    &.alert {
        margin: 25px 0 25px 3px;
        padding: 0;      
        @media (min-width: 992px) {
            margin: 25px 0 5px 3px;
        }
        .col {
            padding: 0;
            margin: 0;
        }
        p {
            margin-bottom: 0;
            font-size: 20px;
            display: inline-block;
            margin-left: 10px;          
            color: #4E4C4A;
            font-family: 'EtihadAltis-Book'; 
            padding-left: 40px;
        }
        .image-wrapper{
            margin-left: 1rem;
            width: 32px;
            padding-bottom: 4px;
            padding-right: 4px;
            position: absolute;
            margin-top: 2px;
        }
        .common-alert-icon-wrapper{
            display: inline-block;          
            padding: 25px 20px;           
            width: 32px;        
            margin-left: 2rem;
            background-position: center;
            background-size: cover;
        }

        .alert-success-wrapper{
            background-color: #E4EBCC !important;
            border-color: #E4EBCC !important;    
        }
        .alert-success-tick{
            @extend .common-alert-icon-wrapper;
            background: url('../../assets/images/Check-mark-filled.png') no-repeat;
        }        
        .alert-error-wrapper{
            background-color: #F5D5D6;              
        }
        .alert-error-close{
            @extend .common-alert-icon-wrapper;
            background: url('../../assets/images/Error-Close-Filled.png') no-repeat;
        }
        // .alert-close {
        //     display: inline-block;
        //     width: 79px;
        //     height: 79px;
        //     padding: 20px 20px;
        //     float: right;
        //     background: transparent;
        //     border-left: 1px solid #4E4C4A;
        //     background: url('../../assets/images/icon-close-alert.png') no-repeat;
        //     background-position: center;
        // }
         .alert-info-wrapper{
            background-color: #E8F1F4 !important;
            border-color: #E8F1F4 !important;    
        }  
        .alert-info-icon{
            @extend .common-alert-icon-wrapper;
            background: url('../../assets/images/notification-info.svg') no-repeat;
        } 
        .alert-warning-wrapper{
            background-color: #FDE8D5 !important;
            border-color: #FDE8D5 !important;
        }
        .alert-warning-icon{
            @extend .common-alert-icon-wrapper;
            background: url('../../assets/images/notification-warning.svg') no-repeat;
        }
    }
    .alert-inner-wrapper{
        padding: 24px 24px 24px 0px;
    }
  
}