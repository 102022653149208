// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
  font-family: 'EtihadAltis-Book';
  src: url('../../fonts/EtihadAltis-Book_V3.eot');
  src: url('../../fonts/EtihadAltis-Book_V3.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/EtihadAltis-Book_V3.woff') format('woff')
}
@font-face {
  font-family: 'EtihadAltis-Medium';
  src: url('../../fonts/EtihadAltis-Medium_V3.eot');
  src: url('../../fonts/EtihadAltis-Medium_V3.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/EtihadAltis-Medium_V3.woff') format('woff')
}
@font-face {
  font-family: 'EtihadAltis-Bold';
  src: url('../../fonts/EtihadAltis-Bold_V3.eot');
  src: url('../../fonts/EtihadAltis-Bold_V3.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/EtihadAltis-Bold_V3.woff') format('woff')
}
@font-face {
  font-family: 'EtihadAltis-Text';
  src: url('../../fonts/EtihadAltis-Text_V3.eot');
  src: url('../../fonts/EtihadAltis-Text_V3.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/EtihadAltis-Text_V3.woff') format('woff')
}