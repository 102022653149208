@import '../../../assets/scss/abstracts/abstracts';

.participant-details-wrapper{
    .participant-sub-title{
        font-family:$font-book;
        font-size: 20px;
        line-height: 25px;
        @media (min-width: 992px){              
            font-size: 24px;
            font-weight: 300;
            letter-spacing: 0px;
            line-height: 30px;
          }
        }
      
}