@import '../../assets/scss/abstracts/abstracts';

.item-group{
   box-shadow: 0px 1px 11px #00000029;
   padding: 12.5px 16px;
   margin-bottom: 11px;
   border-radius: 12px;
   @media (min-width: 992px) {
    padding: 12.5px 8px;
   }
   .close-button{
        height: 12px;
        float: right;
        margin: 5px;
        cursor: pointer;
    }
}
.delete-div{
    justify-content: flex-end;
    display: flex;
    margin-left: auto;
    margin-top: 35px;
}
.p-selectbutton .p-button{
    width: 66px
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover{
    background: white
}

.autocomplete{
    width: 100% !important;
}

.margin-negativeLeft10{
        margin-left: -10px
    }
    .border-left0,.border-left0 .p-dropdown{
        border-left:0
    }
    .borderRadius-L0,.borderRadius-L0 .p-dropdown{
        border-bottom-left-radius:0px;
        border-top-left-radius:0px;
        width: 60px;
    }
     .borderRadius-R0{
        border-bottom-right-radius:0px;
border-top-right-radius:0px;
border-right: none !important;
    }
    .cust-dropdown{
    background: #EDEDEC;
    .p-dropdown-trigger .pi-chevron-down{
        background: #EDEDEC !important;
    }
}
.p-dropdown{
    position: relative;
    .p-dropdown-trigger{
        position: absolute;
        right:0%;
        top: 50%;
        transform: translate(0, -50%);
        .pi-chevron-down{
            background: $white;
        }     
    }
}
.uld-form{
    .weight-div{
        width:100%;
        padding-left: 0px;
    }
    &__double_element{
        display: flex;
        input{
          width:60%;
        }
        .dropdown_div{
         width:30%;
        }
      }
    &__double_element.weight-div{
        input{
      width: 100%;
    }
    .dropdown_div{
     width:60%;
    }
  }
  .delete-section{
    height: 100%;
    margin-top: 3%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    span{
    font-size: rem(12)
    }
    float: right;
    padding-right: 30px;
}
    .info-message{
        background: white;
        color: #3B3B3A;
        margin-top: 30px;
    }
    .info-icon{
        margin-top: 26px;
        padding-right: 10px;
    }
    .pivot-message{
        display: flex;
    }
}

.add-overflow-link{
    color: $link;
    background: $white;
    margin-top: 10px;
    box-shadow: none;
    outline: none;
    padding: 20px 0px;
    font-family: $font-bold;
    font-size:18px;
    border: none; 
    box-shadow: none !important;  
}
.p-button.p-button-link:enabled:hover .p-button-label{
    text-decoration: none;
}
.overflow-accordian{
    padding-top: 20px;
}
#pivot-styling{
    font-size:16px;
    font-family:$font-book;
}