@import "../../assets/scss/abstracts/abstracts";

.flight-details {

   margin: 25px 0 0;
   @media (min-width: 992px) {
    margin: 20px 0 0;
   }  
   &_choose {
     width: 100%;
     height: 40px;
   } 
   .other-value{
     padding-right: 59px;
   }
   &__flyingHr{
     opacity: 0.8;
   }
   &__flightNo{
    color: $black;
    font-size: $flightNoFont;
    width: 20%;
    font-family:$font-medium;
    letter-spacing: -0.07px;
    text-transform: capitalize;
    opacity: 0.8;
    position:relative;
    display: flex;
    @media (min-width: 992px) {
      left:15px;
      display: block;
    }   
    & > div {
      &:first-child {
        border-left: 0;
        padding-left: 0;
      }
      border-left: 1px solid #4E4C4A;
      line-height: 12px;
      padding: 0 10px;
      font-size: 14px;
      font-family: 'EtihadAltis-Book';
      @media (min-width: 992px) {
        border-left: 0;
        line-height: normal;
        padding: 0;
        font-size: 12px;
        font-family:$font-medium;
      }   
    }
   }
   &__others{
    flex-direction: column;
    padding: 0;
    @media (min-width: 992px) {
      padding: 0 15px;
    }   
    .no-instant-booking {
      &.expanded {
        img {
          width: 24px;
        }
        span {
          font-size: 16px;
          font-family: 'EtihadAltis-Book';
          padding-left: 10px;
        }
      } 
    }
    .time-details {
      border-top: 1px solid #dcdbdb;
      margin-top: 20px;
      padding-top: 22px;
      @media (min-width: 992px) {
        border-top: 0;
        margin-top: 0;
        padding-top: 0;
        display: flex;
      }  
    }
    //  .weight-details {
      //  @media (min-width: 992px) {
      //   padding-left: 100px;
      //  }  
    //  }
    .chargeable-weight{
      font-size: 14px;
      font-family:$font-book;
      color: rgb(78, 76, 74);
      padding-bottom: 4px;
      &_wrap {
        @media (min-width: 992px) {
          padding-left: 100px;
        }
      }
      @media (min-width: 992px) {
        font-size: 16px;
        color: $black;
        opacity: 0.8;
        padding-bottom: 0;
      }  
      span{
        font-family:$font-book;
        font-size: 14px;
        @media (min-width: 992px) {
          font-size: 16px;
        }  
        
       
      }
    }
    .other-value{
      font-size: $controlFont;
      padding-bottom: 10px;
      color: #3B3B3A;
      font-family: $font-book;
      @media (min-width: 992px) {
        opacity: 0.8;
        flex: 1;
      }  
      .time-field{
      font-family:$font-book;
      color: #3B3B3A;
      font-size: 16px;
      margin-bottom: 0;
      @media (min-width: 992px) {
        opacity: 0.8;
      }  
      }
    }
   }
   &__routeDetails{
    display: block;
    margin-top: 0px;
    margin: 7px;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    strong{
      font-size: $controlFont;
      white-space: nowrap;
      letter-spacing: -0.09px;
    }
    span{
      font-size: $stationFontSize;
      color: $textcolor;
      letter-spacing: -0.08px;
      white-space: nowrap;
      @media (min-width: 992px) {
        opacity: 0.8;
      } 
      
    }
    // &:not(:first-child){
    //   margin-top: -12px !important;
    // }
   }
   &__expandedDetails{
              margin: 7px;
              display: flex;
              justify-content: space-between;
              padding: 0 12px 16px 12px;
              margin: 0;
              background: none;
              border: 0;
              display: block;
              @media (min-width: 992px) {
                display: flex;
                padding: 25px 12px 35px 12px;
                background: #fcfcfc;
                border-bottom-left-radius : 12px;
                border-bottom-right-radius : 12px;
              }
              .flight-details__route {
                margin-top: 26px;
                @media (min-width: 992px) {
                  margin-top: 0;
                }
              }
              .flight-details__segment-info{
                border: none;
                width: 100%;
                border-top: 1px solid rgb(220, 219, 219);
                padding: 0;
                padding-top: 24px;
                  // padding: 0 10px;
                  @media (min-width: 992px) {
                    width: 50%;
                    padding: 0;
                    border: none;
                    border-right: 1px dotted $black;
                    display: flex;
                    flex-direction: column;
                  }
              }
              
              .flight-details__routeDetails{
                display: flex;
                margin: 0px;
                //margin-bottom: 10px;
                background: none;
                align-items: center;
                align-items: flex-start;
                justify-content: stretch;
                flex-direction: column;
                width: 100%;
                @media (min-width: 992px) {
                  flex-direction: row;
                }    
              }

              .flight-details__flyingHr{
                opacity: 0.8;
              }
              .flight-details__flightNo{
               color: $black;
               font-size: 12px;
               width: 100%;
               font-family:$font-medium;
               opacity: 0.8;
               @media (min-width: 992px) {
                width: 20%;
               }    
              }

              .flight-details__layover{
                align-self: flex-end;
                text-align: center;
                display: flex;
                margin: 11px 0;
                margin-right: 6px;
                margin-top: 3px;
                width: 100%;
                flex-direction: column;
                align-items: center;
                @media (min-width: 992px) {
                  width: 80%;
                }    
                &:before{
                  display: none;
                }
                &:after{
                  display: none;
                }
                span{
                  border-radius: 10px;
                  margin: 5px 0px;
                  display: inline-block;
                  font-size: $stationFontSize;
                  padding: 6px 13px;
                  vertical-align: middle;
                  border: 0;
                  @media (min-width: 992px) {
                    border: 1px solid #EDEDEC;
                    background: $white;
                    padding: 3px 13px;
                  }    
                  
                }
                .segment-transit-line{
                  height: 20px;
                  width: 0;
                  border: none;
                  border-left: 1px solid #4E4C4A;
                  vertical-align: middle;
                  @media (min-width: 992px) {
                    height: 13px;
                    border-left: 1px solid $black;
                  }    
                }
              }

              .flight-details__departure{
                display: inline-flex;
                flex-direction: column;
                line-height: 1;
          
              }
              .flight-details__timing strong{
                font-size: 16px;
                @media (min-width: 992px) {
                  font-size: 15px;
                }  
              }
              .flight-details__timing span{
                font-size: 14px;
                font-family:$font-book;
                margin: 6px 0 4.5px 0;
                @media (min-width: 992px) {
                  font-size: 13px;
                  font-family:$font-family;
                }  
              }

            }
              &__route{
              position: relative;
              margin: 0;
              border: none;
              width: 100%;
              justify-content: space-evenly;
              @media (min-width: 992px) {
                width: 80%;
                margin: 0px 10px;
              }    
            }
  &__allotment-wrapper{
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 17px;
  }
  &__allotment-description {
    margin-left: 6px;
    margin-right: 27px;
    font: normal normal normal 15px/20px;
    color: $black;
  }
  &__allotment-indicator {
    position: absolute;
    height: 23.62px;
    width: 17px;
    right: 0px;
    background-color: $lightGreen;
    
    &::before {
      content: "A";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      color: $white;
      font: normal normal bold 12px/9px;
      
    }
    &::after {
      content: " ";
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
      border-color: transparent;
      border-left: 8.5px solid transparent;
      border-right: 8.5px solid transparent;
      border-top: 10px solid transparent;
      border-bottom: 4px solid $white;
      
    }
  }
  &__allotment-indicator__flight-item {
    position: absolute;
    height: 23.62px;
    width: 17px;
    left: 15px;
    top: -1px;
    background-color: $lightGreen; 
    
    &::before {
      content: "A";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      color: $white;
      font: normal normal bold 12px/9px;
      
    }
    &::after {
      content: " ";
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
      border-color: transparent;
      border-left: 8.5px solid transparent;
      border-right: 8.5px solid transparent;
      border-top: 10px solid transparent;
      border-bottom: 4px solid $white;
      
    }
  }
  &__wrap {
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 25%);
    border-radius: 12px;
  }
  &__item {
    display: flex;
    align-items: center;
    // border: solid 1px $border;
    padding: 0.5rem rem(10);
    position: relative;
    flex-wrap: nowrap;
    margin-top: 25px;
    justify-content: space-between;
    cursor: pointer;
    .logo{
      width: 20%;
      img{
        margin-top: -7px;
        margin-left: 3px;
        height: 36px;
        width: 68px;
      }
    }
    @include respond(lg) {
      padding: 2.2rem 1rem 1rem 1rem;
      flex-direction: column;
    }
  }
        &__routes {
      text-align: center;
    display: flex;
    padding: 0;
    // margin-bottom: -11px;
    font-size: $controlFont;
    font-family: $font-book;
    @media (min-width: 992px) {
      margin-left: -7px;
      display: block;
      opacity: 0.8;
      font-size: $stationFontSize;
      font-family: $font-family;
    }   
    & > span {
      flex: 1;
    }
    &-dep {
      text-align: left;
    }
    &-arr {
      text-align: right;
    }
    }

  &__expanded{
    border: solid 1px $border;
    border-top:0;
    position: relative;
    flex-wrap: nowrap;

     &__timing {
    display: flex;
    min-width: 167px;
    padding-bottom: 0;
    // margin-top: -1.6rem;
      @media (min-width: 992px) {
        justify-content: space-evenly;
      }
    strong {
      font-size: $controlFont;
      font-family: $font-book;
      @media (min-width: 992px) {
        font-family: $font-medium;
        margin-top: 0;
      }   
    }
    span{
      font-size: $stationFontSize;
      padding: 1px 0px;
       
     
    }
  }

  }
  &__arrowUp{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    line-height: 1;
    cursor:pointer;
    align-self: center;
    i img{
      width: 18px;
    }
  }
  &__flyingHr{
    text-align: center;
    display: block;
    padding: 0;
  }
   &__layover{
                align-self: flex-end;
                text-align: center;
                display: flex;
                margin: 11px 0;
                margin-right: 6px;
                margin-top: 3px;
                width: 100%;
                flex-direction: column;
                align-items: center;
                &:before{
                  display: none;
                }
                &:after{
                  display: none;
                }
                span{
                  border: 1px solid #EDEDEC;
                  border-radius: 10px;
                  margin: 5px 0px;
                  display: inline-block;
                  font-size: $stationFontSize;
                  padding: 3px 13px;
                  vertical-align: middle;
                  background: $white;
                  
                }
                .segment-transit-line{
                  height: 13px;
                  width: 0;
                  border: none;
                  border-left: 1px solid $black;
                  vertical-align: middle;
                }
              }
  &__timing {
    display: flex;
    min-width: 167px;
    padding-bottom: 0;
    // margin-top: -1.6rem;
    & > div {
      flex: 1;
      @media (min-width: 992px) {
        flex: auto;
      }
    }
      @media (min-width: 992px) {
        justify-content: space-evenly;
      }
      li {
        flex: 1;
        @media (min-width: 992px) {
          flex: none;
        }
      }
    strong {
      font-size: $controlFont;
      font-family: $font-book;
      font-weight: 300;
      margin-top: 5px;
      margin-bottom: 5px;
      @media (min-width: 992px) {
        font-family: $font-medium;
        font-weight: 600;
      }   
    }
        span{
      font-size: $stationFontSize;
          padding: 1px 0px;
         font-family: $font-book;
         margin: 4.5px 0;
    }
  }
  &__departure {
    display: flex;
    flex-direction: column;
    line-height: 1;
    white-space: nowrap;
    @media (min-width: 992px) {
      align-items: flex-end;
    }  
  }
  &__arrival {
    display: flex;
    flex-direction: column;
    line-height: 1;
    position: relative;
    white-space: nowrap;
    text-align: right;
    @media (min-width: 992px) {
      text-align: left;
    }  
  }
  .flight-details__expandedDetails{
   .flight-details__routeDetails{
    display: flex;
    margin-top: 0px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
   }
  .flight-details__departure{
        display: inline-flex;
    flex-direction: column;
    line-height: 1;
      }

  }
   &__sub-connection{
     display: flex;
    flex-direction: row;
    align-items: center;
   }
  &__connection{
              display: flex;
              width: 100%;
              max-width: 250px;
              align-content: space-between;
              align-items: flex-start;
              margin: 0 7px;
              flex-direction: row;
              padding: 8px 21px 0 21px;
              /* position: relative; */
              @media (min-width: 992px) {
                padding: 0;
                padding-top: 8px;
              }  
              .origin-destination-icon{
                height: 8px;
                width: 8px;
                border: 1px solid $primary;
                border-radius: 50%;
                background-color: transparent;
                // &.dark {
                //   background-color: $primary;
                // }
              }
              .iconsSection{
                width: 24px;
                height: 22px;
                display: flex;
                align-items: center;
                // background: #bd8b13;
                border-radius: 50%;
                justify-content: center;
                margin-top: -7px;
                padding-left: 1px;
              }
              .transit-line{
                flex-grow: 1;
                height: 0;
                border: none;
                border-top: 1px dotted $primary;
                margin-top: 4px;
              }
            
      }
  &__left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: rem(20) 0;
    width: 100%;
    @media (min-width: 992px) {
      width: 50%;
    } 
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    flex-direction: column;
    @media (min-width: 992px) {
      width: 50%;
      padding: rem(20) 0;
      flex-direction: row;
    } 
    &__service{
      width: 30%;
    }
    &__sub{
      width: 100%;
      display: flex;
      padding: 0;
      align-items: center;
      justify-content: space-between;
      margin: 24px 0 12px 0;
      @media (min-width: 992px) {
        width: 70%;
        margin: 0;
        padding: 0 5px;
      } 
      &__pricing{
        width: 80%;
        font-family: $font-medium;
        margin-right: 25px;
        flex: 1;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        @media (min-width: 992px) {
          text-align: right;
          width: 50%;
        } 
        .rate-type {
          color: $green;
          font-size: 12px;
          font: italic normal medium;
          letter-spacing: -0.07px;
          text-align: right;
          opacity: 0.8;
        }
        .no-instant-booking {
          font-size: 12px;
          font-family: $font-medium;
          color: $textcolor;
          border: 1px solid #C7C6C6;
          border-radius: 14px;
          text-align: center;
          padding: 4px 8px;
          width: auto;
          margin-top: 2px;
          display: inline-block;
          &.expanded {
            img {
              width: 24px;
            }
            span {
              font-size: 16px;
              font-family: 'EtihadAltis-Book';
              padding-left: 10px;
            }
          } 
        }
        .no-rates {
          font-size: 14px;
          font-family: $font-family;
          color: $textcolor;
          text-align: left;
          padding: 4px 8px 4px 0;
          width: 100%;
          margin-top: 2px;
          display: inline-block;
          @media (min-width: 992px) {
            padding: 4px 8px;
            text-align: center;
            width: auto;
          } 
        }
      }
      &__amount{
        @media (min-width: 992px) {
          line-height: 1;
        } 
        > label {
          font-size: 16px;
          position: relative;
          white-space: nowrap;
          color: $primary;
          margin-bottom: 0;     
          letter-spacing: -0.13px; 
          @media (min-width: 992px) {
            font-size: 22px;
          } 
          > em {
            position: absolute;
            font-size: rem(13);
            font-style: normal;
            top: -10px;
            right: 20px;
          }
        }
        span {
          color: $primary;
        }
      }
      &__rate{
        > label {
          position: relative;
          margin-bottom: 0;
          font-size: 14px;
          color: $textcolor;
          letter-spacing: -0.09px;
          font-family: $font-book;
          @media (min-width: 992px) {
            font-size: 15px;
            font-family: $font-medium;
          } 
        }
      }
    }
  }
  &__elapse{
    text-align: center;
    display: block;
    padding: 0;
    margin-top: -22px;
    font-size: $stationFontSize;
    color: $textcolor;
    font-family: $font-book;
    position: absolute;
    width: 100%;
    @media (min-width: 992px) {
      margin-top: -47px;
      opacity: 0.8;
      font-family: $font-family;
    } 
  }
 
  .fd-header {
    display: flex;
    // width: 100%;
    // justify-content: space-between;
    align-items: center;
    @media (min-width: 992px) {
      white-space: nowrap;
    }    
    .flights_count {
      width: 50%;
      @media (min-width: 992px) {
        width: auto;
      }    
    }
    &__right {
      display: inline-flex;
      align-items: center;
    }
    &__left {
      display: inline-flex;
    }
    &__results{
      font-size:$rateFont;
      font-family: $font-book;
      //  margin: auto;
      color: $titleColor;
      @media (min-width: 992px) {
        font-size:$subTitleFont;
        font-family: $font-bold;
      }    
      @include respond(sm) {
        margin-bottom: 10px;
      }
    }
    &__sort {
      color: $textcolor;
      margin-left: rem(20);
      &.has-drop-arrow {
        padding-right: 15px;
        position: relative;
        &:after {
          position: absolute;
          right: 0;
          @include chevron($color: $textcolor, $direction: down, $size: 7px);
          top: 5px;
        }
      }
      > label{
        color: $grey;
        margin-right: 5px;
      }
    }
    &__tab {
      display: flex;
      border: solid 1px $border;
      border-radius: 4px;
      overflow: hidden;
      a {
        padding: rem(8) rem(15);
        display: inline-block;
        &:hover {
          background: $textcolor;
          color: $white;
        }
        &.active{
          background: $textcolor;
          color: $white;
        }
      }
      li + li {
        border-left: solid 1px $border;
      }
    }
  }

}
.borderB0{
      border-bottom:0 !important;
    }
    .borderT0{
      border-top:0 !important;
    }
    .border-expand{
      border: solid 1px $textcolor;
    }
     .no-data{
    
    display: flex;
    flex-wrap: nowrap;
    border: 1px solid $border;
    padding: 9px 10px;
    margin: 25px 0;
    font-family: $font-medium;
    box-shadow: 0px 3px 6px #2D1F5008;
    font-size: 14px;
    justify-content: flex-start;
    @media (min-width: 992px) {
      margin: 25px 20px;
    }    

  }
  #flight-label{
      @media (min-width: 992px) {
      margin-top: -38px;
    } 
  }