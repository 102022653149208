@import '../../assets/scss/abstracts/abstracts';

.autocomplete{
    
    &_item{
        display: flex;
        flex-flow: nowrap;
        // &_main{
    
        // }
        &_sub{
            display: flex;
            flex-flow: nowrap;
            margin-left: auto;
            align-items: center;
            &_item{
                padding: 2px 6px;
                margin: 0 4px;
                display: inline-block;
                border: 1px solid $darkGrey;
                font-size: 13px;
                background-color: #e1eaf6;
                border-radius: 4px;
                font-family: $font-bold;
                color: $textcolor
            }
        }
    }
}
.shipping-item-row{
    .input-wrapper{
        .selected-item_sub{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 9%;
            align-items: center;
            max-width: 50%;
            justify-content: right;
            &_item{
                background: $sccBgColor;
                border: 1px solid $darkGrey;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                margin-right: 3px;
                max-width: 40%;
                font-size: 13px;
                font-family: $font-bold;
            }
            :first-child{
                max-width: 70%;
            }
        }
        // .selected-item_sub,.nodrop-down{
        //     right: 0;
        // }
        .p-autocomplete{
            button{
              position: absolute;
              top: 0; 
              bottom: 0;
              right: 0;
              width: 8%;
              background-color: white;
              border: none;
              box-shadow: none;
              .pi-chevron-down{
                color: #848484;
              }
              &:hover{
                background-color:  white;
              }
            }
          }
    }
    
}
