@import '../../../assets/scss/abstracts/abstracts';

.quote-details__filghtDetails{
          @media (min-width: 992px) { 
            border: 1px solid #959492;
            border-radius: 2px;
            padding: 10px;
            margin: 0 0 16px;
          }
          h4{
            font-size: 16px;
            font-family:$font-book;
            font-weight: bold;
            margin-bottom: 0;
            span{
              font-family:$font-book;
              font-size: 16px;
              
            }
          }

          .flight-details{
            &__summary{
              display: block;
              min-height: 72px;
              margin: 0;
              padding: 0;
              border: 0;
              position: relative;
              cursor: pointer;
              @media (min-width: 992px) {  
                display: flex;
                cursor: default;
              }
              &-trigger {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 99999;
                cursor: pointer;
              }
            }
            &__summary-route{
              border:0;
              padding: 0;
              margin: 0;
              display: flex;
              min-height: 72px;
              width: 100%;
              @media (min-width: 992px) {   
                width: 50%;
                padding: 0 8px;
              }
              .logo{
                width: 20%;
                padding: 5px 0;
                display: flex;
                align-items: center;
                img{
                  height: 42px;
                }
              }          
            }
            &__route{
              position: relative;
              border: none;
              width: 100%;
              justify-content: space-evenly;
              @media (min-width: 992px) {
                width: 80%;
                margin: 0 10px;
              }
            }
            &__routes{
              // position: absolute;
              // top: -16px;
              // left: 46%;
              display: flex;
              @media (min-width: 992px) {
                margin: 0 12px;
              }
              &-count {
                margin: 0 7px;
                padding: 0px 21px 0 21px;
                @media (min-width: 768px) {
                  min-width: 100px;
                }
                @media (min-width: 992px) {
                  min-width: 257px;
                }
              }
              &-arrival {
                text-align: left;
                @media (min-width: 992px) {
                  text-align: right;
                }
              }
              &-depart {
                text-align: right;
                @media (min-width: 992px) {
                  text-align: left;
                }
              }
            }
            &__timing {
              display: flex;
              min-width: 167px;
              padding-bottom: 0;
              margin-bottom: 0;
              justify-content: space-evenly;
              strong {
                font-size: 16px;
                @include respond(sm) {
                  font-size: rem(18);
                }
              }
              span{
                font-size: 14px;
                font-family:$font-book;
                padding: 1px 0px;
              }
            }
            
            &__departure {
              display: flex;
              flex-direction: column;
              line-height: 1;
              white-space: nowrap;
              @media (min-width: 992px) {
                align-items: flex-end;
              }
            }
            &__arrival {
              display: flex;
              flex-direction: column;
              line-height: 1;
              position: relative;
              white-space: nowrap;
            }
            &__elapse{
              text-align: center;
              display: block;
              padding: 0;
              margin-top: -22px;
              font-size: 13px;
              color: $textcolor;
              opacity: 0.8;
              @media (min-width: 768px) {
                margin-top: -47px;
              }
              @media (min-width: 992px) {
                margin-top: -35px;
              }
            }
            &__connection{
              display: flex;
              align-items: center;
              width: 100%;
              max-width: 230px;
              align-content: space-between;
              align-items: center;
              align-items: flex-start;
              flex-direction: row;
              padding: 0;
              margin: 0;
              padding-top: 8px;
              /* position: relative; */
              @media (min-width: 992px) {
                padding-top: 8px;
                margin: 0 7px;
                padding: 8px 21px 0 21px;
              }  
              .origin-destination-icon{
                height: 8px;
                width: 8px;
                border: 1px solid $primary;
                border-radius: 50%;
                background-color: transparent;
              }
              .iconsSection{
                width: 24px;
                height: 23px;
                display: flex;
                align-items: center;
              }
              .transit-line{
                flex-grow: 1;
                height: 0;
                border: none;
                border-top: 1px dashed $primary;
                margin-top: 4px;
              }
            }

            &__expandedDetails{
              display: block;
              justify-content: space-between;
              margin: 0;
              background: none;
              border: 0;
              padding: 0;
              padding-top: 2rem;
              @media (min-width: 992px) {
                background-color: $lightbgColor !important;
                padding: 15px 12px;
                margin-top: 1rem;
                display: flex;
                padding: 0;
              }
              .flight-details__elapse {
                margin-top: -47px;
                @media (min-width: 992px) {
                  margin-top: -35px;
                }
              }
              .flight-details__segment-info{
                  padding: 5px;
                  border: none;
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  border: none;
                  @media (min-width: 992px) {
                    padding: 5px 14px;
                    border-right: 1px dotted $black;
                    width: 50%;
                  }
                  .flight-details__routeDetails{
                    &:first-child {
                      .flight-details__flightNo {
                        display: flex;
                        @media (min-width: 992px) {
                          flex-direction: column;
                        }
                      }
                    }
                  }
              }
              
              .flight-details__routeDetails{
                display: flex;
                margin: 0px;
                //margin-bottom: 10px;
                background: none;
                align-items: center;
                align-items: flex-start;
                justify-content: stretch;
                padding-top: 5px;
              }

              .flight-details__flyingHr{
                opacity: 0.8;
              }
              .flight-details__flightNo{
               color: $black;
               font-size: 12px;
               width: 20%;
               font-family:$font-medium;
               opacity: 0.8;
               display: flex;
               @media (min-width: 992px) {
                 flex-direction: column;
               }
              }

              .flight-details__layover{
                align-self: flex-end;
                text-align: center;
                display: flex;
                margin: 11px 0;
                // margin-right: 6px;
                flex-direction: column;
                align-items: center;
                margin-top: -20px;
                margin-bottom: -20px;
                @media (min-width: 992px) {
                  width: 80%;
                  margin-top: 3px;
                  margin-bottom: 11px;
                }
                &:before{
                  display: none;
                }
                &:after{
                  display: none;
                }
                span{
                  border: 1px solid #EDEDEC;
                  border-radius: 10px;
                  display: inline-block;
                  font-size: $stationFontSize;
                  padding: 3px 13px;
                  vertical-align: middle;
                  margin: 10px 0px;
                  background: rgb(241, 238, 237);
                  @media (min-width: 768px) {
                    background: $white;
                    margin: 5px 0px;
                  }
                }
                .segment-transit-line{
                  height: 20px;
                  width: 0;
                  border: none;
                  border-left: 2px solid #d9d9d9;
                  vertical-align: middle;
                  @media (min-width: 768px) {
                    height: 13px;
                    border-left: 1px solid $black;
                  }
                }
              }

              .flight-details__departure{
                display: inline-flex;
                flex-direction: column;
                line-height: 1;
              }

            }
            &__more-info{
              padding-top: 15px;
              padding: 0 10px;
              display: flex;
              flex-direction: column;
              width: 48%;
              @include respond(md) {
                width: 100%;
              }
              > div {
                margin: 7px 0;
              }
              h4{
                color: $black;
                // text-transform: capitalize;
                opacity: 0.8;
                @media (min-width: 992px) {
                  height: 17px;
                }  
              }
              .details{
                letter-spacing: -0.09px;
                color: #3B3B3A;
                text-transform: capitalize;
                font-size: 16px;
                 font-family:$font-book;
                opacity: 0.8;
                      .time-field{
      font-family:$font-medium;
      color: #3B3B3A;
      margin-bottom: 0;
      opacity: 0.8;
      }
              }
              .address-container{
                display: flex;
                @include respond(sm) {
                  display: block;
                }
              }
              .lat-container{
                display: flex;      
              }
              .toa-container{
                display: flex;      
              }
              .address-info{
                padding: 0 4px;
                width: 45%;
                @include respond(sm) {
                  width: 100%;
                }
              }
              .lat-info{
                padding: 0 4px;
                width: 50%;
                h4{
                  @media (min-width: 992px) {
                    white-space: nowrap;
                  }  
                }
                @include respond(sm) {
                  width: 100%;
                }
              }
              .toa-info{
                padding: 0 4px;
                width: 60%;
                h4{
                  @media (min-width: 992px) {
                    white-space: nowrap;
                  }  
                }
                @include respond(sm) {
                  width: 100%;
                }
              }
            }
          }
      }
.quote-details__filghtDetails {
    border-bottom: 1px solid rgb(220, 219, 219);
    padding-bottom: 25px;
    margin-bottom: 48px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.7rem;
    @media (min-width: 992px) {
      padding-bottom: 10px;
      margin-top: 0;
    }
}
.mobile_partition {
  &-wrap {
    border-top: 1px solid rgb(151, 151, 151);
    border-bottom: 1px solid rgb(151, 151, 151);
    width: 100%;
    flex-direction: column;
  }
  &-title {
    color: rgb(78, 76, 74);
    font-family: EtihadAltis-Book;
    font-size: 20px;
    font-weight: 300;
    flex: 1;
  }
  &-row {
    display: flex;
    padding: 18px 0;
    cursor: pointer;
  }
  &-icon {
    text-align: right;
    align-items: center;
    justify-content: center;
  }
  &-expanded {
    
  .address-info {
    width: 100%;
    margin-bottom: 25px;
    h4 {
      font-size: 14px;
      font-family: 'EtihadAltis-Medium';
    }
    .details {
      font-size: 16px;
      font-family: 'EtihadAltis-Book';
    }
  }
  }
}
@media print {
.toa-info{
          position:relative;
          left:23px;
          right:0px;
}
.address-info-pickup{
          position:relative;
          left:35px;
          right:0px;
}
.flight-details__segment-info{
          position:relative;
          right:8px;
}
}
button.toggle-route-details {
  background: transparent;
  height: 25px;
  width: 25px;
  float: right;
}
.flight-details__right__sub__rate {
   > label {
    margin: 0;
  }
}
.flight-details__expanded {
        .flight-details__elapse {
            margin-top: -47px;
            
        }
    }
