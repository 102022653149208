@import '../../assets/scss/abstracts/abstracts';

.split-btn{
   height: 100%;
   .p-splitbutton{
      &-defaultbutton, &-menubutton {
         font-family: $font-bold !important;
         padding: rem(5) rem(15);
         background-color: $white;
         font-size: 20px;
         line-height: 1.5;
         border-radius: 4px;
         display: inline-block;
         border: 1px solid $primary;
         letter-spacing: 0.2px;
         background: $white;
         color: $textcolor;
         border:solid 1px $textcolor;
         outline: none;
         font-family: $font-bold;
         &:hover{
            background-color: $textcolor !important;
            color: $white !important;
         }
         &:active{
            outline: none !important;
         }
         &:focus{
            outline: none !important;
         }

      }
      &-defaultbutton {
         border-bottom-right-radius: 0;
         border-top-right-radius: 0;
      }
      &-menubutton {
         border-bottom-left-radius: 0;
         border-top-left-radius: 0;
         position: relative;
         .pi-chevron-down {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
         }
      }
   }
   .p-menu-overlay{
      width: 54px;
      * {
         text-decoration: none;
         &:hover{
            text-decoration: none;
         }
      }
   }
}
