@import '../../assets/scss/abstracts/abstracts';


.shipment-summary{
    display: block;
    padding: 9px 2px;
    margin: 25px 0 0 0;
    align-items: center;
    font-family:$font-book;
    font-size: 18px;
    // padding:rem(25) 0;
    background: rgb(241, 238, 237);
    border-radius: 12px;
    padding: 13px 50px 13px 0;
    position: relative;
    line-height: normal;
    @media (min-width: 992px) { 
        margin: 32px 0px 25px 0px;
        font-family:$font-medium;
        border-radius: 2px;
        background: transparent;
        display: flex;
        flex-wrap: nowrap;
        border: 1px solid $lightBorderColor;
        box-shadow: 0px 3px 6px #2D1F5008;
        padding: 11px 2px;
    }
    &_modify-mobile {
        img {
            width: 17px;
        }
    }
    &__separator{
        display: none;
        @media (min-width: 992px) {
            border-right: solid 2px $black;
            height: 14px;
            display: block;
        }
    }
    .departure-icon{
        img {
            width: 26px;
        }
    }
    &__filter-calendar{
        position: absolute;
        top: -2px;
        right: 0px;
        border: none !important;
        .p-calendar{
            height: 20px;
        }
        .form-control{
            border: none;
        }
        .edit-icon{
            position: absolute;
            //z-index: 99;
            right: 0px;
            width: 22px;
            bottom: 1px;
        }
        input{
            display:none
        }
        .p-button:enabled:active,.p-button:enabled:hover{
            background: transparent;
            border-color: $white;
            box-shadow: none;
        }
        .p-button{
            top: 0px;
            right: -8px;
            background: transparent;
            box-shadow: none !important;
            z-index: 999;
            border: 0;
            .pi-calendar{
                display: none
            }
        }
        .p-datepicker{
           top: 10px !important;
           left: -94px !important;
        }
    }
    &__item{
        padding: 0px 15px;
        color: $titleColor;
        font-size:14px;
        line-height: 14px;
        display: inline-block;
        border-left: 1px solid #4D4F5C;
        @media (min-width: 992px) { 
            margin: 3px 0 3px 0;
            font-size:18px;  
            line-height: normal;        
            border-left: 0;  
        }
        &:first-child {
            border-left: 0;
        }
        &.shipment-summary__filter-calendar {
            display: none;
            @media (min-width: 992px) { 
                display: block;        
            }
        }
    &__commodity{
        text-overflow: ellipsis;
        max-width: 329px;
        white-space: nowrap;
        overflow: hidden;
        display: none;
        @media (min-width: 992px) { 
            display: block;
        }
    }
        &__right{
            margin-left: auto;
            position: absolute;
            right: 10px;
            @media (min-width: 992px) { 
                position: relative;
                right: auto;
                top:auto;
            }
            .l-link{
                color: $link;
                font-size: 16px;
                font-family: $font-family;
                margin-right: 20px;
                @media (min-width: 992px) { 
                    margin-right: 10px;
                }
                    &::after {
                        content: " ";
                        width: 1px;
                        height: 100%;
                        top: 0;
                        left: 0;      
                        position: absolute;
                        width: 100%;  
                        @media (min-width: 992px) { 
                            border-bottom: 1px solid #005f96;  
                        }    
                }
            }
        }
        > i{
            // margin-right: 5px;
            color: $grey;
        }
        .p-datepicker {
            width:440px;
        min-width: 280px;
        }
    }
    .border-r-0{
        border-right:0px;
    }
    .calender-icon{
        position: relative;
        @media (min-width: 992px) { 
            padding-right: 30px;
        }
    }
}