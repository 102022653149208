@import '../../../assets/scss/abstracts/abstracts';
.cargo-portal-container {
    .user-profile-panel {
        background-color: #fff;
        .company-info {
            box-shadow: 0px 3px 6px #00000029;
            background-color: #fff ;
            padding: 30px 35px;
            border-radius: 5px;
        }
        .privilege-tick-mark{
            background-position: center;
           background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAABD5JREFUWAm1V11sFFUUPvfO7Ha33d0GdqGtBYw/IZGfACHSh4Y0USGYgMEg0dRSS9ttQoAXXgxQk4Gy60/UJyMhbGlQAsZ/QoxoGighmlQiRtRgYlAjlBqga2lnt9vduXM9d3GXaXpndrd278POmXO+831nztw5M0ugxBU95puXZBNrgfCFHCBEODDO4W8gZNgF7u+18MSNUihJMWDtPd/8TDq5Gzh/FsWWOuYQuEKBnFGp512tI3nTEYtBxwK0WGBuxtR78GrbUdhTiMwaJwRSnJPDbl9VVGsev2ONWW3bAl45pjYxxk4AhwXWhFJtFBimRHm+J2xclOVSmbP7KN1pGuzc/xUX3LhP6kzOzu2P0V0yrWkd2BdT2oGbMcycFpMRlOKjhLYfCrM+a84Uke5edT1n7EusWtoZa+JMbBQzOCgbo13GV7n8fAGv94b8Y+bIL7jZFuaC5Tii4FBACT72csedccGfv9K7ZvzVcosLQexu/V0WPyBssbIdEMNFNxLX8bwi6y3zD4oa3OtdFN2WHM52QDeTnbMtPsf/kO1lYBdUMpnaJgD3bgHnbbboGQSeXH0AdmweBJdaaZvNTb5dBEn0uD+oT+q2k8qWwSaw7OGt8MITH8CH51vgyrVTNqh7brenqobqRnKNI6qE4APBVbClqQ8u/PhaQXFBa6ZTyynlfHEJGrZQn7cGWtafhmtD/dB/qdsWZw0wbi6luCFcVudMbIW64cV1n0IqPZptPRcPWxGLE6gqqgCFuqB1wxfwSP1TUtrNa49AqHoxvP/1M5DO6FKMzImPY4ZSE4rIIDCWGIK2p89C08q9U7gal++BFY82w8n+5+Cf8T+nxAqecEirVKE/m4w5YpmZhs8vdsGNW4OwqfEdWDDvcfh44CV4sKYRNjS8AWe+2Ql/DF9w5JAFCaW/Eu2kP5TW9dsygMxXH1oNzes+wRFKwVsxBy7/dhwLkL5pZen3fQS4vzIQxNsAsO8o+Qn3zbL7UWfLWzEXGpbsgGRqBC5dPYKpxW06Kyt+MV2NhPkSVTgJJ31I8pYV4GRPTMZh4IeIE6RgDD83+sSrKTuKXZ7KE5iRKZg1SwDxvegmvl5Bly1Aa9VvUUreniX+gjTiY1XrHIvnCxCGWlN7EA9/Cbusi8Dv7rra/KjMdkAIaptuJlVFacP2pMtVAO54g4LSIbRyGvkChONgh3Ee70obPmOlb+sco80RxU0gtPVQ2BiwQqYUIAKRMDtFgYZnsxPiyjmlYcFtFRc2xuRrf8y1hpvGRxhdJEcU6cV7rihqc097ZlCWMa0DOVCkM/Od21W9ghISwSqLeF/kMv87EhgnQN6spsGVduICadsBK50Y15lEoguH1Ub8N9yAG0ReOAGGu+cyUPKZWwkc1raPjlp5ZHZRBVgTRTFmYmIVI7wWJ1mdGGY4SUfw97rqCXyrtcTHrPhC9r8uA19NMB5wXgAAAABJRU5ErkJggg==) left no-repeat;
           overflow: hidden;
           background-size: 20px;
           padding-left: 30px;
           margin-bottom: 1.4rem;
           @media (min-width: 992px) { 
            margin-bottom: 0;               
           }

        }
        .form-info-right{
            font-size: 16px;
            text-align: right;
            font-weight: normal;
            font-family: "EtihadAltis-Book", sans-serif;
            white-space: nowrap;
        }
        .company-name-inner-wrapper{
            line-height: 15px;
        }
        .user-info-head{
            font-size: 32px;
            font-family: $font-medium;
            font-weight: medium;
        }
        .edit-link-profile{
            text-align: right;
            // text-decoration: underline;
            font-size: 20px;
        }
    }
}
