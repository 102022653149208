@import '../../assets/scss/abstracts/abstracts';

.manage-booking {

  background-color: $white;
  padding: 0px 0px 20px;
  margin-top: 51px;
    &__list{
      
      .p-datepicker {
        @media only screen and (min-width: 992px) {
          width: 440px;
        }
      
        min-width: 300px;
        position: absolute;
        top: 32px !important;
      }
      .p-datepicker table td > span.p-highlight {
        background: #DDD3BB;
      }
      .p-autocomplete-panel {
        margin-top: 13px;
        position: absolute;
      }
       
    }

    @media (min-width: 992px){
      .m-t-10{
        margin-top: 10px;
      }
  }
    .image-wrapper{
      width: 23px;
      padding-bottom: 2px;
      padding-right: 4px;
      padding-left: 4px;
  }
  .portal-tab-menu .p-tabmenu .p-tabmenu-nav {
    .p-tabmenuitem.tab-my-booking {
        .p-menuitem-link {
            border: none;
            background: $white;
            color: $textcolor;
            border-bottom: 5px solid $primary;
            font-family: $font-medium
        }
    }
}
}
