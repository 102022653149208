
@import '../../../assets/scss/abstracts/abstracts';

.booking-details{

  &__total-results-display{    
    font-size: 16px;    
    font-family: $font-family;
    @media (min-width:992px){
      font-family: $font-bold ;
    }
  }
  
  &__item {
    display: flex;
    align-items: center;
    border: solid 1px #DDDDDD;   
    position: relative;
    flex-wrap: nowrap;
    margin: 24px 0 0;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    font-family: $font-book !important;
    border-radius: 12px;
    padding: 24px 17px;
    font-weight: 300;
    font-size: 16px;
    @media (min-width: 992px){
      box-shadow: 0px 3px 6px #2d1f5008;
      padding: 1.4rem 1rem;
      border-radius: 5px;  
      font-size: 14px;
      margin: 19px 0 0;
      }

    .awb-status-wrapper{      
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (min-width:992px){
        padding-left: 2rem;
        font-size: 14px;
      }
    }
    .flight-status-wrapper{   
      padding-top: 1.1rem;
      @media (min-width: 992px){
        padding-top: 0;
      }
      .confirm-tick-mark{
        background-position: center;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAABD5JREFUWAm1V11sFFUUPvfO7Ha33d0GdqGtBYw/IZGfACHSh4Y0USGYgMEg0dRSS9ttQoAXXgxQk4Gy60/UJyMhbGlQAsZ/QoxoGighmlQiRtRgYlAjlBqga2lnt9vduXM9d3GXaXpndrd278POmXO+831nztw5M0ugxBU95puXZBNrgfCFHCBEODDO4W8gZNgF7u+18MSNUihJMWDtPd/8TDq5Gzh/FsWWOuYQuEKBnFGp512tI3nTEYtBxwK0WGBuxtR78GrbUdhTiMwaJwRSnJPDbl9VVGsev2ONWW3bAl45pjYxxk4AhwXWhFJtFBimRHm+J2xclOVSmbP7KN1pGuzc/xUX3LhP6kzOzu2P0V0yrWkd2BdT2oGbMcycFpMRlOKjhLYfCrM+a84Uke5edT1n7EusWtoZa+JMbBQzOCgbo13GV7n8fAGv94b8Y+bIL7jZFuaC5Tii4FBACT72csedccGfv9K7ZvzVcosLQexu/V0WPyBssbIdEMNFNxLX8bwi6y3zD4oa3OtdFN2WHM52QDeTnbMtPsf/kO1lYBdUMpnaJgD3bgHnbbboGQSeXH0AdmweBJdaaZvNTb5dBEn0uD+oT+q2k8qWwSaw7OGt8MITH8CH51vgyrVTNqh7brenqobqRnKNI6qE4APBVbClqQ8u/PhaQXFBa6ZTyynlfHEJGrZQn7cGWtafhmtD/dB/qdsWZw0wbi6luCFcVudMbIW64cV1n0IqPZptPRcPWxGLE6gqqgCFuqB1wxfwSP1TUtrNa49AqHoxvP/1M5DO6FKMzImPY4ZSE4rIIDCWGIK2p89C08q9U7gal++BFY82w8n+5+Cf8T+nxAqecEirVKE/m4w5YpmZhs8vdsGNW4OwqfEdWDDvcfh44CV4sKYRNjS8AWe+2Ql/DF9w5JAFCaW/Eu2kP5TW9dsygMxXH1oNzes+wRFKwVsxBy7/dhwLkL5pZen3fQS4vzIQxNsAsO8o+Qn3zbL7UWfLWzEXGpbsgGRqBC5dPYKpxW06Kyt+MV2NhPkSVTgJJ31I8pYV4GRPTMZh4IeIE6RgDD83+sSrKTuKXZ7KE5iRKZg1SwDxvegmvl5Bly1Aa9VvUUreniX+gjTiY1XrHIvnCxCGWlN7EA9/Cbusi8Dv7rra/KjMdkAIaptuJlVFacP2pMtVAO54g4LSIbRyGvkChONgh3Ee70obPmOlb+sco80RxU0gtPVQ2BiwQqYUIAKRMDtFgYZnsxPiyjmlYcFtFRc2xuRrf8y1hpvGRxhdJEcU6cV7rihqc097ZlCWMa0DOVCkM/Od21W9ghISwSqLeF/kMv87EhgnQN6spsGVduICadsBK50Y15lEoguH1Ub8N9yAG0ReOAGGu+cyUPKZWwkc1raPjlp5ZHZRBVgTRTFmYmIVI7wWJ1mdGGY4SUfw97rqCXyrtcTHrPhC9r8uA19NMB5wXgAAAABJRU5ErkJggg==) left no-repeat;
        overflow: hidden;
        background-size: 20px;
        padding: 25px; 
        @media (min-width: 992px){
          background-size: 15px;
          padding-left: 20px;           
        }
           
      }  
      .indicator{
        color: #F68D2E;
      }    
      .reject-status{
        padding-right: 5px;
      }
    }    
  }
  &__item:hover {
    box-shadow: 0px 1px 8px #00000029;
  }
  &__flight-section{
    display: flex;   
    align-items: center; 
    justify-content: center;    
    font-size: 14px;
    // @media (min-width: 992px){      
    //   justify-content: center;
    // }
  }
  &__route {
    position: relative;  
    border: none;  
    width: 100%; 
     @media (min-width: 992px){      
      width: 80%;  
    }
  }
 
  &__commodity-details{
    font-family: $font-book;
    font-size: 16px;
    padding-top: 1.5rem;
    @media (min-width: 992px){   
      font-size: 14px;   
      padding-left: 3rem !important;
      padding-top: 0;
    }

    .commodity-inner-wrapper{
      display: inline;
      width: 50%;     
    }
  
  }
  &__departure{
    display: flex;
      flex-direction: column;
      align-items: left; 
      /* padding-top: 2px; */
      white-space: nowrap;
      @media (min-width:992px){      
        align-items: flex-end; 
        line-height: 15px;
      }

  }
  &__arrival{
    line-height: 24px;
    @media (min-width: 992px){ 
      line-height: 15px;
    }
  }
  &__p-b-6{  
    padding-bottom: 6px;
  }
  &__right-track{      
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px !important;
    }   
  }
  .booking-detail_icons{
    width: 20px;
    margin: 5px;
    padding-bottom: 25px;
  }
  .paginationWrapper{
    margin-top: 15px;
  }
  .m-t-10{
    margin-top: 6px;
  }

  .font-16{
    font-size: 16px !important;
  }
  .flightNoWrapper{
    color:#4E4C4A
  }
  .sub-head{
    font-size: 22px;
    font-weight: 600;
  }

  .menu-dot-wrapper{
     padding: 15px 15px 3px 15px;
  }
  .menuDot-dropdown{    
      position: absolute; 
      z-index: 1002;
      transform-origin: center top;      
      right: 0;
      padding-right: 2.4rem;
      max-height: 100px;
      overflow: auto;
      .dropdown-panel{
        border-radius: 6px;
        width: 140px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        border: 1px solid #707070;
        background: #fff;
        color: #333;  
        box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
        font-size: 20px;
      }
      .panel-first-item{
        border-bottom: 1px solid #e4e4e4!important;
      }
      .panel-item,.panel-first-item{
        padding:.4rem .7rem;
        &:hover{
          color: $black;
          background: $bgcolor;
        }
      }
     
  }
  .page-sub-head{
    font-family: $font-family !important;
    font-size: 20px;
    @media (min-width:992px){
      font-family: $font-medium !important;
      font-size: 24px;
    }
  }