.autocomplete-inner-wrapper{
    
    .p-inputtext{
        border: none;
        padding: 0;
        width: 100%;      
    }
    .p-button:enabled:hover{
        color:#4c4e4a
    }
    .p-inputtext:enabled:focus{
    box-shadow: none;
    border-color: #fff;
    -webkit-shadow:0;
   
    }   
}