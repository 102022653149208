
@import '../../../../assets/scss/abstracts/abstracts';
.wight-dispaly-wrapper{       
    .loose-info-subwrapper{
        display: flex;
        justify-content: center;
        align-items: center;

        // @include respond(md) {
        //     .page-sub-head {
        //         font-family: $font-book !important;
        //     }
        //   }
        
        .stackable-tiltable-wrapper{
            font-size: 16px;
            font-family: 'EtihadAltis-Book';
            text-align: right;
        }
    }
    .uldInfo-table-wrapper{   
        @include respond(lg) {
            border-radius: 12px;
            box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.11);
            overflow: auto;
          }     
        .uld-body-width-number,.uld-body-width-uldType,
        .uld-head-width-0
        ,.uld-head-width-1{
        width: 15%;
        }
}
    .looseInfo-table-wrapper{
        @include respond(lg) {
            border-radius: 12px;
            box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.11);
            overflow: auto;
          }
        .Pieces-body-width-numberOfPieces, .loose-head-width-0
        {
        width: 10%;
        input[type=checkbox][disabled]{
            color: #DCDBDB ;
        }
        }
    }
     
}
.table{
    .weight-font{
        td{
            font-size:20px;
            font-family: EtihadAltis-Book;
        }
    }
}
#column-name-font{    
     font-weight: 500;
     font-family: $font-medium;
     @media (min-width: 992px) { 
        font-weight: normal;
        font-family: $font-book;
     }
}
