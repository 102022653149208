@import '../../../assets/scss/abstracts/abstracts';

.top-destinations-chart {
    padding-bottom: 10px;
    .section-sub-heading {
        margin-bottom: 0;
        margin-top: 24px;
        @media (min-width: 992px) { 
            margin-bottom: 42px;
        }
    }
    .revenue-indicator-panel{
        display: flex;
        flex-direction: column;
        .indicator-element-wrap{
        position: relative;
        width: 100%;
        height: 60px;
        padding: 10px 24px 30px;
        margin-bottom: 24px;
        @media (min-width: 992px) { 
            padding: 10px 34px 30px;
        }
        &__details{
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        &__element-background{
            height: 10px;
            width: 100%;
            border-radius: 50px;
            position: absolute;
            background-color: #eaeaea;
            width: calc(100% - 51px);
            left: 24px;
            @media (min-width: 992px) { 
                left: 34px;
                width: calc(100% - 68px);
            }
            .element{
                height: 10px;
                border-radius: 50px;
                position: absolute;
                background-color: #6997d3;
            }
        }
        p {
            font-family: $font-book;
            font-size: 16px;
            margin-bottom: 14px;
            margin-right: 32px;
        }
        }
    }
}