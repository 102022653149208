@import '../../../assets/scss/abstracts/abstracts';
.cargo-portal-container {
    .new-booking-tile-panel{
        display: flex;
        flex-direction: column;
        width: auto;
        padding: 23px;
        background-color: $white;
        //align-items: center;
        justify-content: center;
        border-radius: 12px;
        //text-align: center;
        height: 100%;
       .section-sub-heading {
           font-family: $font-book;
          
       }
        button.cta-button {
            background: transparent;
            width: 100%;
            padding: 5px 16px;
            @media (min-width: 768px) { 
                padding: 10px 16px;
            }   
        }
        img{
            display: block;
            margin-left: auto;
            margin-right: auto;
            height: 72px;
            width: 72px;
        }
       
    }
}
