@import '../../assets/scss/abstracts/abstracts';
.booking-heading {
    h3.page-heading {
        padding-top: 17px;
        margin-bottom: 10px;
    }
}
.portal-tab-menu {
    padding: 0 0px;
    margin-bottom: 29px;
    .p-tabmenu {
        background: $white;
        font-family: $font-book;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 22px;
        padding: 5px 0px 0;
        border-bottom: 1px solid $dropdownBorderColor;
        height: 66px;
        white-space: nowrap;
        @media (min-width: 768px) {
            white-space: normal
        }
        .p-tabmenu-nav {
            margin: 0;
            overflow-x: auto;
            overflow-y: hidden;
            flex-wrap: nowrap;
            @media (min-width: 768px) {
                overflow: unset;
                flex-wrap: wrap;
            }
            .p-tabmenuitem {
                .p-menuitem-link {
                    border: none;
                    background: $white;
                    color: $textcolor;
                    padding: 22px 16px 17px 16px;
                    text-decoration: none;
                    font-family: $font-book;
                    font-weight: normal;
                }
                &.p-highlight {
                    .p-menuitem-link {
                        border: none;
                        background: $white;
                        color: $textcolor;
                        border-bottom: 5px solid $primary;
                        font-family: $font-medium;
                    }
                }
                &:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
                    background: none;
                    border-bottom: 5px solid $primary;
                }
            }
        }
    }
    .tab-permanent-booking {
        @media (max-width: 768px) {
            display: none;
        }
    }
}