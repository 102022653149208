.ffm-dashboard {
    .ffm-status-table {
        th {
            width: 15.66%;
            &.dep-time, &.arrv-time, &.ffm-status {
                width: 19.5%;
            }
            &.flight-no, &.origin, &.destination {
                width: 14%;
            }
            &.ffm-link {
                width: 5%;
            }
            &.timeafterSTD {
                width: 16%;
            }
        }
    }
}