@import '../../assets/scss/abstracts/abstracts';


.login-container {
  height: 100vh;
  width: 100%;
  background-color: $white;
}

.signin-widget-wrapper{
  height: 100vh;
  width: 100%;
  padding: 10px 20px;
  position: relative;
  div {  
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
    margin: 26px auto;
    box-sizing: border-box;
    button {
      margin: 10px;
    }
  }
}



.banner{
  justify-content: space-between;
  height: 100%;
  padding-left: 0;
  @include respond(sm) {
    display: block;
  }
  &__item{
    width: 100%;
    position: relative;
    margin: 0;
    @include respond(sm) {
      height: auto;
      width: 100%;
      margin-top: 15px;
    }
    img{
      height: 100vh;
      max-width: 50vw;
    }
  }
}

.main-container{
  margin: auto !important;
}

#okta-sign-in.auth-container.main-container,#okta-sign-in.auth-container .okta-sign-in-header{
    border:none;
    box-shadow: none
}
.okta-sign-in-header.auth-header{
  padding-bottom: 40px !important;
}
.outh-header{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  color: #b7aeae;
}
#okta-sign-in .o-form .input-fix{
   border:none;
   border-bottom: 1px solid #bbb;
   box-shadow: none !important;
   border-radius: 0px; 
}

#okta-sign-in.auth-container .button-primary{
    background: $primary !important;
    color:  $white;
    border: none !important;
    &:hover{
      background: $hover !important;
      box-shadow: 0px 7px 8px -4px rgba(189, 139, 19, 0.2), 0px 12px 17px 2px rgba(189, 139, 19, 0.14), 0px 5px 22px 4px rgba(189, 139, 19, 0.12) !important;
    }
    &:active{
      background: $hover !important;
      box-shadow: 0px 7px 8px -4px rgba(189, 139, 19, 0.2), 0px 12px 17px 2px rgba(189, 139, 19, 0.14), 0px 5px 22px 4px rgba(189, 139, 19, 0.12) !important;
    }
    &:focus {
      outline: 0;
      box-shadow: 0px 7px 8px -4px rgba(189, 139, 19, 0.2), 0px 12px 17px 2px rgba(189, 139, 19, 0.14), 0px 5px 22px 4px rgba(189, 139, 19, 0.12) !important;
    }
}

#okta-sign-in {
  .auth-org-logo {
    max-height: 240px;
  }
}

.auth-content{
  padding-top: 0 !important;
}

.okta-form-title.o-form-head{
  margin-bottom: 40px !important;
  color: $grey !important;
  font-weight: 500 !important;
}

.beacon-container{
  top: 90px;
}

@media (max-width: 767px) {                  
   .banner {
      display: none;
   }
}