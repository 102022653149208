@import '../../../assets/scss/abstracts/abstracts';

.listBookingForm {
  @media (min-width: 992px){
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    padding: 40px 25px;
  }

  .form-item__label{
    font-size: 16px;
  }
  @media (min-width: 768px){
    .form-item[class*=col-] {
      margin-bottom: 16px;
    }
  }

  .custom-margin-search {
    margin-top: 25px;
    @media (min-width: 768px){
      margin-top: 33px;
    }
  }
 
  .p-calendar .p-inputtext{
    border: none;
    padding: 0;
    width: 100%;
  }
 .p-inputtext:enabled:focus{
  box-shadow: none !important;
  border-color: rgb(131, 120, 120) !important;
  }
  .p-autocomplete-dd .p-autocomplete-dropdown {
    background-color: #fff;
    border: none;
    line-height: 14px;
    width: auto;
  }
     
    .innerWrapper{
        display:flex;
        justify-content: flex-end;    
        align-items: center;
    }
    &__double_element{
    display:flex;
    width: 100%;
    }

    &__carrier_code{
    margin-right: 4px;
    width: 25%;
    }

    .no-data{    
    display: flex;
    flex-wrap: nowrap;
    border: 1px solid $border;
    padding: 9px 10px;
    margin: 25px 20px;
    font-family: $font-medium;
    box-shadow: 0px 3px 6px #2D1F5008;
    font-size: 14px;
    justify-content: flex-start;
    }
    .serach-button{
      display: inline-block;
      margin-right: 4px;
      button{
        float: right;
      }
    }
    .link{
      font-family: $font-book;
    }

    .image-wrapper{
    width: 20px;
    padding-bottom: 4px;
    padding-right: 4px;
    }
    .autocomplete-dropdown {
      .autocomplete-inner-wrapper{
        .p-inputtext{
            border: none;
            padding: 0;
            width: calc(100% - 20px);
        }
      }
      .p-button:enabled:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }
    ::placeholder{
      color:#999;
  }
}

.textFiledCaps{
  text-transform: uppercase !important;
}

