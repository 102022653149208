

.datatable-rowexpansion-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.datatable-rowexpansion-demo .orders-subtable {
    padding: 1rem;
}

.status-finalized{
    background-color: #559E49
}
.status-onhold{
    background-color: #FF5A4B
}
.no-information{
    background-color: #FFBF00
}

.esc-ack{
       background-color: blue;
}
.def{
    display: none;
    pointer-events: none;
    cursor: default;
}
.dot {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    //display: inline-block;
    margin-left: 10%;
    margin-top: 2px !important;
    border: none;
    cursor: pointer;
    caret-color: transparent;
  }

.chevron-icon {
    font-size: 39px;
    color: grey;
    cursor: pointer;
    padding-bottom: 0px;
}
.card{
    h3{
        margin-left: 16px;
        margin-top: 24px;
        font-size: 22px;
    }
}
  
  .customdiv:hover .tooltiptext {
    visibility: visible;
  }

  .customdiv{
    //border: solid 1px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    margin-left: 10%;
    margin-top: 5px !important;
    border: none;
    cursor: pointer;
    caret-color: transparent;
  }

  .customdiv .tooltiptext {
    visibility: hidden;
    width: auto;
    max-width: 300px;
    //background-color: rgb(204, 201, 201);
    color: black;
    text-align: left;
    //border-radius: 6px;
    //padding: 5px 0;
    font-weight: 600;
    font-size: 15px;
    margin-left: 28px;
    margin-top: -10px;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1000;
    border: 1px solid #dcdbdb;
    border-radius: 5px;
    padding: 5px;
    background-color: #fff;
  }
.csn-table {
  .oci-details {
      display: block;
  }
  .sign-post-container {
    width: auto;
    display: block;
  }
  .sign-post-info {
    font-size: 14px;
    font-weight: normal;
    margin-top: 5px;
    display: block;
  }
  .sign-post {
    font-size: 14px;
    font-weight: normal;
    padding: 8px 17px;
    border-radius: 15px;
    text-align: center;
    min-width: 100px;
    display: inline-block;
    &.flight-status {
      width: 100%;
    }
    &.blue-status {
      background: #CEE5FA;
    }
    &.status-finalized {
      background: #559E49;
    }
    &.status-onhold {
      background: #FF5A4B;
    }
    &.no-information {
      background: #F68D2E;
    }
    &.yellow-status {
      background: #FFC72C;
    }
  }
  .awb-count {
    text-align: right;
    font-size: 16px;
  }
}
.awb-discalimers {
  font-size: 16px;
  float: right;
  sup {
    font-size: 14px;
  }
}
sup.awb-sup {
  font-size: 14px;
}

                 