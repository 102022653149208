@import '../../assets/scss/abstracts/abstracts';

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    background: #dbdbdb;
    color: black;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link{
    background: #dbdbdb;
    color: black;
}

.overflow{
    border: solid;
    border-width: thin;
    border-radius: 6px;
    .heading{
        padding: 15px;
        display: flex;
        .heading-text{
            text-align: left;
            font-family: $font-bold;
            font-size:18px;
            letter-spacing: 0px;
            color: #005F96;
            opacity: 1;
            cursor: pointer;
        }
        .close-icon{
            position: absolute;
            // left: 97%;
            right: 21px;
            font-size: large;
            margin-top: -10px;
            cursor: pointer;
        }
    }
}