@import '../../assets/scss/abstracts/abstracts';

.custom-pl-0 {
    padding-left: 0;
    @media (min-width: 992px) { 
        padding-left: 15px;
    }
}
.item-group{
    position: relative;
    box-shadow: 0px 1px 11px #00000029;
   padding: 12.5px 8px;
   margin-bottom: 11px;
   .grey-div{
      background: $lightbgColor;
      padding-left: 0;
   }
   .weight-div{
       width: 20%;
       @media (min-width: 768px) {
        padding-left:10px;
       }
   }
   .form-group.pieces-div{
       width: 50%;
       @media (min-width: 992px) {
        width: 12%;
        padding-left:10px; 
       }
   }
   .close-button{
        height: 18px;
        float: right;
        margin: 5px;
        cursor: pointer;
        width: 13px;
    }
    .border-0{
        border: 0px;
    }
    .borderRadius-R0{
        border-bottom-right-radius:0px;
border-top-right-radius:0px;
border-right: none !important;
    }
    .p-l-0{
            padding-left: 0;
    }
    .p-t-0{
            padding-top: 0;
    }
    .m-r-10{
        margin-right: 10px;
    }
    .m-b-20{
        @media (min-width: 992px) {
            margin-bottom:20px;
        }
    }
     .borderRadius-L0,.borderRadius-L0 .p-dropdown{
        border-bottom-left-radius:0px;
border-top-left-radius:0px;
    }
    .margin-negativeLeft10{
        margin-left: -10px
    }
    .border-right0{
        border-right:0
    }
    .border-left0,.border-left0 .p-dropdown{
        border-left:0
    }

    .operational_weight_dropdown{
        width: 40%;
    }
}
.m-t-20{
    margin-top:20px;
}
.m-t-10{
    margin-top:10px;
}
.m-b-0{
    margin-bottom:0px;
}

.cust-dropdown{
    background: #EDEDEC!important;
    .p-dropdown-trigger .pi-chevron-down{
        background: #EDEDEC !important;
    }
}
.p-selectbutton .p-button{
    width: 66px
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover{
    background: white;
    
}
/* .p-button-label{

   font-family: 'EtihadAltis-Bold', sans-serif;
   color: #4d4f5c;
   font-size: 18px;
}

 .p-button.p-button-link:enabled:hover .p-button-label {
    text-decoration: none;
    color: rgb(51, 51, 175);
}
.form-group .align-items-center{
    margin-top: 10px;

} 
 .last-item{
     border-bottom: none;
 } */

 .form-group .add-overflow-link{
    font-family: $font-medium;
    color: #005F96;
    //margin-top: -80px;
    font-size: 18px
}
// #pivot-styling{
//     height:1px;
// }
.ops-weight {padding-top: 0px;}
.p-dropdown{
    position: relative;
    .p-dropdown-trigger{
        position: absolute;
        right: -5%;
        top: 50%;
        transform: translate(0, -50%);
        .pi-chevron-down{
            background: $white;
        }     
    }
}
.delete-section{
    margin-top: 24px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (min-width: 992px) {
        margin-top: 0;
    }
    cursor: pointer;
    span{
    font-size: rem(12)
    }
}
.no-wrap-type{
    @media (min-width: 992px) {
        white-space: nowrap;
        margin-right: 30px;
    }
   
}