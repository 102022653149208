@import '../../assets/scss/abstracts/abstracts';

.country-autocomplete-inner-wrapper{
    font-family: $font-book !important;

    .p-inputtext{
        border: none;
        padding: 0;
        width: 100%;
    }
    .p-inputtext:enabled:focus{
        box-shadow: none;
        border-color: #fff;
        -webkit-shadow:0;
    }
    .p-autocomplete-panel {
        margin-top: 15px;
        position: absolute;
      }

}