@import '../../assets/scss/abstracts/abstracts';
.additional-form{
    &__question{
        display: flex;
    flex-direction: column;
    }
    .p-selectbutton.p-buttonset .p-button{
        font-size: 20px;
        height:50px;
    }
    .title{
        font-family: $font-medium;
        font-size: 18px;
        color: #4D4F5C;
    }
    .warning{
        padding-bottom: 10px;
       .p-inline-message.p-inline-message-warn{
          background: #F5D5D6;
        }
    }
    label{
        vertical-align: top;
    }
    .form-element{
        display: inline-block;
    }
    .p-selectbutton{
    .p-button{
        width:auto;
      box-shadow: none;
      
    }
    // .p-highlight,.p-highlight:hover{
    //   border-color: #4E4C4A;
    //   background-color: #4E4C4A;
    //   color:  $white;
    // }
    
    
  }
}
.item-group-additional{
   padding: 4px 0px;
    margin-left: 0px;
    margin-right: 0px;
   .close-button{
        height: 12px;
        float: right;
        margin: 5px;
        cursor: pointer;
    }
}


.p-dropdown{
    position: relative;
    .p-dropdown-trigger{
        position: absolute;
        right: -5%;
        top: 50%;
        transform: translate(0, -50%);
        .pi-chevron-down{
            background: $white;
        }     
    }
}
