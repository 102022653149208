@import '../../assets/scss/abstracts/abstracts';

.search-panel {
  //box-shadow: 0px 0px 6px -2px #aaa;  
  background: $white;

  @media (min-width: 992px) {
    padding: 0;
  }

  .title-content {
    border-bottom: solid 1px $border;

    &.modify_temp {
      width: 100%;
      margin: 0 1rem;
      border: 0;

      h3 {
        margin-bottom: 0;

        @media (min-width: 992px) {
          margin-bottom: 0.5rem;
        }
      }

      @media (min-width: 992px) {
        width: auto;
        margin: 0;
        border-bottom: solid 1px #d9d9d9;
      }
    }
  }

  .additional_scc {
    @media (min-width: 992px) {
      // margin-right: 10px;
      // margin-left: 10px;
      padding: 0 10px;
    }
  }

  .p-selectbutton.p-buttonset.shipping-type .p-button {
    padding: 10.5px 23px;
    font-size: 16px;
  }

  &__double_element {
    display: flex;

    input {
      width: 60%;
    }

    .dropdown_div {
      width: 30%;
    }
  }

  &__double_element.weight-div {
    input {
      width: 75px;

      @media (min-width: 992px) {
        width: 100px;
      }
    }

    .dropdown_div {
      width: 30%;
    }
  }

  .form-group {

    .form-row>.col,
    .form-row>[class*=col-] {
      padding-right: 8px;
      padding-left: 8px;
    }

    label {
      font-size: 16px;
      color: $textcolor;
      font-family: $font-book;
      // @media (min-width: 992px) {
      //   font-size: 20px;
      // }
    }

    input {
      font-size: 18px;
      color: #4D4F5C;
      font-family: $font-book;
    }

    .l-checkbox input[type=checkbox]:checked+label {
      font-size: 20px;
      color: $black;
    }

    .l-radio label {
      line-height: 20px;
      font-size: 20px;
    }

    .date-toggle {
      margin: 0;
      margin-bottom: 7px;
      height: 22px !important;
      width: 75%;
      display: flex;

      .p-button {
        height: 22px !important;
        width: 50%;
        white-space: nowrap;
      }
    }
  }

  .search-panel__type {
    border: 0;
  }

  .p-dropdown .p-dropdown-label {
    font-size: 20px;
    color: #4D4F5C;
    line-height: 2;
    font-family: $font-book;
  }

  .warning-info .p-inline-message {
    width: 100%;
    float: left;
    display: block;
    background: #F5D5D6;
    opacity: 1;
    font-family: $font-book;
    font-size: $subTitleFont;
    color: $textcolor;
    padding: 9px;
  }

  .warning-error .p-inline-message {
    width: 100%;
    float: left;
    display: block;
    background: #E8F1F4;
    opacity: 1;
    font-family: $font-book;
    font-size: $subTitleFont;
    color: $textcolor;
    padding: 9px;
  }

  // @include respond(sm) {
  //   padding: rem(10);
  // }
  &__scc-autocomplete {
    button {
      width: 14% !important;
    }

    .p-autocomplete-panel {
      width: 250px;

      li {
        white-space: normal;
        float: left;
        width: 230px;
        height: auto;
        word-wrap: break-word;
      }
    }
  }

  &__promo {
    display: inline-block;
    min-width: 270px;
    width: 100%;

    @media (min-width: 992px) {
      width: auto;
    }
  }

  &__booking-title {
    margin-bottom: 24px !important;
    color: $titleColor;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid rgb(220, 219, 219);
    padding-bottom: 19px;

    @media (min-width: 992px) {
      border-bottom: 0;
      padding-bottom: 0;
    }

    h3 {
      display: inline-block;
    }

    .l-link {
      float: right;
      text-decoration: none !important;
      font-size: 17px;

      &::after {
        border-bottom: 1px solid transparent;
      }
    }
  }

  &__sccbutton {
    margin-top: 27px;
    height: 42px;
    margin-left: -13px;
  }

  &__errormsg {
    color: $invalid !important;
    font-size: 14px !important;
    //  position: absolute
  }

  &__hiddenmsg {
    display: none;
  }

  &__data {
    justify-content: start;
    margin-bottom: 1.5rem;

    &.last-item {
      margin-bottom: 1rem;
    }

    //margin: 0;
    &.specs {
      .form-group {
        margin-right: 3px;
      }
    }

    .form-group {
      position: relative;

      // @include respond(sm) {
      //   width: calc(50% - 10px);
      // }
      @media (min-width: 992px) {
        width: calc(20% - 10px);
      }

      label span {
        font-size: 0.8em;
      }

      .autocomplete {
        width: inherit;
      }



      .p-dropdown {
        box-shadow: none !important;
        border-color: $textcolor;
      }

      &.red-border {
        .p-dropdown {
          border-color: $invalid;
        }
      }

      .p-dropdown-label {
        line-height: 2;
      }

    }
  }




  .shipping-item-row {

    // font-size: 18px;
    // label{
    //   font-size: 16px;
    //   font-family: $font-bold;
    // }
    input {
      font-size: 20px;
      color: #4E4C4A
    }

    .shipping-wrapper {
      box-shadow: 0px 0px 6px #98BCD3;
    }

    .form-group {
      margin-bottom: 10px;
    }
  }

  .scc-row {
    .scc-wrapper {
      margin-bottom: 0 !important;
      margin-top: 10px;

      span {
        position: relative;
        margin-right: 7px;
        display: inline-block;

        .scccode {
          display: inline-block;
          white-space: nowrap;
          border-radius: 4px;
          background-color: $sccBgColor;
          border: 1px solid $darkGrey;
          padding: 5px 6px;
          padding-right: 20px;
          margin-top: 10px;
          cursor: pointer;
          font-size: 12px;
          font-family: $font-bold;
        }
      }

      .close-button {
        position: absolute;
        right: 2px;
        padding: 1px;
        border-radius: 50%;
        width: 16px;
        height: 15px;
        color: #777;
        cursor: pointer;
        top: 17px;

      }
    }

  }

  &__units {
    position: absolute;
    right: 8px;
    bottom: 12px;
    font-size: rem(12);
  }

  &__link {
    font-weight: 300;
    color: $link;
    letter-spacing: 0px;
    line-height: 16px;
    position: relative;
    display: inline-block;
    cursor: pointer;

    &:hover {
      color: $link;
    }

    &::after {
      content: " ";
      width: 1px;
      height: 100%;
      top: 0;
      left: 0;
      border-bottom: 1px solid #005f96;
      position: absolute;
      width: 100%;
    }
  }

  &__type {
    display: flex;
    align-items: center;

    >* {
      margin-right: 10px;
      flex: 1;

      @media (min-width: 992px) {
        flex: none;
      }
    }
  }

  &__selectbutton .p-button {
    padding: 9px 6px 9px 6px;
    box-shadow: none !important;
    background-color: $white;
  }

  &__selectbutton .p-button.p-highlight {
    background-color: $primary;
    color: $white;
    border-color: $primary;
    outline: none;

    &:hover {
      background-color: #976F0F;
      border: 1px solid $primary;
    }
  }

  &__voice {
    display: flex;
    align-items: center;
    line-height: 1;

    >i {
      border-radius: 50%;
      background: $textdark;
      width: rem(58);
      height: rem(58);
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      font-size: rem(24);
      color: #fff;
      margin-right: 10px;
      border: solid 1px $primary;
    }
  }

  &__footer {
    align-items: center;
  }

  .scc-recommendation {
    margin: 0;

    @media (min-width: 992px) {
      margin: 0 4px !important;
    }

    div {
      display: inline-block;
      white-space: nowrap;
      border-radius: 4px;
      background-color: #E1EAF6;
      border: 1px solid $darkGrey;
      padding: 5px 6px;
      padding-right: 20px;
      cursor: pointer;
      font-size: 16px;
      font-family: $font-medium;
    }
  }

  .p-autocomplete,
  .p-calendar {
    position: relative;
  }

  .p-datepicker {
    width: 100%;
    min-width: 280px;
    position: absolute;
    top: 33px !important;

    @media (min-width: 992px) {
      width: 440px;
    }
  }

  .p-autocomplete-panel {
    margin-top: 13px;
    position: absolute;
    width: 100%;
  }

  .form-row,
  .row {
    // margin-bottom: 8px;

    .p-tabview {
      .p-tabview-nav {
        .p-highlight {
          a {
            background-color: #000 !important;
          }

        }
      }

      .p-tabview-panels {
        border-radius: 3px;
        border-color: $border;
      }
    }

  }

  .p-dialog {
    max-width: 600px;

    @media (min-width: 992px) {
      min-width: 600px;
    }

    &-content {
      background-color: $white;
    }

    &-header {
      padding-bottom: 10px;
      padding-top: 10px;
      background-color: $white;

      .p-dialog-title {
        font-weight: 500;
        font-size: 1.2rem;
      }
    }

    &-footer {
      border: none;
      background-color: $white;
    }

    .button__first {
      background-color: $primary;
      color: $white;
      border-color: $primary;
      box-shadow: none !important;
      outline: none;

      &:enabled:hover {
        background-color: $hover;
        border-color: $primary;
      }

      &:hover {
        background-color: $hover;
        border-color: $primary;
      }
    }

    .button__second {
      background-color: $white;
      box-shadow: none !important;
      color: $primary;
      border-color: $primary;
      outline: none;

      &:enabled:hover {
        background-color: $white;
        border-color: $primary;
      }

      &:hover {
        background-color: $white !important;
        border: 1px solid $primary;
        border-color: $primary !important;
        color: $primary !important;
      }

      &:active {
        background-color: $white;
        border: 1px solid $primary;
      }
    }

  }

  .m-b-0 {
    margin-bottom: 0px;
  }

  .l-link.disabled {
    color: $grey;
    pointer-events: none;
    cursor: default;
  }

  .form-row.alert {
    margin: 0px 0 25px 0px;
  }
  .portal-dialog .p-dialog .p-dialog-content .restriction-content {
    text-align: left;
    font-size: 16px;
    p {
      line-height: 24px;
      text-align: left;
    }
  }
  @media (min-width: 992px) {
    .portal-dialog .p-dialog .p-dialog-content .restriction-content {
      line-height: 24px;
      text-align: left;
      font-size: 16px;
      p {
        line-height: 24px;
        text-align: left;
        margin-top: 0px;
      }
    }
  }

}

button.p-button.p-component.button__another {
  height: 48px;
  color: $black;
  background: $white;
  margin-top: 10px;
  box-shadow: none;
  outline: none;
  padding: 0.572rem 2.1rem;
  font-family: $font-bold;
  font-size: 20px;
  color: #4E4C4A;
  border: 1px solid #4E4C4A;
  border-radius: 6px;

  @media (min-width: 992px) {
    margin-bottom: 0.7rem;
  }

  .pi-plus {
    font-size: 20px;
    margin-right: 0.4rem;
  }
}



.code-type-btn {
  height: 40px;
  width: 270px;
  position: relative;
  left: 10px;

  #promo-code-btn {
    position: absolute;
    top: 12px;
  }

  #spot-code-btn {
    position: absolute;
    top: 12px;
    left: 150px;
  }

  .promo-label {
    font-family: $font-book;
    font-size: 16px;
    position: absolute;
    top: 8px;
    left: 24px;
  }

  .spot-label {
    font-family: $font-book;
    font-size: 16px;
    position: absolute;
    top: 8px;
    left: 173px;
  }

  .p-radiobutton .p-radiobutton-box {
    padding: 2px 2px;
    width: 18px;
    height: 18px;
    border-color: $textcolor;
  }

  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 12px !important;
    height: 12px !important;
    background-color: #bd8b13;
    border: $textcolor;
  }

  .p-radiobutton .p-radiobutton-box .p-radiobuttonp-icon {
    width: 12px;
    height: 12px;
    background-color: #bd8b13;
    border: $textcolor;
  }


  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $textcolor;
    background: white;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: none;
    background: white;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: white;
    border-color: $textcolor;
  }
}


.codep-type-btn {
  height: 40px;
  width: 270px;
  position: relative;


  #promo-code-btn {
    position: absolute;
    top: 12px;
  }

  #spot-code-btn {
    position: absolute;
    top: 12px;
    left: 150px;
  }

  .promo-label {
    font-family: $font-book;
    font-size: 16px;
    position: absolute;
    top: 8px;
    left: 24px;
  }

  .spot-label {
    font-family: $font-book;
    font-size: 16px;
    position: absolute;
    top: 8px;
    left: 173px;
  }

  .p-radiobutton .p-radiobutton-box {
    padding: 2px 2px;
    width: 18px;
    height: 18px;
    border-color: $textcolor;
  }

  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 12px;
    height: 12px;
    background-color: #bd8b13;
    border: $textcolor;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $textcolor;
    background: white;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: none;
    background: white;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: white;
    border-color: $textcolor;
  }
}


.promo-div {
  span {
    color: #4D4F5C;
    font-size: 18px;
  }
}

.bold-label {
  font-family: $font-bold;
  font-size: 18px !important;
  color: $titleColor !important;
}

.p-tooltip-text {
  background-color: $lightbgColor !important;
  color: $textdark !important;
}

.p-tooltip-arrow {
  border-right-color: $lightbgColor !important;
}

.f-15 {
  font-size: 16px !important;
  color: $textcolor !important;
  font-family: $font-book;

  input {
    font-size: 20px !important;
    color: $textcolor !important;
  }

}

.scc-autocomplete {
  display: flex;
  align-items: center;

  .scc-code {
    display: contents;
  }

  .scc-description {
    font-size: 12px;
    margin-left: 15px;

  }
}

.form-item__field_template {
  border: 1px solid #4E4C4A;
  width: 100%;
  height: 45px;
  border-radius: 4px;
  cursor: pointer;
  padding-left: 0.75rem;
  padding-right: 2.5rem;
  font-size: 20px;
  background-repeat: no-repeat;
  background-position: center right 0.75rem;
  font-family: $font-book;
  color: #4D4F5C;
  transition: box-shadow 0.35s;

  &:focus {
    box-shadow: 0 0 5px #005f96;
  }

}

.button-footer-panel {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .clear-button {
    color: #005F96;
    font-size: 20px;
    font-family: $font-book;
  }
}

#recomment-font {
  font-family: $font-family;
}

#per-item-styling {
  font-size: 20px;
}

.date-toggle-adjust {
  position: relative;
  display: flex;
  align-items: end;

  @media (min-width: 992px) {
    display: block;
    height: 31px;
    padding-bottom: 27px;
  }

  #btn-radio-first-adjust {
    @media (min-width: 992px) {
      position: absolute;
      top: 2px;
    }
  }

  #btn-radio-second-adjust {
    @media (min-width: 992px) {
      position: absolute;
      top: 2px;
      left: 150px;
    }
  }

  #label-size-first-adjust {
    font-family: $font-book;
    color: $textcolor;
    font-size: 16px;

    @media (min-width: 992px) {
      position: absolute;
      left: 23px;
    }
  }

  #label-size-second-adjust {
    font-family: $font-book;
    color: $textcolor;
    font-size: 16px;

    @media (min-width: 992px) {
      position: absolute;
      left: 173px;
    }
  }

  .p-radiobutton .p-radiobutton-box {
    border-color: $textcolor;
    padding: 2px 2px;
    width: 18px;
    height: 18px;
  }

  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 12px;
    height: 12px;
    background-color: #bd8b13;
    border: $textcolor;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $textcolor ;
    background: white;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: none;
    background: white;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: white;
    border-color: $textcolor;
  }
}

#cargo-col-style {
  .p-selectbutton.p-buttonset .p-button.p-highlight {
    height: 50px !important;
    width: 109px;

    @media (min-width: 992px) {
      width: 93px;
    }
  }

  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight) {
    height: 50px !important;
    width: 109px;

    @media (min-width: 992px) {
      width: 93px;
    }
  }
}

#unknown-shipper-button {
  .p-selectbutton.p-buttonset .p-button.p-highlight {
    background: white !important;
    color: #000 !important;
  }

}

.age-input {
  input {
    position: relative;
  }
  .age-months {
    margin-top: 31px;
  }
}

.yearSpan {
  position: absolute;
  z-index: 3;
  margin-top: 44px;
  margin-left: 10px;
  font-size: 17px;
}

.monthsSpan {
  position: absolute;
  z-index: 3;
  margin-top: 44px;
  margin-left: 36px;
  font-size: 17px;
}

.age-month-year {
  display: flex;
  flex-direction: row;
  width: 358px;
  margin-left: 7px
}

.hwl-measure {
  display: flex;
  flex-direction: row;
  width: 405px;
  margin-left: -30px;
  grid-gap: 4px;
  gap: 0px;
}

.deleteicon {
  width: 121px;
  height: 22px;
  color: rgb(0, 95, 150);
  font-size: 16px;
  font-family: EtihadAltis-Book;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 22.4px;
  cursor: pointer;
  padding-left: 25px;
}

.trashsize {
  width: 18px !important;
  height: 18px !important;
}

.listupload {
  color: #4e4c4a;
  font-family: EtihadAltis-Book;
  font-size: 16px;
  font-weight: 300;
  height: 24px;
  letter-spacing: 0px;
  line-height: 22.4px;
  width: 210px;
  overflow: hidden;
  max-width: 238px;
  text-overflow: ellipsis;
  white-space: nowrap;


}

.listyle {
  display: flex;
  padding: 12px 0px 12px 0px;
  width: fit-content;
  min-width: 197px;

}

.filePetUpload {
  width: auto;
  height: 28px;
  color: rgb(78, 76, 74) !important;
  font-size: 20px !important;
  font-family: EtihadAltis-Book !important;
  font-weight: 300 !important;
  letter-spacing: 0px;
  line-height: 28px;
  min-width: 136px;


}

.inputmedical {
  padding: 0px;
  margin-top: 30px;
}

.additionalinstruction {
  height: auto;
  color: rgb(78, 76, 74);
  font-size: 16px;
  font-family: EtihadAltis-Book;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 22px;
}

.snubnosedCheck {
  color: rgb(78, 76, 74) !important;
  font-family: EtihadAltis-Book !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  height: 22px;
  letter-spacing: 0px;
  line-height: 22px;
}

.breedSelect {
  background: rgb(255, 255, 255) !important;
  border-radius: 4px !important;
  border: 1px solid rgb(78, 76, 74) !important;
  height: 50px !important;
  width: 100%;
}

.breedName {
  cursor: auto;
}


.dgunidName {
  background-color: #E1EAF6 !important;
  border-radius: 4px !important;
  border: 1px solid rgb(78, 76, 74) !important;
  height: 50px !important;
  width: 380px !important;

  &.div-readonly {
    overflow: hidden;
    line-height: 35px;
    word-break: break-all;
  }

  .pi-chevron-down {
    background: #E1EAF6 !important;
  }
}

.snbnbtn {
  color: rgb(78, 76, 74);
  font-family: EtihadAltis-Book;
  font-size: 16px;
  font-weight: 300;
  height: 22px;
  letter-spacing: 0px;
  line-height: 22px;
  width: 27px;
}

.monthstyle {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(78, 76, 74);
  height: 50px;
  width: 158px;
  padding-left: 0.75rem;

}

.animalsCount {
  background: rgb(255, 255, 255) !important;
  border-radius: 4px !important;
  border: 1px solid rgb(78, 76, 74) !important;
  height: 50px !important;
  width: 376px !important;
  cursor: auto !important;
}

.petdimension {
  background: rgb(255, 255, 255);
  border-radius: 4px !important;
  height: 50px !important;
  width: 120px !important;
  margin-bottom: 8px !important;
}

.lengthp {
  margin-left: 40px !important;
}



.lengthl {
  margin-left: 38px !important;
}

.petcageheight {
  width: 115px !important;
}

.cageweight {
  height: 23px;
  padding-top: 14px;
  @media (min-width: 768px) { 
    max-width: 183px;
  }
}

.cagedimension {
  height: 23px;
  margin-top: 0;
  @media (min-width: 768px) { 
    max-width: 115px;
  }
}



.textarea1 {
  resize: horizontal;
  width: 768px;
}

.textarea1:active {
  width: auto;
  height: auto;
}

.textarea1:focus {
  min-width: 768px;
  min-height: auto;
}

.fileicon {
  height: 20px;
  width: 19px;
  margin-right: 19px;
}

.yearslabel {
  padding-left: 2px
}


.codedg-type-btn {
  height: 41px;
  width: 270px;
  position: relative;


  #dgy-code-btn {
    position: absolute;
    top: 12px;
  }

  #dgn-code-btn {
    position: absolute;
    top: 2px;
    left: 80px;
  }

  .dgcheck-label {
    position: absolute;
    left: 24px;
    color: rgb(78, 76, 74);
    font-family: EtihadAltis-Book !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    height: 22px;
    letter-spacing: 0px;
    line-height: 22px;
    width: 105px;

  }

  .dgcheckn-label {
    color: rgb(78, 76, 74);
    font-family: EtihadAltis-Book !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    height: 22px;
    letter-spacing: 0px;
    line-height: 22px;
    width: 18px;
    left: 104px;
    position: absolute;
  }


  .p-radiobutton .p-radiobutton-box {
    padding: 2px 2px;
    width: 18px;
    height: 18px;
    border-color: $textcolor;
  }

  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 12px;
    height: 12px;
    background-color: #bd8b13;
    border: $textcolor;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $textcolor;
    background: white;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: none;
    background: white;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: white;
    border-color: $textcolor;
  }
}



.dg-label {
  color: rgb(78, 76, 74) !important;
  font-family: EtihadAltis-Book !important;
  font-size: 16px !important;
  font-weight: 300;
  height: 22px;
  letter-spacing: 0px;
  line-height: 22px;
  width: 100%;

  &.disabled {
    color: $labelColor !important;
  }
}

.dg-selectBtn {
  background: rgba(255, 255, 255, 0);
  border: 1px solid rgb(0, 95, 150);
  height: 20px;
  width: 20px;
}

.specify-id {
  color: rgb(78, 76, 74);
  font-family: EtihadAltis-Book;
  font-size: 16px;
  font-weight: 300;
  height: 22px;
  letter-spacing: 0px;
  line-height: 22px;
  width: 160px;
}

.dg-unid {
  padding: 0px;
  width: 460px;
}

.additional-dg-style {
  align-items: center;
  padding-top: 10px;
}

.addunid {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(78, 76, 74);
  height: 40px;
  width: 221px;
}

.dg-unid-input {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  height: 50px;

}

.custom-padding-dg {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

div.is-disabled {
  pointer-events: none;
  opacity: 0.7;
}