.l-btn {
  padding: rem(5) rem(15);
  background-color: $white;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 4px;
  display: inline-block;
  border: 1px solid $primary;
  letter-spacing: 0.2px;
  height: 50px;

  &--primary {
    background-color: $primary;
    color: $white;
    font-family: $font-bold;

    &:hover {
      background-color: $hover;
    }

    &:focus {
      outline: 0;
      // border: 1px solid #fff;
      box-shadow: 0px 7px 8px -4px rgba(189, 139, 19, 0.2), 0px 12px 17px 2px rgba(189, 139, 19, 0.14), 0px 5px 22px 4px rgba(189, 139, 19, 0.12);
    }
  }

  &--secondary {
    color: $textcolor;
    border: solid 1px $textcolor;
    outline: none !important;
    font-family: $font-bold;

    &:hover {
      background-color: $textcolor;
      color: $white;
    }
  }

  &--black {
    background-color: #4E4C4A;
    color: $white;
    border: 1px solid #4E4C4A;

    &:hover {
      background-color: #4E4C4A;
    }

    &:focus {
      outline: 0;
      border: 1px solid #4E4C4A;
      box-shadow: 0px 7px 8px -4px #F4F4F5, 0px 12px 17px 2px #f4f4f4, 0px 5px 22px 4px #F4F4F5;
    }
  }

  &--cancel {
    background-color: $white;
    color: $primary;
    border: 1px solid $primary;

    &:hover {
      background-color: $white;
    }

    &:focus {
      outline: 0;
      border: 1px solid $primary;
      box-shadow: 0px 7px 8px -4px #F4F4F5, 0px 12px 17px 2px #f4f4f4, 0px 5px 22px 4px #F4F4F5;
    }
  }

  &--xs {
    // padding: rem(5) rem(15);
    // font-size: rem(14);
  }

  &--large {
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: $buttonFont;
    font-family: $font-bold;
  }

  >i {
    font-size: rem(12);

    +span {
      margin-left: 5px;
    }
  }
}

.l-input-icon {
  position: absolute;
  left: rem(12);
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  +.form-control {
    padding-left: rem(26);
  }
}


label {
  color: $textcolor;
}

.l-radio {
  position: relative;

  input[type="radio"] {
    opacity: 0;
  }

  label {
    padding-left: 10px;
    cursor: pointer;
    font-size: rem(13);

    &:before {
      width: 18px;
      height: 18px;
      position: absolute;
      top: 1px;
      left: 1px;
      content: '';
      border-radius: 50%;
      border: 1px solid $textcolor;
      background: #fff;
    }

    &.checked {
      &::after {
        content: '';
        position: absolute;
        top: 4px;
        left: 4px;
        display: inline-block;
        font-size: rem(11);
        width: 12px;
        height: 12px;
        background-color: $primary;
        border-radius: 50%;
      }
    }
  }

  input[type="radio"] {
    margin: 0px;

    &:disabled+label {
      color: #999;

      &:before {
        background-color: $primary;
      }
    }

    // &:checked + label::after {
    //   content: '';
    //   position: absolute;
    //   top: 5px;
    //   left: 4px;
    //   display: inline-block;
    //   font-size: rem(11);
    //   width: 10px;
    //   height: 10px;
    //   background-color: $primary;
    //   border-radius: 50%;
    // }

  }
}


.l-checkbox {
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -1;

    &:disabled {
      +label {
        color: $disabledFill;

        &:before {
          border: 1px solid $disabledFill;
          opacity: 1;
        }
      }
    }

    +label {
      position: relative;
      display: inline-block;
      padding: 0 0 0 24px;
      line-height: 18px;
      cursor: pointer;
      color: $textcolor;
      //font-family: $font-book !important;
      font-size: 20px;

      &:before,
      &:after {
        position: absolute;
        top: 0;
        left: 0;
      }

      &:before {
        content: " ";
        border: 1px solid $textcolor;
        border-radius: 2px;
        width: 18px;
        height: 18px;
      }

      &:after {
        width: 6px;
        height: 10px;
        left: 6px;
        top: 2px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: scale(0);
        opacity: 0;
        content: '';
      }
    }

    &:checked+label {
      color: $textcolor;

      &:after {
        transform: scale(1) rotate(45deg);
        opacity: 1;
      }

      &:before {
        background-color: $primary;
        border: none;
      }
    }
  }
}


.l-link {
  color: $link;
  font-size: $linkFont;
  position: relative;

  >i+span {
    margin-left: 6px;
  }

  &:hover {
    color: $link;

    &:after {
      content: " ";
      width: 1px;
      height: 100%;
      top: 0;
      left: 0;
      border-bottom: 1px solid #005f96;
      position: absolute;
      width: 100%;
    }
  }
}

.l-selectbox {
  border: solid 1px $border;
  position: relative;
  width: min-content;
  border-radius: 20px;
  overflow: hidden;

  >select {
    appearance: none;
    padding-right: 20px;
    padding: 3px 25px 3px 12px;
    color: $link;
  }

  &:after {
    width: 0px;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid $link;
    content: "";
    right: 10px;
    top: 11px;
    position: absolute;
  }
}

.l-suggested {
  background: #f7f0e0;
  position: absolute;
  left: 0;
  top: 10px;
  padding: 1px 6px;
  color: $primary;
  text-transform: uppercase;
  font-size: 10px;
  border-radius: 0 6px 6px 0;
}

.p-dropdown {
  box-shadow: none !important;
}

.p-dropdown-panel,
.p-menu-overlay {
  border: 1px solid $darkGrey !important;

  .p-highlight {
    color: $black !important;
    background-color: $bgcolor !important;
  }

  .p-dropdown-item,
  .p-menuitem-link {
    padding: 0.822rem 0.857rem;
    font-family: $font-family;
    font-size: 17px;
    color: $textcolor;
  }
}

.input-wrapper,
.p-inputwrapper {
  position: relative;

  .l-input-icon {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 6px;
    transform: translate(0, -50%);
  }

  .p-autocomplete-panel {
    position: absolute;
    margin: 0;
    top: 38px !important;
    box-sizing: border-box;
    overflow: visible;
    border: 1px solid $darkGrey !important;

    .p-autocomplete-items {
      overflow-y: auto;
      max-height: 180px;

      li {
        width: 100%;
        white-space: normal;

        @media (min-width: 768px) {
          white-space: nowrap;
        }
      }
    }

    .p-autocomplete-item {
      padding: 0.822rem 0.857rem;
      font-family: $font-book;
      font-size: 17px;
      color: $textcolor;

      &:hover {
        color: $black;
        background-color: $bgcolor !important;
      }
    }

    .p-highlight {
      color: $black !important;
      background-color: $bgcolor !important;
    }

    &::before {
      width: 0px;
      height: 0px;
      border: 7px solid transparent;
      border-bottom-color: $grey;
      content: "";
      bottom: 0;
      position: absolute;
      left: 0;
      right: 50%;
      top: -14px;
      margin: 0 auto;
    }

    &::after {
      width: 0px;
      height: 0px;
      border: 6px solid transparent;
      border-bottom-color: $white;
      content: "";
      bottom: 0;
      position: absolute;
      left: 0;
      right: 50%;
      top: -12px;
      margin: 0 auto;
    }
  }
}