@import '../../assets/scss/abstracts/abstracts';
/* Absolute Center Spinner */
.spinnerdiv {
    border-radius: 10px;
    position: fixed;
    z-index: 9999;
    height: 70px;
    width: 70px;
    overflow: visible;
    margin: auto;
    top: -172px;
    left: -324px;
    bottom: 0;
    right: 0;
}
/* Transparent Overlay */
.spinnerdiv:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
}
/* :not(:required) hides these rules from IE9 and below */
.spinnerdiv:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}
.spinnerdiv .loading__inner{
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.98);
    backface-visibility: hidden;
    transform-origin: 0 0;
    div{
      position: absolute;
      width: 66px;
      height: 66px;
      border: 4px solid $primary;
      border-top-color: transparent;
      border-radius: 50%;
      animation: spinner 1s linear infinite;
      top: 50px;
      left: 171px;
      box-sizing: content-box;
    }
  }

@keyframes spinner {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
