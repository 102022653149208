@import '../../../assets/scss/abstracts/abstracts';

.cargo-portal-container {
    .previlege_heading{
       font-family: $font-family;
       color: $textcolor;
       font-size: 16px;
    }
    .create-user-panel {
        background-color: $white;
        /*padding: 71px 100px 100px;*/
        .user-information-panel{
            background-color: $white;
            border-radius: 15px;
            @media (min-width: 768px) {
                box-shadow: 0px 3px 6px #00000029;
                padding: 30px 35px;
            }
        }
        .form-group {
            .cust-dropdown {
                background: $white;
            }
            .p-dropdown {
                .p-dropdown-label {
                    padding-top: 6px;
                    padding-left: 5px;
                }
                .p-dropdown-trigger{
                    right:0;
                    .pi-chevron-down {
                        background: $white !important;
                    }
                }
            } 
        }
    }
}
