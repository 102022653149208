
.cargo-portal-container {
  .list-user{
    
      .form-group{
        margin-bottom: 2rem !important;
      }
      .m-t-28{
        margin-top: 28px;
      }
      .pull-right{
        float: right;
      }
      .p-t-5{
        padding-top: 5px;
      }
      .addUserButton{
          border: 1px solid #4E4C4A;
          border-radius: 6px;
          background: #FFFFFF 0% 0% no-repeat padding-box;
          width: 100%;
          padding: 0.5rem 0.75rem;
          color: #4E4C4A;
          font-size: 20px;
          font-family:'EtihadAltis-Bold';
          display: flex;
          justify-content: center;    
          align-items: center;
          @media (min-width: 992px) { 
              width: 158px;
          }      
        .plusIconWrapper{
          color: #7A9A01;
          font-size: 25px;       
        }
      }
      .p-input-icon-right{
        margin-top: 50px;
      }
      .form-row{
        margin-left: 0px;
        display: flex;
        flex-wrap: nowrap;
      }
      #AgentCode{
        height: 50px;
      }
      #btn-template-search{
        margin-left: 20px;
        height:50px;
        margin-top: 50px
      } 
      .noUser {
        color: #41464b;
        background-color: #F5D5D6;
        border-color: #d3d6d8;
        height: 50px;
        margin-top: 50px;
        
      }
      .message{
        height: 40px;
        font-family: "EtihadAltis-Text", sans-serif;
        font-size: 15px;
        margin-top: 15px;
      }
      .row{
        margin-top: 25px;
        //background-color: #F5D5D6;
       
        
      }
    }
}