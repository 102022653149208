@import '../../scss/abstracts/abstracts';

.get-quote{
    padding: 0 0 rem(10);
    margin-top: 51px;
    .booking-heading {
        h3.page-heading {
           padding-left: 15px;
           font-size: 22px;
           font-family: 'EtihadAltis-Text';
           padding: 0 0 0 15px;
            margin: 0;
           @media (min-width: 768px) {
            font-size: 24px;
            font-family: "EtihadAltis-Medium", sans-serif;
            padding-bottom: 10px;
            padding-right: 6px;
            padding-top: 17px;
            margin-bottom: 10px;
           }
        }
    }
}
