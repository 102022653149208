.body {
    box-sizing: border-box;
}

.custom_overlay_panel {
    margin-top: 0;
    margin-left: 0;
    width: 80% !important;
    height: auto;
    transform-origin: left bottom !important;
}

div {
    //border: solid 1px;
    box-sizing: border-box;
}

.form-container {
    align-items: flex-start;
}

.form-columns {
    //color: red !important;
    width: 50%;
    margin: 0;
    //border-left: solid 2px rgb(177, 176, 176);
    // height: 100px;
}

.border-BR {
    border-right: dotted 1px grey;
}
.popup-header {
    text-align: right;
    padding: 15px 0;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 20px;
}
.popup-footer {
    text-align: right;
    padding: 15px 0;
    border-top: 1px solid #d9d9d9;
    margin-top: 20px;
}

.checkbox-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.checkbox-custom {
    padding: 0 1rem 0 0;
}

.checkbox-custom .checkbox-item {
    position: relative;
    padding-top: 0;
    padding-left: 1.7857142857rem;
    display: flex;
}

.checkbox-custom .checkbox-item__label {
    font-size: 16px;
    line-height: 1;
    margin: 0;
    cursor: pointer;
    padding: 0;
    font-family:"EtihadAltis-Text", sans-serif;
}

.checkbox-custom .checkbox-item__label:before {
    width: 20px;
    height: 20px;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0.7142857143rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border: 1px solid #4e4c4a;
    border-radius: 2px;
    background-color: #fff;
}

.checkbox-custom .checkbox-item__field {
    position: absolute;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    clip: rect(0 0 0 0);
}

.checkbox-custom .checkbox-item__field:checked+.checkbox-item__label:before {
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="M1 7l3.876 3.287a1.024 1.024 0 0 0 1.425-.153L13 2" stroke="%23ffffff" stroke-width="1.3" fill="none" fill-rule="evenodd"/></svg>');
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    overflow: hidden;
    background-color: #bd8b13;
}

.custom-filterDate {
    .p-datepicker {
        min-width: 400px;
    }

    .p-inputtext.p-component {
        font-size: 18px;
        color: #4D4F5C;
        font-family: "EtihadAltis-Book", sans-serif;
    }
}

.filter-title{
    font-weight: bold;
}

.clear-filter{
    margin-left: 95%;
    font-size: 18px;
    color: blue;
}
.placi-filter {
    .checkbox-custom {
        .checkbox-item__label {
            font-size: 14px;
        }
    }
}