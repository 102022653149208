@import '../../../assets/scss/abstracts/abstracts';
.data-capture-wrapper {
    padding-top: 32px;
    .page-heading{
        font-size:32px;
        font-weight: 500;
    }
    .data-capture-declaration {
        .declaration-content {
            max-height: calc(100vh - 500px);
            overflow: auto;
        }
    h3 {
        font-family: $font-medium;
        margin-bottom: 40px;
    }
    h4 {
        font-size: 20px;
        font-family: $font-medium;
        margin-top: 35px;
        margin-top: 25px;
    }
    h5 {
        font-family: $font-medium;
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 15px;
        text-decoration: underline;
    }
    }
}