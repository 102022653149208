// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
$font-family:'EtihadAltis-Text', sans-serif;
$font-medium:'EtihadAltis-Medium', sans-serif;
$font-bold:'EtihadAltis-Bold', sans-serif;
$font-book: 'EtihadAltis-Book', sans-serif;

// COLORS
$primary: #bd8b13;
$secondary: #004164;
$tertiary: #fac832;
$link: #005F96;
$hover: #976F0F;
$grey: #aaa;
$darkGrey: #707070;
$lightbgColor: #FCFCFC;
$border: #d9d9d9;
$lightBorderColor:#C3C3C3;
$secondaryDropdownColor: #EFF3F8;
$dropdownBorderColor:#DCDBDB;
$focuscolor:#005fcc;
$textcolor: #4E4C4A;
$textdark: #333;
$bgcolor: #e4e4e4;
$white: #fff;
$black:#000;
$invalid: #FF0000;
$dropdownSub:#9fbfdf;
$labelColor: #959492;
$em-base: 14;
$disabledBorder: #DCDBDB;
$disabledFill: #DCDBDB;
$sccBgColor: #E1EAF6;
$titleColor:#4D4F5C;
$cargoLabelColor: #9697A0;
$docInfoColor: #F68D2E;
$lightGrey: #EDEDEC;
$lightGreen: #82BD13;
$green: #7A9A01;
$yellow: #FFC72C;
$red: #CB2C30;
$charcoalGrey: #959492;
$falconGrey: #D6D2C4;
$etihadgold2: #CAA242;
//FONTS
$controlFont: 16px;
$fontWeightDefault: 700;
$rateFont: 20px;
$stationFontSize: 14px;
$linkFont: 14px;
$flightNoFont: 14px;
$subTitleFont: 18px;
$grandTotalFont: 30px;
$buttonFont: 20px;
$titleFont: 32px;
$labelFont: 16px;
$textFont:20px;
:root {
  --primary: #bd8b13;
  --white: #fff;

  --em-base: 14;
  --font-family:'EtihadAltis-Book', sans-serif;

}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
  xxl: 1170px,
  xxxl: 1170px
);
