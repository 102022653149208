@import '../../../assets/scss/abstracts/abstracts';

.chart-filter-component {
    margin-bottom: 24px;
    .p-selectbutton.p-buttonset .p-button {
        font-size: 16px;
        padding: 10.5px 8.5px;
        height:50px;
        @media screen and (min-width:1200px) {
            font-size: 18px;
            padding: 10.5px 12.5px;
        }
        @media screen and (min-width:1400px) { 
            min-width: 105px;
            padding: 10.5px 12.5px;
        }
        @media screen and (max-width:767px) { 
            min-width: 100px;
            padding: 10.5px 13.5px;
        }
    }
    .updated-time{
        font-size: 16px;
        font-family: $font-book;
        margin-top: 0rem;
        text-align: right;
        padding-top: 1rem;
        @media (min-width: 992px) { 
            padding-top: 0;
        }
    }
    .form-item--select{
        height: 50px;
    }
    .currency-selector {
        min-width: 90px;
        margin-left: 20px !important;
    }
    .cost_selector {width: auto;}
    .updated-time_wrap {
        flex: 1;
    }
    .chart-filter-row {
        min-height: 50px;
        display: flex;
        align-items: center;
        @media screen and (min-width:992px) { 
            min-height: 1px;
        }
        &:first-child {
            margin-top: 1rem;
            @media screen and (min-width:992px) { 
                margin-top: 0;
            }
        }
    }
    // .form-item{
    //     height:50px
    // }
    /*.toggleButton{
        .p-togglebutton.p-button,.p-button:hover{
            background: #bd8b13;
            border-color: #bd8b13;
        }
        .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover{
            background: #bd8b13;
            border-color: #bd8b13;
            color: white;
        }
        .p-button:focus {
           
            box-shadow: none;
        }
        .p-button-label{
            font-size: 16px;
        }
    }*/
}