@import '../../assets/scss/abstracts/abstracts';

.filter{
  border-bottom: solid 1px $border;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 rem(20);
  flex-wrap: wrap;
  &-icon {
    width: 14px;
    margin-left: 10px;
  }
  &_wrap {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: space-between;
    @media (min-width: 992px) {
      width: 100%;
    }    
    ul {
      display: block;
      width: 100%;
      text-align: right;
      @media (min-width: 992px) {
        width: 100%;
        text-align: left;
      }    
    }
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    padding: rem(15) 0;
    align-items: center;
  }
  &__item{
    margin: 4px 5px;
    display: inline-block;
    vertical-align: middle;
  }
  &__icon{
    color: $grey;
  }
  &__active{
    background: #EDEDEC;
   // font-family: $font-medium !important;
  }
}
.filter-option{
  display: flex;
  flex-wrap: wrap;
  &__item{
    padding: rem(23) rem(5);
    @include respond(lg) {
      padding: rem(10);
    }
    &.active{
      position: relative;
      &::before{
        width: 0px;
        height: 0px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $border;
        content: "";
        bottom: 0;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      &::after{
        width: 0px;
        height: 0px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff;
        content: "";
        bottom: -1px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    a{
      display: flex;
      font-family: $font-medium;
    }
  }
  li + li{
    border-left: solid 1px $border;
    margin-left: 10px;
    padding-left: 10px;
    @include respond(lg) {
      border-left:0;
    }
  }
}
span .active {
  font-weight: bold;
}
.custom_dropdown{
  // width: 50%;
  .p-dropdown:not(.p-disabled).p-focus{
      box-shadow: none !important
    }
}
.stops-radio-wrapper{
  font-size: $labelFont;
  .form-item__label{
    font-family: $font-book;
    font-size: 16px;
  }
}
.custom-dropdown{
  .form-control{
    line-height: 2rem;
  border: solid 1px #d9d9d9;
    position: relative;
    width: 77px;
    border-radius: 20px;
    &:hover{
      border: solid 1px #d9d9d9;
    }
  }
   .p-dropdown:not(.p-disabled).p-focus{
      box-shadow: none !important
    }
}
.custom-slider{
  width:78%;
  margin-top: 15px;
  margin-bottom: 20px;
}
.time-panel{
  border-radius: 4px;
  background: #EDEDEC !important;
  .p-inputtext{
    padding-top: 10px;
    padding-bottom: 6px;
    padding-left: 13px;
    border: solid 1px transparent;
    border-radius: 4px;
    font-size: 16px;
    font-family: $font-bold;
    box-shadow: none !important;
    cursor: default;
     background: #EDEDEC;
     cursor
     &:hover,&:focus{
border: solid 1px transparent;
     }
  }
  .p-inputtext:enabled:hover{
    border: solid 1px transparent;
  }
  .p-inputgroup-addon{
    border: solid 1px transparent;
    border-radius: 4px;
  }
  .fa-clock{
    width: 1.2rem;
    height: 1.2rem;
  }
}

.cusom_returntime{
  margin-top: 20px;
  font-family: $font-book;
  font-size: 16px;
}
.custom_input_group{
  .p-inputgroup{
    width:40%;
    margin-top: 10px;
    border: solid 1px $textcolor;
    border-radius: 4px;
    font-family: $font-family;
    font-size: $labelFont;
    input{
      box-shadow: none !important;
      height: 45px;
      border: 0;
       font-family: $font-book;
      font-size: 20px;
                color: $titleColor;

    }
    span{
          border: 0;
          color: $titleColor;
          font-family: $font-book;
    }
  }
}
.custom_overlay_panel{
  // top: 24% !important;
  // left: 20% !important;
 // border-radius: 8px;
    border: 1px solid $darkGrey;
  //box-shadow: 0px -1px 6px #00000029;
  .duration-section{
    margin-top: 15px;
    @media (min-width: 992px) {
      display: flex;
    }    
    .custom-slider{
      width:90%;
    }
    .silder-div{
      width: 80%;
      @media (min-width: 768px) {
        padding-right: 10px;
      }  
      @media (min-width: 1200px) {
        padding-right: 0;
      }  
    }
    .p-inputgroup{
          width: 65%;
          margin-top:5px;
    }
    .duration-div{
      margin-top: 7px;
    }
  }
  .allotment-filter-section{
    margin: 8px 0;
    display: flex;
    .l-checkbox{
      margin: 0 10px;
    }
  }
  .all-filters{
    display: inline-flex;
    margin: 10px 17px;
    border-bottom: 1px solid $border;
    padding-bottom: 10px;
  }
  .p-selectbutton.p-buttonset{
    .p-highlight{
      background: #DCDBDB !important;
    }
     .p-button{
    font-family: $font-bold;
      font-size: $controlFont;
      color:$black;
      background: $white;
      border-color: $textcolor;
      box-shadow: 0px 2px 3px #0000000D;
          width: auto;
          padding: 10.5px 13.5px;
    }
  }
  button{
            font-size: 20px;

  }
  width:58%;
  .seperator{
    margin:auto 0;
  }
  .p-slider .p-slider-range{
    background-color: $primary;
  }
  .p-slider.p-slider-horizontal .p-slider-handle{
     background-color: $primary;
     border-color: $primary;
  }
  .p-button{
    width:100%;
  }
  .p-slider.p-slider-horizontal{
    margin-left: 9px;
  }
  .p-dropdown .p-dropdown-trigger{
    right:2%;
  }
  .border-rghtDiv{
    border-right: dotted 1px grey;
    padding-right: 0;
  }
  .filter-title{
    font-family: $font-book;
    font-size: $labelFont;
    color: $textcolor;
  }
  .under-text{
    font-size: $controlFont;
    font-family: $font-book;
    color: $textcolor;
  }
}
.clearFilter{
  color:$link;
  cursor: pointer;
  font-family: $font-book;
  font-size: $subTitleFont;
  display: inline-block;
  @media (min-width: 992px) {
    float: right;
  }    
}
.showFilter{
  color: $link;
  cursor: pointer;
  font-size: 16px;
  font-family: $font-book;
  @media (min-width: 576px) {
    margin-left: 16px;
    font-family: $font-family;
  }    
  &_Mob {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 9;
    overflow: hidden;
    overflow-y: auto;
    padding: 15px;
    margin-top: 60px;
    padding-bottom: 80px;
    @media (min-width: 992px) { 
     display: none;         
    }
    &-close {
      padding: 0 5px;
      margin: 20px 0 10px 0;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      img {
        width: 15px;
      }
    }
    .all-filters {
      background: rgb(255, 255, 255);
      border-radius: 12px;
      box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 25%);
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: flex-end;
      padding: 15px;
    }
    .filter-title, .cusom_returntime, .under-text {
      font-family: "EtihadAltis-Book", sans-serif;
      font-size: 16px;
      margin-top: 20px;
    }
    .duration-section {
      width: 100%;
    }
    .buttons-div {
      width: 100%;
      > * {
        width: 100%;
        margin-bottom: 24px;
      }
    }
    .custom-slider {
      width: calc(100% - 30px);
      height: 12px;
      margin: 0 20px 0 10px;
      margin-top: 20px;
      margin-bottom: 25px;
      .p-slider-handle {
        height: 24px;
        width: 24px;
        margin-top: -0.8715rem;
        background: rgb(189, 139, 19);
        border: 1px solid rgb(255, 255, 255);
      }
    }
    .p-slider-range {
      background: rgb(189, 139, 19);
    }
    .filterField_wrap {
      // display: flex;
      // margin: 0 -7.5px;
      width: 100%;
      // .filterField {
      //   margin: 0 7.5px;
      // }
    }
    .time-panel .p-inputtext {
      border: 1px solid rgb(78, 76, 74);
      border-radius: 4px;
      font-size: 20px;
      font-family: 'EtihadAltis-Book';
      background: #fff;
      &:enabled{
        &:hover {
          border: 1px solid rgb(78, 76, 74);
        }

      }
  }

  }
}
.sort-overlay-panel.p-overlaypanel{
&:before{
      margin-left: 14.5%;
        border-bottom-color: #4E4C4A;
}
&:after{
  margin-left: 16%;
}
}


.sort-overlay-panel{
      // left: 24% !important;
      border-radius: 4px;
      left: 50%;
      border-radius: 4px;
      border: 1px solid $darkGrey;
      box-shadow: 0px -1px 6px #00000029;
      
 .p-overlaypanel-content{
      padding: 0 !important;
    }
    min-width: 12%;
    li:first-child{
      border-top-left-radius: 8px;
       border-top-right-radius: 8px;
    }
    li:last-child{
      border-bottom-left-radius: 8px;
       border-bottom-right-radius: 8px;
    }
     li{
    padding: 12px;
    border-bottom: solid 1px $border;
    cursor:pointer;
    font-family: $font-book;
    font-size: 17px;
    padding-right: 40px;
      }
    
}
.cusom_label{
  margin: 20px 0 5px;
  display: block;
  font-family: $font-book;
  font-size: $labelFont;

}
.buttons-div{
  text-align: right;
  div{
    float: right;
    padding-left: 0;
  }
}
.button__first{
  display: inline-block;
      background-color: $primary !important;
      color: $white;
      border-color: $primary;
      padding: rem(5) rem(15);
      font-size: rem(14);
      outline: none;
      box-shadow: none !important;
      &:hover{
        background-color: $hover !important;
        border: 1px solid $primary;
        border-color: $primary !important;
      }
      }
      .button__second{
         display: inline-block;
      background-color: $white;
      padding: rem(5) rem(15);
      box-shadow: none !important;
      font-size: rem(14);
      color: #976F0F;
      border-color: $primary;
      outline: none;
      &:hover{
    background-color: $white !important;
    border: 1px solid $primary;
    border-color: $primary !important;
    color: $primary !important;
      }
      }
          .sort-by{
          font-weight: normal;
          color: $textcolor;
              display: inline-block;
    white-space: nowrap;
    border-radius: 3px;
    background-color: #E0EAF6;
    border: 1px solid $darkGrey;
    font-size:$flightNoFont;
    padding: 5px 18px;
    cursor: pointer;
    margin-left: 21px;
        }
      .sort-panel{
    
        &::after{
        

  
  top: -5px;
  content: "";
  display: inline-block;
  width: 9px;
  height: 9px;
  border-right: 2px solid #005F96;
  border-top: 2px solid #005F96;
  transform: rotate(135deg);
 margin-bottom: 2px;
        margin-left: 2px;
       
      }
      }
.flight-details__allotment-description{
  font-size: 16px;
  font-family: $font-book;
}
