@import '../../assets/scss/abstracts/abstracts';

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none;
}

.view-quote-page {
  min-height: calc(100vh - 71px);
  padding-bottom: rem(30);
  margin-top: 51px;

  .warning-info-wrapper {
    .alert-inner-wrapper {
      padding: 10px 10px 10px 0px;

      .image-wrapper {
        margin-left: 0rem;
        width: 0px;
        padding-bottom: 0;
        padding-right: 0;
      }
    }

  }

  .no-rate {
    font-size: 14px;
    font-family: 'EtihadAltis-Book';

    @media (min-width: 992px) {
      font-size: 18px;
      font-family: 'EtihadAltis-Text';
    }
  }

  .flight-details {
    &__right {
      flex-direction: row;
      padding-top: 0.5rem;
      padding-bottom: 0;
      width: 100%;

      @media (min-width: 992px) {
        width: 50%;
        padding-top: 0;
        justify-content: flex-end;
        padding-right: rem(30);
        align-items: flex-end;
      }

      &__sub__pricing {
        flex: 1;
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: right;
        font-family: $font-medium;
        align-items: flex-start;
        padding: 6px 8px 4px 0;

        @media (min-width: 992px) {
          flex: auto;
          align-items: flex-end;
          padding: 0;
        }
      }

      &__sub__rate {
        label {
          margin: 0;
        }
      }
    }

    // &__routes{
    //     // margin-bottom: -10px;
    // display: block;
    //   }
    &__item {
      border: 0;
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  .flight__left {
    display: flex;
    padding-left: 29px;
    align-items: center;
    width: 50%;
  }

  .shipment-summary {
    margin: 25px 0px 0px 0;
    padding: 9px 12px;
    font-size: $textFont;

    .awb-number-block {
      .shipment-summary__item {
        padding: 0 2px;
      }

      .separator {
        width: 2px;
        height: 14px;
        background: #000;
        display: inline-block;
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }

  .quote-details {
    background: $white;
    padding: 10px 0px;
    border-radius: 4px;

    .awb-no {
      color: $docInfoColor;
      font-family: "EtihadAltis-Medium", sans-serif;
      font-weight: normal;
    }
  }

  .modify {
    padding: 10px 0px 10px 0
  }

  .free-div {
    min-height: 37px;
    background: $white;
    margin-top: 6px;
  }

  .address-details {
    border: 1px solid $textcolor;
    border-radius: 2px;
    margin: 0 0px 20px 0px;
    border-top: none;

    .flight-details {
      &__right {
        padding: 0;
      }

      &__others {
        display: flex;
        flex-direction: column;

        .address-field {
          height: 67%;
        }

        .details {
          font-size: $controlFont;
          padding-bottom: 12px;
          letter-spacing: -0.09px;
          text-transform: capitalize;
          opacity: 0.8;

          .time-field {
            font-family: $font-bold;
            color: #3B3B3A;
            margin-bottom: 0;

          }
        }

      }

      &__expanded {
        border: 0;
        padding: 0;
      }

    }

    .inner-div {
      display: flex;
      margin: 10px;
      width: 100%;
      background: $lightbgColor;
      padding: 10px 0;
      border-radius: 4px;
    }

  }

  .error-msg {
    color: red;
  }

  .cargo-details {
    padding: 20px 30px 30px 30px;
    margin: 5px 0px 20px;
    margin-top: 15px;
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 6px;

    .cargo-item {
      padding-top: 10px;
    }

    .value-field {
      font-size: $rateFont;
      color: $textcolor;
      font-family: $font-book;
      display: inline-block;

      &__sub {
        line-height: 1;
        font-size: 20px;
        font-family: $font-family;
        color: #959492;
      }
    }

    label {
      font-size: $labelFont;
      color: $textcolor;
      font-family: $font-book;
    }

    .row {
      margin-top: 10px;
    }
  }
  .restrict-space {
    max-width: 300px;
  }

  .l-link {
    font-weight: 300;
    font-size: 16px;
    color: $link;
    letter-spacing: 0px;
    line-height: 16px;
    position: relative;
    top: 0px;
    right: 0px;
    display: inline-block;
    cursor: pointer;

    &:hover {
      color: $link;
    }

    &::after {
      content: " ";
      width: 1px;
      height: 100%;
      top: 0;
      left: 0;
      border-bottom: 1px solid #005f96;
      position: absolute;
      width: 100%;
    }
  }

  .booking-details {
    padding: 5px;
    margin: 15px 20px 0px 0px;
    padding-left: 16px;

    .plus-sign {
      border: solid 1px $border;
      border-radius: 5px;
      cursor: pointer;
      padding: 0px 8px;
      margin-left: 10px;
      background: #e6e3e3;
      font-size: 13px;
    }

    .row:first-child {
      border-top: 1px solid #d9d9d9;
    }

    .total {
      &.row {
        border-bottom: 0;

        @media (min-width: 992px) {
          border-bottom: 1px solid #d9d9d9;
        }
      }

      .total-cost {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
      }

      border-top:1px solid #d9d9d9;

      h4 {
        color: $textcolor;
        padding-bottom: 10px;
        font-size: 14px;
        font-family: $font-book;

        @media (min-width: 992px) {
          font-size: $subTitleFont;
          font-family: $font-bold;
        }
      }

      span {
        color: $primary;
        font-family: $font-medium;
        font-size: 20px;

        @media (min-width: 992px) {
          font-family: $font-bold;
          font-size: $grandTotalFont;
        }
      }

      .exclude-text {
        color: $titleColor;
        width: 60%;
        font-size: 14px;
        margin-top: 12px;
        font-family: $font-book;

        @media (min-width: 992px) {
          font-size: $labelFont;
          margin-top: 0;
        }
      }
    }

    .subcharges {
      border-bottom: none !important;

    }

    .charges-row {
      // margin-right: 16px;
      display: inline-block;
      padding-left: 0;
    }

    .sub-charges {
      padding: 0;
      border: 0 !important;

      .rate-field {
        font-size: 16px;
        font-family: $font-family;
      }

      .per-rate-field {
        font-size: 18px;
      }

      .charges-row {

        h4 {
          color: $titleColor;
          font-size: 14px;

          @media (min-width: 992px) {
            font-size: 16px;
          }
        }

      }
    }

    h4 {
      color: $textcolor;
      font-size: 14px;
      font-family: $font-book;
      margin: 0;
      padding: 0;

      @media (min-width: 992px) {
        font-size: $subTitleFont;
        font-family: $font-family;
        margin-bottom: 0.5rem;
        padding-bottom: 10px;
      }
    }

    .rate-field {
      color: $titleColor;
      display: inline-block;
      text-align: right;
      font-size: 16px;
      font-family: $font-book;

      @media (min-width: 992px) {
        font-family: $font-medium;
        font-size: $buttonFont;
      }

      &#charges-styling {
        font-family: $font-book;
        font-size: 16px;

        @media (min-width: 992px) {
          font-family: "EtihadAltis-Book", sans-serif;
          font-size: 20px;
        }
      }
    }

    .per-rate-field {
      display: inline-block;
      color: $titleColor;
      text-align: right;
      font-family: $font-book;
      font-size: 14px;

      @media (min-width: 992px) {
        width: 25%;
        font-size: $subTitleFont;
        font-family: $font-family;
      }
    }

    .charge-weight {
      color: $titleColor;
      font-size: 16px;
      font-family: $font-book;

      @media (min-width: 992px) {
        font-size: $buttonFont;
        font-family: $font-bold;
      }

      .value-field {
        font-size: $rateFont;
        color: $textcolor;
        font-family: $font-bold;
        display: inline-block;

        &__sub {
          line-height: 1;
          font-size: 16px;
          font-family: $font-family;
          color: #959492;
        }
      }
    }

    .row {
      padding: 16px 0px;
      border-bottom: 1px solid $border;
      align-items: center;

      @media (min-width: 992px) {
        padding: 12px 0px 8px 0px;
      }

    }
  }

  .awbSpotWrap {
    display: flex;
    margin-top: 1rem;

    @media (min-width: 992px) {
      border-bottom: 1px solid #dee2e6;
      margin-top: 0;
    }

    .border-bottom {
      padding: 12px 0px 8px 0px;
      border-bottom: 1px solid $border;
    }

    .awb-details {
      margin: 20px 0px 0 0px;
      padding-right: 0;

      @media (min-width: 992px) {
        margin: 20px 0px;
      }

      h4 {
        color: $titleColor;
        padding-bottom: 10px;
        font-size: 20px;
        font-family: $font-family;

        @media (min-width: 992px) {
          font-size: $subTitleFont;
          font-family: $font-bold;
        }
      }

      padding-left: 0;

      .details {
        padding-bottom: 16px;
        display: flex;
        // align-items: center;
      }

      .awb-title {
        margin-bottom: 10px;
        color: $titleColor;
        font-size: $labelFont;
      }

      .l-checkbox {
        margin-top: 1rem;
        margin-bottom: 1rem;

        @media (min-width: 992px) {
          margin-top: 0;
        }

        label {
          font-size: $controlFont;
          color: $black !important;

        }
      }
    }
  }

  .spotRate-error {
    border: solid 1px red
  }

  @media only screen and (max-width: 992px) {
    .awbSpotWrap {
      display: flex;
      flex-direction: column;
    }
  }

  .address-details h4 {
    font-size: $stationFontSize;
    font-family: $font-medium;
    margin-bottom: 0;
    opacity: 0.8;
    letter-spacing: -0.08px;
    color: $black;

    span {
      font-family: $font-family;
      font-size: $stationFontSize;
    }
  }

  .awb-details .red-border .form-control {
    border: none;
    border-bottom: solid 1px red;
  }

  .flight-details__connection .view-label {
    left: 93px;
    bottom: -10px;
  }

  .quote-details {
    .m-r-10 {
      margin-right: 10px;
    }

    .title {
      color: $titleColor;
      font-size: 22px;
      font-family: 'EtihadAltis-Text';
      display: flex;
      align-items: center;

      @media (min-width: 992px) {
        margin-bottom: -10px;
        font-size: $titleFont;
        font-family: $font-medium;
        display: block;
      }
    }

    .sub-title {
      font-size: 20px;
      font-family: 'EtihadAltis-Text';
      color: rgb(78, 76, 74);
      margin-top: 3rem;
      margin-top: 48px;

      @media (min-width: 992px) {
        margin-top: 10px;
        color: $titleColor;
        font-size: $subTitleFont;
        font-family: $font-bold;
      }
    }

  }

  .p-dialog {
    max-width: 600px;
    min-width: 300px;

    &-footer {
      border: none;
      background-color: $white;
    }

    &-header {
      padding-bottom: 10px;
      padding-top: 10px;
      background-color: $white;

      .p-dialog-title {
        font-weight: 500;
        font-size: 1.2rem;
      }
    }
  }
  .density-col {
    @media (min-width: 992px) {
      display: inline-block;
      max-width: 56%;
      vertical-align: middle;
    }
  }
}

.m-r-0 {
  margin-right: 0px !important;
}

.p-l-0 {
  padding-left: 0;
}

.p-r-0 {
  padding-right: 0;
}

.p-t-0 {
  padding-top: 0 !important;
}

.w-60 {
  @media (min-width: 992px) {
    width: 60%;
  }
}

.w-15 {
  @media (min-width: 992px) {
    width: 15%;
    white-space: nowrap;
  }
}

.p-r-5 {
  padding-right: 5px;
}

.modify-styling {
  font-size: $labelFont;
}

#charges-styling {
  font-family: $font-book;
  font-size: $buttonFont;
}

#text-font-style {
  font-size: 14px;
  font-family: $font-book;

  @media (min-width: 992px) {
    font-size: $labelFont;
  }
}

#awb-font-style {
  font-size: 16px;
  font-family: $font-book;
  margin-bottom: 1rem;

  @media (min-width: 992px) {
    margin-bottom: 0;
    font-size: 15px;
    font-family: $font-family;
  }
}

#modify-position {
  font-weight: 300;
  color: $link;
  letter-spacing: 0px;
  line-height: 16px;
  position: relative;
  top: -46px;
  right: 25px;
  display: inline-block;
  cursor: pointer;

  &:hover {
    color: $link;
  }

  &::after {
    content: " ";
    width: 1px;
    height: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid #005f96;
    position: absolute;
    width: 100%;
  }

}

.mobile_partition-expanded {
  .cargo-item {
    label {
      font-size: 14px;
      font-family: 'EtihadAltis-Medium';
      padding-left: 2px;
    }
  }

  .value-field {
    font-size: 16px;
    font-family: 'EtihadAltis-Book';
    margin-bottom: 25px;
  }
}

.share_quote {
  button {
    height: 40px;

    @media (min-width: 992px) {
      height: 50px;
    }
  }
}

.summary_title {
  flex: 1;
}