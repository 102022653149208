
@import '../../../../assets/scss/abstracts/abstracts';

.flight-detail-wrapper{
    margin-top: 3px;
    .link-align-center{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-content: center;
    }
    .flight-detail-inner-wrapper {
        .flight-item{
            display: flex;      
            align-items: center;
            border: 1px solid #000000;
            padding: 1.5rem 1rem;
            position: relative;      
            flex-wrap: nowrap;      
            justify-content: space-between;
            box-shadow: 0px 3px 6px #2D1F5008;
            border: 1px solid #DDDDDD;
            border-radius: 4px;           
            font-family: $font-book;

            &__flight-section{
                display: flex;
                justify-content: center;
                align-items: center;       
            }
            .logo{
                width: 100%;              
                img {                    
                    height: auto;
                    width: 80%;
            }
        }
            &__timing{
                display: flex; 
                padding-bottom: 0;
                justify-content: flex-start;
            }
            .connection-wrapper{
                margin: 5px 7px ;
            }

            &__departure{
                display: flex;         
                flex-direction: column;
                line-height: 1;
                white-space: nowrap;
                margin-top: 5px;
                text-align: right;
            }  
            &__arrival{
                display: flex;         
                flex-direction: column;
                line-height: 1;
                white-space: nowrap;
                margin-top: 5px;
            }
            .flight-time-inner-wrapper{
              
                font-family: $font-medium;
                font-weight: medium;
            }     
            &__right{
                display: flex;
                align-items: center;
                justify-content: flex-end;      
        }
        //    &__arrival{
        //     display: flex;         
        //     flex-direction: column;
        //     line-height: 1;
        //     white-space: nowrap;
        
        //    }
        &__price{
            font-family: $font-medium;
    
        &__amount{        
            font-size:20px ;
            color: #BD8B13;
           
        }
            }
        }
    }
}