@import '../../../../assets/scss/abstracts/abstracts';

    
    .cargo-details-wrapper{ 
        .cargo-details-inner-wrapper{
            background: #fff;            
            padding: 0;
            @media (min-width: 992px) {
                padding: 35px 30px;  
            }
        .cargo-details-item{
            font-family: $font-book;
            font-size: 16px;
            word-break: break-word;
            @media (min-width:767px) { 
                font-size: 20px;
            }
            div{
                padding-bottom: 10px;   
                padding-left: 0;
                @media (min-width: 992px) {                            
                    padding-left: 0;
                }  
                label{    
                    font-weight: 500;
                    font-family: $font-medium;  
                    font-size: 14px;
                    padding: 4px 0px;
                    @media (min-width: 992px) {
                        // padding: 4px 0px;
                        font-weight: normal;
                        font-family: $font-book;
                        font-size: 16px;
                    }
                }
            }
        }
       
    }
}
