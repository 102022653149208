@import '../../../assets/scss/abstracts/abstracts';


.news-details-main-wrapper {
    margin: 20px 0;
    //display: none;
    .card-content__img {
        background-size: cover;
        background-position: center;
        border-radius: 12px 12px 0px 0px;
    }
    
    
    
    .carousel-wrapper{
        
        /*Top pagination indicator */ 
        .p-carousel .p-carousel-indicators{
            position: absolute;
            right: 0;
            padding-right: 0;
            display: flex;
            .p-carousel-indicator{
                margin-right:4px;
                button{               
                    height: 4px;
                    width: 35px;
                    border-radius: 0; 
                    background-color: #e5e4e3; 
                    @media (min-width: 992px) { 
                        width: 41px;          
                    }                           
                }
            }
            .p-highlight button{
                background-color: #4e4c4a;
            }            
        }
        
        .p-carousel-content{
            position: relative; 
            @media (min-width: 992px) { 
                position: static;               
            }    
            .p-carousel-container{
                margin-top: 30px;                     
            }
            button:focus{
                box-shadow: none!important;
                outline-offset: none;
                outline: none !important;
            }
            
            .p-carousel-next {
                position: absolute;
                bottom: 0px;
                right: 0px;
                box-shadow: none!important;
                padding-top: 1rem;
                color: #4e4c4a;   
                width: 1rem;   
                display: none;
                @media (min-width: 992px) { 
                    display: block;
                }           
            }
            .p-carousel-prev{
                position: absolute;
                right: 2rem;
                bottom: 0px;
                box-shadow: none!important;
                padding-top: 1rem;  
                color: #4e4c4a;   
                display: none;
                @media (min-width: 992px) { 
                    display: block;
                }                  
            }
            .pi:not(.p-disabled){
                &:hover,&:focus{
                    color: #4e4c4a;
                }
            }
              .pi-chevron-left:before ,
              .pi-chevron-right:before {               
                font-size: 1.5rem;                
              }
            
            .p-carousel-items-content{
                font-family: $font-book;
                font-weight: 300;  
                padding: 5px; 
                margin-bottom: 35px;
            }
            
            .p-carousel-item {
                padding-right: 15px;    
                @media (min-width: 1200px){
                    height: 420px !important;    
                }                         
            }
           
            .p-carousel-item-end{
                //padding-right: 0px;
                margin-right: 10px;

            }
            
            .card-content{
                background-color: rgb(255, 255, 255);
                box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.11);              
                border-radius: 12px;      
                height: 100%;
                display: flex;
                flex-direction: column;
                @media (min-width: 992px) { 
                    display: block;             
                }
                &__img{
                    img{
                        height: 185px;
                        width: 100%;
                        border-radius: 12px 12px 0px 0px;
                        visibility: hidden;
                        @media (min-width: 992px) { 
                            visibility: visible;
                        }
                    }                   
                }
                &__text{
                    padding: 1rem; 
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    @media (min-width: 992px) { 
                        height: 60%;
                        display: block; 
                        //background-color: #45c971;  

                    }
                }   
                &__link {
                    display: flex;
                    flex-direction: row;
                    align-self: end;
                    flex: 1;
                    align-items: flex-end;
                    @media (min-width: 992px) { 
                        display: block; 
                        padding-top: 180px; 
                        
                      
                           
                    }
                }     
                &__heading{
                    max-height: 60px;
                    float: left;
                    overflow: hidden;
               
                    
                }
                &__alertheading{
                    max-height: 60px;
                    float: left;
                    overflow: hidden;
                    font-weight: 300;
                    font-size:21px;
                    color: #bd8b13;
               
                    
                }
                &__description  {
                    color: rgb(78, 76, 74);
                    font-size: 16px;
                    font-family:$font-book;
                    font-weight: 300;
                    letter-spacing: 0px;
                    line-height: 22.4px;
                    float: left;
                    @media (min-width: 992px) { 
                        height: 65%;
                        max-height: 90px;   
                        overflow: hidden;  
                      
                     
                    }
                }
                .link .icon{
                    height: 1rem !important;
                    color: #005f96;
                }
            }           
        }       
        
    }
}