@import '../../assets/scss/abstracts/abstracts';

.mixbooking-lineitem{
    margin: 11px;
    &__add_another{
        padding: 15px !important;
    }
}

.form-grouplabel{
    background: #fff;
    margin-top: 10px;
    box-shadow: none;
    outline: none;
    padding: 0.572rem 2.358rem;
    font-family: "EtihadAltis-Bold", sans-serif;
    font-size: 18px;
    color: #4E4C4A;
    border: 1px solid #4E4C4A;
    border-radius: 6px;
    height: 83%;
}

.excel_Upload_button{
    font-family: $font-book;
    color: #005F96;
    margin: 10px 0px 10px 0px;
    cursor: pointer;
    align-items: center;
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    height: 48px;
    font-size:20px;
    @media (min-width: 992px) {
        justify-content: center;
        font-family: $font-medium;
        font-size:18px;
    }
    img {
        padding: 0px 10px 0px 0; 
        width: 34px;
        @media (min-width: 992px) {
            width: auto;
            padding: 0px 10px 0px 40px; 
        }
    }
  
}

.excel_Download_button{
    margin: 0px;
    font-family: $font-book;
    color: #005F96;
    padding: 24px 0px 0px 0px;
    cursor: pointer;
    align-items: center;
    height: 100%;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    @media (min-width: 992px) { 
        font-size: 20px;
    }
    &:hover{
        text-decoration: underline;
        color: #005F96;
    }
}

.selected-file-panel{
    display: flex;
    border: 1px solid #4E4C4A;
    border-radius: 4px;
    box-shadow: 0px 0px 6px #98BCD3;
    height: 45px;
    margin-bottom: 0px;
    .button-choose{
        background: #DCDBDB 0% 0% no-repeat padding-box;
        border-radius: 4px;
        margin-bottom: 0px;
        font-size: 14px;
        cursor: pointer;
    }
    .selected-showcase{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        overflow: hidden;
    }
}