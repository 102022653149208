@import '../../../assets/scss/abstracts/abstracts';


.shipment-tracking-panel{
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 26px;
    background-color: $white;
    align-items: left;
    justify-content: left;
    text-align: left;
    border-radius: 12px;
    .form-item {
      height: 53px;
      @media (min-width: 992px) { 
        height: auto;
      }    
    }
    button {
      height: 53px;
      margin-top: 1rem;
      @media (min-width: 992px) { 
        height: auto;
      } 
    }
    .section-sub-heading {
      font-family: $font-book;
  }
     img{
          display: block;
          margin-left: auto;
          margin-right: auto;
}
}