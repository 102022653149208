.failed-records-table {
  .failed-table {
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid #dee2e6;
    th,
    td {
      font-family: "EtihadAltis-Book", sans-serif;
      text-align: left;
      font-size: 16px;
      padding: 8px;
    }
    .view-link {
      padding: 0;
      margin: 8px;
    }

    .failed-table-header {
      background-color: #ffffff;
      tr {
        border-top: 1px solid #c2c2c2;
        border-bottom: 1px solid #c2c2c2;
        th {
          color: #4e4c4a;
          font-weight: 400;
        }
      }
    }

    .failed-table-body {
      tr {
        border-bottom: 1px solid #e4e4e4;
        td {
          border-collapse: collapse;
          word-break: break-word;
          overflow-wrap: break-word;
          white-space: normal;
          .caution-symbol {
            color: red;
          }
        }
      }
    }
  }

  .no-records {
    text-align: left;
    color: #888;
    margin: 20px 0;
  }
}
