@import '../../../../assets/scss/abstracts/abstracts';

.cargo-portal-container { 
    .table-user-list-wrapper{
        margin-top: 10px;

        .user-table-th{
            border-bottom: 1px solid #ddd;
            font-weight: normal;         
        }      
        .table__head-th,.table__body-td{
            padding: 1.4rem;   
            text-align: left;                                       
        }  

        .clickableRow{
            cursor: pointer;
        }    
        
        .m-r-l-0{
            margin: 25px 0px;
        }
    }  
    @media (min-width: 992px) {
        .column-head-width-0,
        .column-head-width-2,
        .column-body-width-name,
        .column-body-width-time {
            width: 30%;
        } 
        .column-head-width-1,
        .column-body-width-id{
            width: 20%;
        }
     }
}

