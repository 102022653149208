.seasonal-booking {
    #multiple-booking {
        .p-selectbutton {
            .p-button:not(.p-disabled):not(.p-highlight) {
                border-color: #4E4C4A;
            }

            .p-button:not(.p-disabled):not(.p-highlight):hover {
                border-color: #4E4C4A;
            }
        }

        .p-selectbutton.p-buttonset {
            .p-highlight {
                background: #262364 !important;
            }

            .p-button {
                //font-family: $font-bold;
                //font-size: $rateFont;
                //color: $black;
                //background: $white;
                //border-color: $textcolor;
                box-shadow: 0px 2px 3px #0000000D;
                width: auto;
                padding: 10.5px 13.5px;
                //border-radius: 0px 0px 0px 0px;
                border: 1px solid rgb(78, 76, 74);
                height: 50px;


            }

            .p-button.p-highlight {
                color: #ffffff !important;
            }

            .p-button.p-highlight:hover {
                color: #ffffff !important;
                border-color: #4E4C4A;
                background-color: #262364 !important;
            }

            .p-button-label {

                font-family: EtihadAltis-Bold;
                font-size: 18px;
                font-weight: bold;
                height: 30px;
                letter-spacing: 0px;
                text-align: center;

            }
        }
    }

    // .defaultFlightDate {
    //      background-color: rgb(89, 90, 91)
    // }

    .delete-div-wrapper {
        //justify-content: flex-start;
        justify-content: end;
        display: flex;
        margin-left: 0px;
        margin-top: 31px;
    }

    .add-more-flight .link-title {
        margin-left: -11px;
        color: #005F96;
    }

    a.disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
    }


}