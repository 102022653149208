.form-control {
    height: 50px;
    border: solid 1px $textcolor;
    color: $titleColor;
    font-size: 16px;

    // font-family: $font-family;
    &:focus {
        border-color: $focuscolor;
        box-shadow: none;
    }

    &:focus-within {
        border-color: $focuscolor;
        box-shadow: none;
    }
}

.red-border .form-control {
    border: solid 1px $invalid;
}

.error-msg {
    color: $invalid;
}

.warning-info .p-inline-message {
    width: 100%;
    float: left;
    display: block;
    background-color: #E8F1F4;
}

.p-dropdown-items-wrapper,
.p-dropdown .p-dropdown-panel {
    border-radius: 6px;
}

.p-autocomplete-panel {
    border-radius: 6px;
}

.p-dropdown-item,
.p-autocomplete-item {
    border-bottom: solid 1px #e4e4e4 !important;
}

.form-group {
    label {
        @media (min-width: 768px) {
            white-space: nowrap;
        }
    }

    .additional-style {
        font-family: $font-book;
        font-size: 16px;
        font-weight: 300;
    }


}

.pet-style {
    margin-bottom: 22px;
    padding-left: 15px !important;
    color: rgb(78, 76, 74) !important;
    font-family: EtihadAltis-Book !important;
    font-size: 32px !important;
    font-weight: 300;
    height: 28px;
    letter-spacing: 0px;
    line-height: 40px;
    width: 144px;
    margin-left: 4px;

}

.p-calendar .p-datepicker {
    padding: 0px 48px 0px 41px;
    margin-top: 17px !important;

    @include respond(md) {
        min-width: 300px !important;
    }
}

.p-datepicker {
    .p-datepicker-header {
        border-bottom: none !important;
    }

    &::before {
        width: 0px;
        height: 0px;
        border: 7px solid transparent;
        border-bottom-color: $grey;
        content: "";
        bottom: 0;
        position: absolute;
        left: 0;
        right: 50%;
        top: -14px;
        margin: 0 auto;
    }

    &::after {
        width: 0px;
        height: 0px;
        border: 6px solid transparent;
        border-bottom-color: $white;
        content: "";
        bottom: 0;
        position: absolute;
        left: 0;
        right: 50%;
        top: -12px;
        margin: 0 auto;
    }

    .p-datepicker-group {
        position: relative;
    }

    .p-datepicker-header .p-datepicker-prev {
        position: absolute;
        top: 50%;
        left: -6%;
        box-shadow: none !important;
    }

    .p-datepicker-header .p-datepicker-next {
        position: absolute;
        top: 50%;
        right: -9%;
        box-shadow: none !important;
    }

    .p-datepicker-header .p-datepicker-prev,
    .p-datepicker-header .p-datepicker-next {
        span {
            font-size: 1.6rem;
            color: black;
        }
    }

    .p-datepicker-title {
        color: $textcolor !important;
        font-family: $font-book !important;
        font-size: 20px !important;
    }

    table td>span {
        color: #474748 !important;
        font-family: $font-medium !important;
        font-size: 20px !important;
    }

    table th {
        padding-left: 12px;
    }

    table th>span {
        color: $textcolor !important;
        font-family: $font-book !important;
        font-size: 20px !important;
    }

    table td.p-datepicker-today>span.p-highlight {
        color: $textcolor;
        background: #DDD3BB;
    }

    table td>span.p-highlight {
        color: $textcolor;
        background: #DDD3BB;
    }
}

.p-selectbutton {
    .p-button:not(.p-disabled):not(.p-highlight) {
        border-color: #4E4C4A;
    }

    .p-button:not(.p-disabled):not(.p-highlight):hover {
        border-color: #4E4C4A;
    }
}

.p-selectbutton.p-buttonset {
    .p-highlight {
        background: #DCDBDB !important;
    }

    .p-button {
        font-family: $font-bold;
        font-size: $rateFont;
        color: $black;
        background: $white;
        border-color: $textcolor;
        box-shadow: 0px 2px 3px #0000000D;
        width: auto;
        padding: 10.5px 13.5px;

    }

    .p-button.p-highlight {
        color: $textcolor !important;
    }

    .p-button.p-highlight:hover {
        color: $textcolor !important;
        border-color: #4E4C4A;
    }
}

.p-toast {
    white-space: pre-line;
}