@import '../../assets/scss/abstracts/abstracts';
.header{
  display: flex;
  padding: rem(5) rem(30);
  justify-content: space-between;
  align-items: center;
  box-shadow: -1px 0px 6px -2px #aaa;
  background: $white;
  .d-flex {
    display: flex;
  }
  &__right{
    display: flex;
    align-items: center;
    flex-direction: column;
    .username{
      margin-left:10px;
      button{
            border-radius: 0;
    font-size: 13px;
    cursor: default;
    font-family: $font-book;
    opacity: 1;
    &:hover{
      background: $primary
    }
      }
    }
  }
  &__user{
    color: $textdark;
    font-size: rem(22);
  }
   &__signout{
    color: $textdark;
    font-size: rem(20);
    padding: 10px
  }
  &__banner{
    display: block
  }
}
.profile{
  background:$primary;
  padding: rem(10) rem(20);
  color: #fff;
  display: none;
  &__name{
    line-height: 1;
    &.has-drop-arrow{
      padding-right: 15px;
      position: relative;
      &:after{
        position: absolute;
        right: 0;
        @include chevron($color: $white, $direction: down, $size:6px);
        top:3px;
      }
    }
  }
  &__points{
    font-size: rem(18);
  }
}

.search{
  position: relative;
  margin-right: 10px;
    &__txt{
      display: none;
      position: absolute;
      right: 40px;
      border: 0;
      border-bottom: solid 1px $primary;
      width: 280px;
      height: 34px;
    }
    &__btn{
      width: rem(36);
      height: rem(36);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
    }
    &-icon{
      font-size: rem(18);
      line-height: 1;
      color: $textdark;
    }
}

.cargo-portal-container .header-comp .header-img-class-2 {
  height: 76px;
  @media (min-width: 992px) { 
      height: 100px;
  }
}
.portal-header {
  .login-section.login-text-section {
    .firstname-text {
      margin-top: 3px!important;
      padding-left: 5px;
    }
  }
  .header-comp .header-primary .header-navbar-container .header-navbar-left .header-navbar-brand .header-text-logo .header-text-logo-web {
    min-height: 128px;
    max-height: 128px;
  }
  .header-comp .header-primary .header-navbar-container {
    max-width: 1200px;
    height: 76px;
    @media (min-width: 992px) { 
        height: auto;
    }
    .header-navbar-right {
        height: 76px;
        @media (min-width: 992px) { 
            height: 100px;
        }
    }
    .header-navitem-right-icons,  .header-navbar-right {
      > li {
        height: 76px;
        @media (min-width: 992px) { 
            height: 100px;
        }
        .login-icon-button {
          .login-section {
            margin-top: 0;
            white-space: nowrap;
            .login-status-text.firstname-text {
              padding-left: 1rem;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .loggedIn-widget-open
    {
      border-bottom: 4px solid #bd8b13;
      padding-top: 4px;
    }
  
  
  .login-icon-button{
    position: relative;
    .user-profile-menu {
      background: $white;
      box-shadow: 0 4px 8px -2px rgb(9 30 66 / 25%), 0 0 1px rgb(9 30 66 / 31%);
      position: fixed;
      right: 0;
      top:75px;
      font-family: "EtihadAltis-Text", sans-serif;
      width: 100%;
      display: block !important;
      cursor: default;
      @media (min-width: 576px) { 
        position: absolute;
        right: -13px;
        top:54px;
        width: 365px;
      }
      @media (min-width: 992px) { 
        top:66px;
      }
      @media (min-width: 1200px) { 
        top:74px;
      }
      &__inner-wrapper{
        padding: 24px 24px 5px;
        position: relative;
        display: flex;
        margin-bottom: 15px;
        #welcome-wrapper h3{
          font-family: $font-book;
          color: #4e4c4a;
          line-height: 30px;
          font-weight: 500px;
        }        
        img{
          cursor: pointer;
        }
      }
      .user-name{
        font-size: 32px;
        font-family: $font-family;
        line-height: 40px;
        text-align: left;
      }
      .button-group{
        width: 100%;
        margin:8px 12px 0;
        .primary-btn{
          color: #fff;
        }
      }
      .log-out-btn {
        .link{
          font-weight: 300 !important;
          color: #005f96 !important;
          font-family: $font-book;
          .icon{
              cursor: pointer;
              margin-left: 0;
              width: 20px;
              height: 15px;
              display: inline-block;
              vertical-align: middle;           
          }
        }
      }
      .p-overlaypanel-content {
        padding: 0;
      }
    }
  }
  .agent-code-header{
    float:right;
    margin:10px 5px;
    font-size:15px;
  }
}
