@import '../../assets/scss/abstracts/abstracts';

.quote-details-page{
    min-height: auto;
    margin-bottom: rem(30);
    background: $white; 
    margin-top: 51px;
    .search-result {
        padding: 0 15px;
    }
}
.quote-details{
    height: 100%;
    //box-shadow: 0px 0px 6px -2px #aaa;  
    // padding: 0 0 rem(10);
}
.title{
    h3{
    color: $titleColor;
    font-family:$font-family;
    font-size: 22px;
  @media (min-width: 768px) { 
      font-family:$font-medium;
      font-size: $titleFont;
  }
    }
}