@import "../../assets/scss/abstracts/abstracts";

.gsauserselect-container {
  float: right;
  margin: 7px 15px -50px 0;
  font-family: $font-book;
  position: relative;
  z-index: 1;
  @media (max-width: 992px) {
    margin-top: 28px;
  }
  .gsauserselect-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: transparent;
    font-size: 18px;
    font-weight: bold;
    @media (max-width: 992px) {
      margin-bottom: 15px;
    }
    img {
      width: 18px;
      height: 18px;
      margin-left: 3px;
    }
    .gsauser-initialscircle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      font-size: 17px;
      background-color: #bd8b13;
      color: white;
      border-radius: 50%;
      text-align: center;
      margin-right: 7px;
    }
    &:focus {
      outline: none!important;
    }
  }
  .popup {
    display: none;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-out;
    box-shadow: 1px 1px 15px #00000029;
    border-radius: 10px;
    position: fixed;
    right: 0;
    top: 75px;
    width: 100%;
    display: block !important;
    background: white;
    cursor: default;
    margin-right: 2%;
    z-index: 1000;
    @media (min-width: 576px) {
      position: absolute;
      right: -15px;
      top: 60px;
      width: 365px;
    }
    @media (min-width: 992px) {
      top: 60px;
    }
    @media (min-width: 1200px) {
      top: 60px;
    }
  }

  .popup.open {
    display: block;
    height: auto;
  }
  .gsauserselect-popup-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    .error-show-area {
      display: block;
      height: 35px;
      margin: auto;
    }
    .gsauserselect-popup-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
