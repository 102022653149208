@import '../../assets/scss/abstracts/abstracts';

.admin-page{
    width: 100%;
    min-height: calc(100vh - 71px);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding:22px 16px 22px 16px;
    .label{
        font-size: 16px;
    }
    @media (min-width: 768px){  
        padding: 22px 100px 100px;
    }
    .p-component {
        font-family: inherit;
    }
    .p-button{
        color: #000 !important;
        background-color: #fff;
        border-color: #fff;
          &:hover{
            color: #000 !important;
            background-color: #fff;
            border-color: #fff;
          }
    }

    .p-tabview {
        .p-tabview-nav { 
            margin-bottom: 0;
            border:none;
           
            li.p-highlight {
                .p-tabview-nav-link {
                    background: #fff;
                    font-size: 16px;
                    letter-spacing: 0px;
                    color: #4E4C4A;;
                    border-bottom: 5px solid #BD8B13;
                    font-family:$font-medium ;
                    font-weight: medium;
                }
            }
            li { 
                .p-tabview-nav-link {
                    background: #fff;
                    font-size: 16px;
                    letter-spacing: 0px;
                    color:#4E4C4A;;
                    border: none;
                    padding: 25px 25px 26px 16px;                  
                    text-decoration: none;  
                    font-family:$font-medium ;
                    font-weight: medium;                 
                    &::before {
                        content: "";
                        width: 25px;
                        height: 20px;
                        background-color: transparent;
                        background-image: url('../../assets/images/List-view.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin-right: 4px;
                    }
                }
                &:not(.p-highlight):not(.p-disabled):hover {
                    .p-tabview-nav-link {
                        border-bottom: 5px solid #BD8B13;
                    }
                }
                .p-tabview-nav-link:not(.p-disabled):focus {
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    outline: none!important;
                }
                &.my-profile-tab {
                    .p-tabview-nav-link {
                        &::before {
                            background-image: url('../../assets/images/icon-account.svg');
                        }
                    }
                    
                }
                &.no-icon {
                    .p-tabview-nav-link {
                        font-family: $font-book;
                        font-weight: normal;
                        font-size: 16px;
                        padding: 17px 16px 17px 16px;
                        &::before {
                            content: none;
                            background-image: none;
                        }
                    }
                    &.p-tabview-selected {
                        .p-tabview-nav-link  {
                        font-family: $font-medium;
                        }
                    }
                }
            }
            .sub-user-tab{
                display:none;
            }
        }
        .p-tabview-panels {
            border: none;
            border-top: 1px solid #C7C6C6;
            padding: 0;
        }       
    }
    &.admin-tab {
        .p-tabview-nav-link {
            &::before {
                background-image: url('../../assets/images/icon-account.svg');
            }
        }
        
    }
    &__upload-panel{       
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 2rem;       
        flex-wrap: wrap;
        @media (min-width: 768px){  
            box-shadow: 0px 1px 11px #00000029;
            padding: 30px;
            border-radius: 5px;
        }
        .l-btn {
            padding: rem(5) rem(15);
            background-color: $white;
            font-size: 20px;
            line-height: 1.5;
            border-radius: 4px;
            display: inline-block;
            border: 1px solid $primary;
            letter-spacing: 0.2px;
            &--primary{
              background-color: $primary;
              color:  $white;
              font-family: $font-bold;
              padding: 12px 40px 12px 40px;
              margin: 20px 0;
              border-radius: 6px;
              width: 100%;
              @media (min-width: 992px){ 
                margin: 20px 15px;
                width: auto;
              }
              &:hover{
                background-color: $hover;
              }
              &:focus {
                outline: 0;
                // border: 1px solid #fff;
                box-shadow: 0px 7px 8px -4px rgba(189, 139, 19, 0.2), 0px 12px 17px 2px rgba(189, 139, 19, 0.14), 0px 5px 22px 4px rgba(189, 139, 19, 0.12);
              }
            }
        }
        .form-group{
            padding-left: 0px;
        }
        .form-group .form-item__field {
        cursor: pointer;
        padding-left: 0.75rem;
        padding-right: 0rem;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDAuOTk5OTk5TDYuOTk5MjggN0wxMyAxIiBzdHJva2U9IiM0RTRDNEEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
        background-repeat: no-repeat;
        background-position: center right 0.75rem;
        appearance: none;
        background-color: white;
     }

    //  .country_dropdown_wrap{
    //     cursor: pointer;
    //     padding-left: 0.75rem;
    //     padding-right: 2.5rem;
    //     background-image: none;
    //     background-repeat: no-repeat;
    //     background-position: center right 0.75rem;
    //     -webkit-appearance: none;
    //     appearance: none;
    //     border: 1px solid #4E4C4A;
    //     height: 45px;
    //     border-radius: 4px;
    //     cursor: pointer;
    //     padding-left: 0.75rem;
    //     padding-right: 2.5rem;
    //     background-repeat: no-repeat;
    //     background-position: center right 0.75rem;
    //     font-family: "EtihadAltis-Text", sans-serif;
    //     color: #4E4C4A;
    //     box-shadow: none;
    //  }
 
        .selected-file-panel{
            display: flex;
            border: 1px solid #4E4C4A;
            border-radius: 4px;
            box-shadow: 0px 0px 6px #98BCD3;
            height: 45px;
            margin-bottom: 0px;
            .button-choose{
                background: #EDEDEC 0% 0% no-repeat padding-box;
                border-radius: 4px;
                margin-bottom: 0px;
                font-size: 14px;
                width: 35%;
            }
            .selected-showcase{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 65%;
                overflow: hidden;
            }
        }
        .choose-file-button-panel{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
         }
         .choose-file-master-panel{
            display: flex;
            flex-direction: column;           
            margin: 6px 0px 0px 0px ;            
            width: 100%;
            @media (min-width: 768px){                 
                width: 330px;
            }
        }
    }
    &__download-panel{
        margin-top: 50px;
        .heading{
            text-align: left;
            letter-spacing: 0px;
            line-height: 30px;
            font-family: $font-medium;
            color: #4E4C4A;
            font-size: 20px;
            width: 100%;
            border-bottom: none;
            padding-bottom: 1.8rem;
            @media (min-width: 768px){ 
                border-bottom: 1px solid #C7C6C6;
            }
         }
         .list-container{
            border-radius: 12px;
            box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.11);
            overflow: auto;
            @media (min-width: 768px){ 
                border-radius: 0;
                box-shadow: none;
            }
         }
         .master-list-items{
             display: flex;
             align-items: center;
             border-bottom: 1px solid #C7C6C6;
             padding: 8px 20px 8px 23px;
             min-width: 650px;
             @media (min-width: 768px){ 
                min-width: auto;
             }
            
             &__first-element{
                 display: flex;
                 width: 35%;
                 p{
                    margin-bottom: 0px;
                }
             }
             &__second-element{
                display: flex;
                width: 30%;
                justify-content: flex-end;
                p{
                   margin-bottom: 0px;
               }
            }
         }
    }
    .master-alert-wrap{
        color: #3c763d;
        background-color: #dff0d8;
        border-color: #d6e9c6;
        border-radius: 5px;
    }
    .termsPdf-download-button{
        margin-bottom: 0px;
    }
}
