@import '../../assets/scss/abstracts/abstracts';

.search-summary {
    border-top: 1px solid $border;
    padding: 16px 6px 16px 6px;

    @media (min-width: 992px) {
        border-bottom: 1px solid $border;
        margin-bottom: 15px;
    }

    .col-6.col-md-2 {
        &:last-child {
            margin-bottom: 0;
        }

        margin-bottom: 0.8rem;

        @media (min-width: 992px) {
            margin-bottom: 0;
        }
    }

    .form-row {
        padding-left: 0;
        padding-right: 0;
    }

    label {
        font-size: 16px;
        font-family: $font-book;
        color: $cargoLabelColor;
    }

    .value-field {
        font-size: 20px;
        word-break: break-word;
        color: #4E4C4A;
        font-family: $font-family;

        @media (min-width: 768px) {
            font-size: 24px;
            font-family: $font-medium;
        }

        &.highlight-weight {
            color: #F68D2E
        }

        &__sub {
            line-height: 1;
            font-size: 20px;
            font-family: $font-family;
            color: #959492;
        }
    }
}

.quantity-summary {
    width: auto !important;
    ;
}