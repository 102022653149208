@import '../../../assets//scss//abstracts/abstracts';

.item-group {
    box-shadow: 0px 1px 11px #00000029;
    padding: 12.5px 16px;
    margin-bottom: 11px;
    border-radius: 12px;

    @media (min-width: 992px) {
        padding: 12.5px 8px;
    }

    .close-button {
        height: 12px;
        float: right;
        margin: 5px;
        cursor: pointer;
    }
}

.delete-div {
    justify-content: flex-end;
    display: flex;
    margin-left: auto;
    margin-top: 1px;
}

.p-selectbutton .p-button {
    width: 66px
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: white
}

.autocomplete {
    width: 100% !important;
}

.margin-negativeLeft10 {
    margin-left: -10px
}

.border-left0,
.border-left0 .p-dropdown {
    border-left: 0
}

.borderRadius-L0,
.borderRadius-L0 .p-dropdown {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    width: 60px;
}

.borderRadius-R0 {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-right: none !important;
}

.cust-dropdown {
    background: #EDEDEC;
    .p-dropdown-trigger .pi-chevron-down {
        background: #EDEDEC !important;
    }
    &#weight{
        background: #fff!important;
        .p-dropdown-trigger .pi-chevron-down {
            background: #fff !important;
        }
    }
}

.p-dropdown {
    position: relative;

    .p-dropdown-trigger {
        position: absolute;
        right: 0%;
        top: 50%;
        transform: translate(0, -50%);

        .pi-chevron-down {
            background: $white;
        }
    }
}

.uld-form {
    .weight-div {
        width: 100%;
        padding-left: 0px;
    }

    &__double_element {
        display: flex;

        input {
            width: 60%;
        }

        .dropdown_div {
            width: 30%;
        }
    }

    &__double_element.weight-div {
        input {
            width: 100%;
        }

        .dropdown_div {
            width: 60%;
        }
    }

    .delete-section {
        height: 100%;
        margin-top: 3%;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;

        span {
            font-size: rem(12)
        }

        float: right;
        padding-right: 30px;
    }

    .info-message {
        background: white;
        color: #3B3B3A;
        margin-top: 30px;
    }

    .info-icon {
        margin-top: 26px;
        padding-right: 10px;
    }

    .pivot-message {
        display: flex;
    }
}

.add-overflow-link {
    color: $link;
    background: $white;
    margin-top: 10px;
    box-shadow: none;
    outline: none;
    padding: 20px 0px;
    font-family: $font-bold;
    font-size: 18px;
    border: none;
    box-shadow: none !important;
}

.p-button.p-button-link:enabled:hover .p-button-label {
    text-decoration: none;
}

.overflow-accordian {
    padding-top: 20px;
}

#pivot-styling {
    font-size: 16px;
    font-family: $font-book;
}

.dimension-length-cage {
    max-width: 140px;
    margin-top: 31px
}

.input-weight {
    width: 100%;
}

.selectbox {
    margin-left: 4px;
    display: flex;
}

.selectboxp {
    margin: 10px 24px 4px 2px;
}

.selectbtn {
    margin: -10px 0px 0px 60px;
}

.snubnose-label {

    position: absolute;
    top: 10px;
    left: 24px;

    color: rgb(78, 76, 74) !important;
    font-family: EtihadAltis-Book !important;
    font-size: 16px !important;
    font-weight: 300;
    height: 22px;
    letter-spacing: 0px;
    line-height: 22px;
    width: 27px;
}

#snubn-code-btn {
    position: absolute;
    top: 12px;
    left: 108px !important;
}

.snub-label {


    position: absolute;
    top: 10px;
    left: 130px;

    color: rgb(78, 76, 74) !important;
    font-family: EtihadAltis-Book !important;
    font-size: 16px !important;
    font-weight: 300;
    height: 22px;
    letter-spacing: 0px;
    line-height: 22px;
    width: 27px;
}

#snuby-code-btn {
    position: absolute;
    top: 12px;
}

.optionalinput {
    width: 383px;
    padding: 0px !important;
}

.inputopt {
    cursor: auto;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    border: 1px solid rgb(78, 76, 74);
    height: 50px;
    max-width: 768px;
    padding-left: 8px;
}

.docstyle {
    font-size: 24px !important;
    padding-bottom: 10px;

}

.circle-padding {
    height: 30px !important;
    width: 30px !important;
    cursor: pointer;
    padding: 4px;
    margin-bottom: 5px;
    margin-left: 0px;


}

// .input-petweight {
//     // width: 220px !important;
//     border-right: solid 1px;
// }

.petweight-div {
    width: 160px !important;
    padding-left: 0px;
    margin-left: 15px !important;
}


.pet-weightUnit {
    margin-left: 48px;
    .p-dropdown{
        background: none !important;
    }
    .cust-dropdown .p-dropdown-trigger .pi-chevron-down {
        background: none !important;
    }
}

.checkbox-container {
    margin-left: 35px;
}

.cage-style {
    margin-bottom: 32px;
    //padding-left: 0px !important;
    color: rgb(78, 76, 74) !important;
    font-family: EtihadAltis-Book !important;
    font-size: 32px !important;
    font-weight: 300;
    height: 28px;
    letter-spacing: 0px;
    line-height: 40px;
    width: auto;
    margin-left: 2px;
}

.pet-array-data {
    margin-top: 5px;
    padding-top: 0px !important;
}

.pet-info-fields {
    width: 230px !important;
    margin-left: 0px;
    margin-right: -30px;
}

#autocomplete-breedlist {
    background: none !important;
    color: black !important;
    border: none !important;
    .p-inputtext:enabled:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-color: #005fcc;
        + .p-autocomplete-dropdown {
            border-color: #005fcc!important;
        }
    }
    
}
.form-group.red-border {
    #autocomplete-breedlist {
        .p-autocomplete-input {
            border: solid 1px #FF0000;
            border-right: 0;
        }
        .p-autocomplete-dropdown {
            border: solid 1px #FF0000;
            border-left: 0;
        }
    }
}

.blank-space {
    height: 30px;
  }

.p-autocomplete-dropdown {
    border-left: 0px !important;
    background-color: #fff !important;
    border-left: none;
    border-color: black;
    color: grey;

    &:enabled:hover {
        background-color: #fff !important;
        border-color: black;
        border-left: 0;
        color: grey;
    }
}

.p-autocomplete-input {
    border-color: black;
    border-right: 0;
}