@import '../../assets/scss/abstracts/abstracts';

.autocomplete-inner-wrapper{
    .p-inputtext{
        border: none;
        padding: 0;
    }
    .p-inputtext:enabled:focus{
    box-shadow: none;
    border-color: #fff;
    -webkit-shadow:0;
    }
    
}
.airport-autocomplete{
    .p-autocomplete-panel{
        max-height: 1200px !important;
        .similar-booking-overlay {
            box-sizing: border-box;
            //border: 1px solid $darkGrey !important;
            border: none;
            border-top: 1px solid $dropdownBorderColor !important;
            border-radius: 0px 0px 11px 11px;
            &__title{
                padding: 0.5rem 1rem;
                margin: 0;
                color: $primary;
                background: $white;
                font-size: 17px;
                font-style: italic;
                cursor: pointer;
                white-space: nowrap;
                border: 1px solid $dropdownBorderColor !important;  
            }
            &__items{
                box-sizing: border-box;
                background: $secondaryDropdownColor;
                border-radius: 0px 0px 11px 11px;
            }
            &__item{
                padding: 0.5rem 1rem;
                margin: 0;
                color: $textcolor;
                background: transparent;
                font-size: 17px;
                cursor: pointer;
                border: none;
                border-top: 1px solid $dropdownBorderColor !important;
                &:hover{
                    background: $lightGrey;
                }
                a{
                    color: inherit;
                    text-decoration: inherit;
                }
                div{
                    display: flex;
                    justify-content: space-around;
                    .label-ond{
                        font-weight: bold;
                        flex: 1 1 0;
                        white-space: nowrap;
                    }
                    .label-product{
                        flex: 2 1 0;
                        margin: 0 6px;
                        word-break: break-all;
                    }
                    .label-quantity{
                        flex: 1 1 0;
                        white-space: nowrap;
                    }
                    span{
                        padding: 0 2px;
                    }
                }
            }
        
        }
    }
}

