@import '../../../assets/scss/abstracts/abstracts';
.schedule-visualization {
    .button {
        &.next-action {
            width: 100%;
        }
        @media (max-width: 768px) { 
           width: 100%;
        }
    }
}