@import '../../../../assets/scss/abstracts/abstracts';


.route-details-booking {
    .flightDetails-mainWrapper{
        // font-size: 14px !important;
        font-family:$font-book !important;
        color: #4c4e4c;
        border: none;
        // margin: 0 0 16px;
        margin: 0 0 0px;
        //padding-bottom: 0;
        @media (min-width: 992px) {
            border: 1px solid #DDDDDD;
        }
         .flight-details__more-info h4{
            text-transform: none;
            font-size: 14px;
            color: rgb(78, 76, 74);
            font-weight: 500;     
            font-family: $font-medium; 
            opacity: 1;      
        }
        
        .flight-details__timing strong{
            font-size: 16px;
        }
         .flight-details__timing span{
            font-size: 14px;
            padding: 2px 0px;
        }
        .flight-details__flightNo{           
            font-size: 14px;
        }
        .flight-details__right{
            flex-direction: row;
            padding-top: 1.2rem;
            padding-bottom: 0;
            width: 100%;
            @media (min-width: 992px) {
                justify-content: flex-end;
                width: 50%;
            }            
        }
        .flight-details__more-info{            
            padding: 0px;
            @media (min-width: 992px) {
                padding: 0 10px;
            }
            .address-info{
                margin-bottom: 25px;
                @media (min-width: 992px) {
                    margin-bottom: 0;
                }
            }
        }
        .flight-details__right__sub__pricing{
            align-items: flex-start;
            @media (min-width: 992px) {
                align-items: flex-end;
            }
           
        }
        .flight-details__more-info .details{
            font-size: 16px;
            font-family: $font-book;   
            color:#4c4e4c; 
            letter-spacing: 0px;
            opacity: 1;
            padding-top: 8px;
            @media (min-width: 992px) {
                font-size: 14px;
            }    
        }
        .flight-details__right__sub__amount > label{
            font-size: 16x;
            font-weight: 500;
            @media (min-width: 992px) {
                font-size: 24px;
                font-weight: 400;
            } 
           
        }
        .flight-details__right__sub__rate > label {
            font-size: 14x;
            font-weight: 500;
            @media (min-width: 992px) {
                font-size: 16x;
                font-weight: 400;
            }
        }
        .p-l-stops{            
            padding-left: 0px;
            @media (min-width: 992px) {                
                padding-left: 9px;
            }
        }
     
    }
    
}
    
