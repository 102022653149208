@import '../../../assets/scss/abstracts/abstracts';
.country-code-wrapper{

    .search-wrapper-country{   
         
        .p-inputtext{
            border: none;
            padding: 0;
            width: 100%;
            color: #4c4e4a;
            font-size: 20px;    
            font-family: $font-book !important;            
        }
        .p-button:enabled:hover{
            color:#4c4e4a
        }
        .p-button:enabled:focus {
            outline: none !important;
            box-shadow: none !important;
          }
        .p-inputtext:enabled:focus{
            box-shadow: none;
            border-color: #fff;
            -webkit-shadow:0;        
        }   
       
        .p-autocomplete-panel {
            margin-top: 15px;  
            .p-autocomplete-items {
                max-height: 140px;
            }           
        }          
    } 
    
    button{
        position: absolute;
        top: 0; 
        bottom: 0;
        right: 0;
        width: 8%;
        background-color: white;
        border: none;
        box-shadow: none;       
        .pi-chevron-down{
        color: #666963;
        font-size: 16px;
        }
        &:hover{
        background-color:  white !important;
        }
    }      
}
