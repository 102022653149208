@import '../../../assets/scss/abstracts/abstracts';

.file-name {
    display: flex;
    flex-direction: column;

}

.box {
    :hover {
        // background-color: yellow;
        border-radius: 4px !important;
        background-color: #ffffff !important
    }

}

.uploadfile {
    padding: 14px;
}

.petdrop {

    &__container {

        // background-color: yellow;


        // color: $black;
        // margin: 20px auto;
        // max-width: 720px;
        // border-radius: 10px;
        // border-color: grey;
        // border-width: 2px;
        // border-style: dashed; 

        background: rgb(255, 255, 255);
        border-radius: 4px;
        border: 1px solid rgb(78, 76, 74);
        height: 40px;
        width: 236px;

        margin-left: 15px;

    }

    &__main {


        position: relative;

        p {


            color: rgb(78, 76, 74);
            font-size: 18px;
            font-family: EtihadAltis-Book;
            font-weight: 600;
            text-align: center;
            letter-spacing: 0px;
            line-height: 36px;
        }


        .excel_Upload_image {
            // background: rgb(78, 76, 74);
            height: 45px;
            width: 45px;
            position: absolute;
            //top: 90;
            left: 0;
            bottom: 0;
            right: 0;
            margin-top: 0 !important;
            // max-width: 100%; /* not to stand out from div */
            // max-height: 100%; /* not to stand out from div */
            margin: auto auto 0;
            /* position to bottom and center */

            // height: 160px;  
            // vertical-align: bottom;
            // display: table-cell;

        }



    }


}