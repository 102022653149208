
.paginationWrapper {
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #959492;
    border-radius: 4px;       
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: #DCDBDB;
}
.pi{
    font-size: 1.5rem;
    color: #4E4C4A;
}
.p-paginator {
    background: none;
    border: none;
    .p-paginator-first{
        background-color: #fff;
        // color: #777;
        color: #4E4C4A;
    }
} 

.p-paginator .p-paginator-pages .p-paginator-page {
    border: 0.5px solid #4E4C4A;
    border-radius: 4px;
    color: #4E4C4A;
    font-family: 'EtihadAltis-Medium';
    font-size: 18px;
    margin: 0.5rem;
    font-weight: 500;
    height: 40px;
    width: 40px;
  }
}
