@import '../../../../assets/scss/abstracts/abstracts';
.charge-detail-wrapper{
    &__title{
        border-bottom: 1px solid #d9d9d9;
    }
    .no-rate{
        font-size: 16px;
        border-bottom: 1px solid #d9d9d9;
        @media (min-width: 992px) { 
            font-size: 18px;
        }
    }
    .charge-details-inner-wrapper{
     
        .form-row {
            border-bottom: 1px solid #d9d9d9;           
            margin: 0;
            padding: 13px 0px 13px;
            @media (min-width: 992px) {                
                padding: 12px 2px 8px;
            }
        }
        .booking-total-cost{
            font-family: $font-book;
            @media (min-width: 992px) {                
                font-family: $font-medium;
            }
           
        }
        .total-cost-value-field{
            font-size: 20px;
            color: #BD8B13;
            border: 0;
            font-family: $font-medium;
            @media (min-width: 992px) {                
                font-size: 32px;
            }
        }
        
        .freight-wrapper{
            .freight-inner-wrapper{
                padding-right:0px;
                border:0
            }           
            .freight-per-rate-field{
                display: inline-block;               
                text-align: right;
                width: 25%;
                border: 0;
                padding-right: 0;
                font-family: $font-book;
            }
            .charge-font{
                font-size:16px;
                @media (min-width: 992px) {                
                   padding-top: 3px;
                }
            }
        }
           .rate-field{
            display: inline-block;
            text-align: right;
            border: 0;
            padding-right: 0;
        }       
        .surcharge-value-field {
            font-size: 16px;
            color: #4E4C4A;
            font-family: $font-book;
            @media (min-width: 992px) {                
                font-size: 18px;
                font-family: $font-medium;
            }
        }       
    }
    .spot-rate-wrapper{      
        padding-top: 10px;
        font-size: 16px;
        font-family: $font-book;
        &__title{
            font-family: $font-book;
            font-size: 14px;
            display: inline;
            @media (min-width: 992px) {
            font-family: $font-bold;
            font-size: 20px;
            display: inline;
            }
        }       
        &__rate{
            font-family: $font-medium;
        }
    }
    #terms-font{
        font-size:14px;
        padding: 10px 0px 0px 0px;
        @media (min-width: 992px) {                
            font-size:16px;
            padding-top: 0px;
            a{
                font-size:16px; 
            }
        }
            
            
    }
    #header-font{
        font-family:$font-book;
    }

    .terms-conditions-wrapper{
        font-size: 14px;
        font-family: $font-book;
        &__link{
            font-size: 14px;
            font-family: $font-book;
        }
    }
}
