// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------
.p-dropdown.form-control{
  display: flex;
}
.form-control .p-inputtext{
  border:0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: $font-book;
  &:enabled:focus{
    box-shadow: none;
    border: 0;
  }
}