// -----------------------------------------------------------------------------
// This file contains global styles.
// -----------------------------------------------------------------------------

html {
  font-size: 87.5%;
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

::selection {
  background-color: $primary;
  background-color: var(--primary);
  color: $white;
  color: var(--white);
}
html,
body {
  -webkit-overflow-scrolling: touch;
  background: $bgcolor;
  font-family: $font-family;
  height: 100%;
  font-size: 14px;
  color: $textcolor;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

* {
  table-layout: fixed;
}

*:focus {
  outline: 0;
}

::-ms-clear {
  display: none;
}

a{
  cursor: pointer;
}
a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

a[href^="tel"] {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}




button,
input[type="submit"],
input[type="button"],
select {
  outline: none;
  border: 0;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}
