@import '../../../assets/scss/abstracts/abstracts';

.hawb-shipment-detail-wrapper{
   
    .autocomplete {
      width: 100%;
    }
   
    .form-control {
      border: 1px solid #4e4c4a;
      height: 50px;
    }
    .input-wrapper {
      input[type=text].p-autocomplete-dd-input {
        width: calc(100% - 18px);
      }
      .p-autocomplete button {
        background: #fff;
        width: auto!important;
        line-height: 22px;
        border: none;
      }
    }
    .pi-chevron-down{
      color: #4e4c4a;
    }
    .p-autocomplete .p-inputtext{
      font-family: $font-book !important;
    }

    .form-group input[type=text], .form-group input[type=button] {
      font-size: 20px;
      color: #4e4c4a;
      width: 100%;
      font-family: $font-book;
    }
    .scc-description-hawb{
      margin-left: 15px;
      font-size: 14px;
    }
   
}