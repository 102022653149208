@import '../../assets/scss/abstracts/abstracts';

.templateListWrapper{
        background-color: #fff;
        margin-top: 51px;    
        @media (min-width: 992px) { 
            padding: 0px 0px 35px;
         }
   
    .templateFilterWrapper{   
      width: 100%;
      display: inline-block;
    }
    .page-heading {
      font-size: 24PX;
        margin: 0;      
    }

    .no-border{
        border: none;
    }
    .user-table-th{
        border-bottom: 1px solid #ddd !important;
        // font-weight: normal;         
    }      
    .table__head-th,.table__body-td{     
        padding: 1.2rem 0rem !important;
        // vertical-align: middle !important;
    }
    .searchField{     
        text-align: right;
        position: relative;
        margin-bottom: 20px;
        @media (min-width: 992px) {  
            margin-bottom: 0;
         }
        // padding: 10px 0px 30px 0px;
        span {
            width: 100%;
            @media (min-width: 992px) {     
                width: auto;
             }
        }
    }

    #btn-template-search{
        margin-left:20px;
    }
    .editTemplate{
       cursor: pointer;
    }
    .tableHeader{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #c8c8c8;
    }
    .portal-tab-menu .p-tabmenu .p-tabmenu-nav {
        .p-tabmenuitem.tab-templates {
            .p-menuitem-link {
                border: none;
                background: $white;
                color: $textcolor;
                border-bottom: 5px solid $primary;
                font-family: $font-medium
            }
        }
    }
}
@media (min-width: 992px) {
    .column-head-width-0,
    .column-head-width-2,
    .column-body-width-templateName,
    .column-body-width-time {
        width: 30%;
    } 
    .column-head-width-1,
    .column-body-width-userId{
        width: 20%;
    }
 }
.template {
    &-search{
        &_mobile {
            position: absolute;
            top: 8px;
            right: 10px;
            background: transparent;
        }
    }
    &_mobile {
        width: 100%;  
        &_wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid rgb(220, 219, 219);
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }  
    &_column {
        flex: 1;
    }
    &_row {
        margin-bottom: 1rem;
    }
    &_name-value {
        a {
            color: rgb(0, 95, 150);
            font-size: 16px;
            font-family: 'EtihadAltis-Book';
            &:hover {
                text-decoration: none;
            }
        }
    }
}