.confirmation-dialog {
    .p-dialog .p-dialog-content {
        font-family: "EtihadAltis-Book";
        font-size: 18px;
        color: #4E4C4A;
        letter-spacing: 0;
        text-align: center;
        font-weight: 300;
        padding-top: 10px;
        width: 100%;

        @media (min-width: 992px) {
            font-size: 20px;
            line-height: 42px;
        }
    }

    .p-dialog .p-dialog-content .p-dialog-content-infopet {
        line-height: 20px !important;
    }

    .p-dialog .p-dialog-header {
        border-bottom: none;
        background: white;
        padding: 1rem;

        @media (min-width: 992px) {
            padding: 2rem;
        }
    }

    /*.p-dialog .p-dialog-header .p-dialog-title{
        font-size: 28px; 
        color: #4E4C4A; 
        letter-spacing: 0; 
        text-align: center; 
        line-height: 40px;       
        font-weight: 300;
        font-family: 'EtihadAltis-Book';
        padding-top: 10px;
        width: 100%;
    }*/

    .p-dialog .p-dialog-footer {
        padding: 2rem 1rem;
        text-align: center;
        border-top: 0px;

        @media (min-width: 992px) {
            padding: 2rem 5rem 5rem 5rem;
        }
    }

    .p-dialog .p-dialog-footer button {
        margin-left: 10px;
        margin-right: 10px;
    }

    #confirmButton.button:hover {
        background-color: #976f0f;
        border: 1px solid #976f0f;
        text-decoration: none;
        color: #fff;
    }

    #cancelButton.button--secondary:hover {
        background-color: #4e4c4a;
        color: #fff;
        border: 1px solid #fff;
        text-decoration: none;
    }


}