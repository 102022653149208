@import '../../assets/scss/abstracts/abstracts';
.cargo-portal-container {
  .dashboard-container {
    background: $white;
    min-height: 80vw;
    // max-width: 1370px;
    padding-top: 50px;
    .dashboard-component {
      background: $white;
      border-radius: 12px;
      box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.11);
      height: 100%;
    }
  }
  @media (min-width: 1400px){
    .dashboard-container {
      width: 1370px;
    }
  }
  .empty-records{
    display: flex;
    flex-wrap: nowrap;   
    font-family: "EtihadAltis-Medium", sans-serif; 
    padding: 9px 10px;
    margin: 5px 15px;
    font-size: 14px;
    justify-content: flex-start;
  }
  &.dashboard ~ .cargo-portal-container {
    .cargo-portal-footer {
      @media (min-width: 1400px){
        .container.footer-container {
            width: 1370px;
            max-width: 1370px;
        }
      }
    }
  }
}