.cargo-portal-container a {
    color: #4e4c4a;
    font-size: 16px;
}
.cargo-portal-container a:focus, .cargo-portal-container a:hover {
    color: #4e4c4a;
    text-decoration: underline;
}
a.awb-text{
     color: rgb(0, 95, 150);
    font-size: 20px;
}
@media (min-width: 992px){
.cargo-portal-container .col-md-6 {
    max-width: 50%;
}
}