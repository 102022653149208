@import '../../../assets/scss/abstracts/abstracts';

.cargo-portal-container {
    .update-user-panel {
        background-color: #fff;
        .form-group {
            .cust-dropdown {
                background: #fff;
            }           
            .p-dropdown {
                .p-dropdown-label {
                    padding-top: 6px;
                    padding-left: 5px;
                }
                .p-dropdown-trigger{
                    right:0;
                    .pi-chevron-down {
                        background: #fff!important;
                    }
                }
            } 
        }

        .userLastLogin{
            text-align: right;
            font-weight: 400;
            font-size: 20px;
            color: #4e4c4a;
            width: 100%;
            font-family: $font-book;           
        }
        .checkBoxLabel{
            text-align: left;
            font-size: 20px;
            letter-spacing: 0px;
            color: #4E4C4A;
            opacity: 1;
            padding-top: 3px;
            padding-left: 3px;
        }
       
        #edit-user {
            margin-top: 35px;
            font-size: 18px;
            line-height: 18px;
            padding-left: 18px;
            float: right;
            margin-right: 10px;
            .edit-icon {
                width: 18px;
                height: 18px;
                display: inline-block;
                margin-right: 10px;
            }
        }

        
    }   
}
