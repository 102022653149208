.hawbpopup-footer-btns {
  display: flex;
  justify-content: space-evenly;
}

.processing-status {
  font-size: 16px;
  padding-left: 10px;
  .icon-loading-inline {
    width: 60px;
  }
}

.hawbpopup-input-session {
  margin: 20px 0;
  border: 2px dashed #ccc;
  border-radius: 10px;
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: "#f9f9f9";

  .file-input-section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
  }
}

.hawbpopup-exceldownload {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 41%;
  justify-content: space-between;
  margin: auto;
  .downld_temp {
    color: rgb(0, 95, 150);
  }
  img {
    width: 30px;
  }
}
.hawb-bulk-upload {
  .error-show-area {
    margin: 0 0 12px 0;
    width: 100%;
    height: 22px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.bulk-upload-status {
  display: inline;
  font-size: 18px;
  .saved-count {
    color: #005f96;
  }
  .failed-count {
    background-color: rgba(210, 50, 31, 1);
    color: #fff;
    padding: 2px 10px 2px 10px;
    margin-left: 10px;
    border-radius: 5px;
  }
  button.failed-records {
    display: inline !important;
  }
}

.hawbpopup-warning-popup {
  .p-dialog-header {
    display: flex;
    justify-content: center;
  }
}
