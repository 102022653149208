@import '../../../assets/scss/abstracts/abstracts';

.chart-component {
    padding: 23px 12px;
    height: 100%;
    min-height: 400px;
    .chart-wrapper {
      min-height: 400px;
      padding: 0 11px;
    }
    .chart-wrapper-bar {
        height: calc(100% - 132px);
        text-align: center;
        margin-top: 90px;
        .chart-row {
            height: calc(100% - 68px);
            width: auto;
            margin: auto;
            display: inline-block;
            border-bottom: 1px solid $dropdownBorderColor;
            margin-bottom: 24px;
        }
        .vertical-bar {
            width: 24px;
            height: 100%;
            display: inline-block;
            vertical-align: bottom;
            position: relative;
            .bar-value {
                position: absolute;
                font-family: "EtihadAltis-Book", sans-serif;
                font-size: 16px;
                font-weight: 300;
                letter-spacing: 0px;
                line-height: 22px;
                display: inline-block;
                width: 68px;
                //word-break: break-all;
                @media (min-width: 1400px){
                   width: 82px;
                }
            }
            .bar {
                height: 100%;
                width: 24px;
                border-top-left-radius: 24px;
                border-top-right-radius: 24px;
                display: inline-block;
                transition: height 1s;
                position: absolute;
                bottom: 0;
                .bar-value {
                    position: relative;
                    font-family: "EtihadAltis-Book", sans-serif;
                    font-size: 16px;
                    font-weight: 300;
                    letter-spacing: 0px;
                    line-height: 22px;
                    display: inline-block;
                    width: auto;
                }
            }
            &.first {
                margin: 0 25px 0 25px;
                .bar {
                    background: $falconGrey;
                    .bar-value {
                        right: 60px;
                        bottom: 40px;
                    }
                }
            }
            &.last {
                margin: 0 45px 0 25px;
                .bar {
                    background: $etihadgold2;
                    .bar-value {
                        left: 32px;
                        bottom: 44px;
                    }
                }
            }
        }
        .chart-legends {
            display: inline-block;
            width: 100%;
            margin: auto;
            padding: 0 10px 20px;
            .legend {
                display: inline-block;
                margin-right: 0px;
                font-family: $font-book;
                font-size: 16px;
                font-weight: 300;
                letter-spacing: 0px;
                line-height: 22px;
                width: 48%;
                //white-space: nowrap;
                &::before {
                    content: '';
                    background: $etihadgold2;
                    width: 24px;
                    height: 24px;
                    border-radius: 24px;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 4px;
                }
                &.first {
                    &:before {
                        background: $falconGrey;
                    }
                }
                &.last {
                    padding-left: 8px;
                }
            }
        }
    }
}