@import '../../../assets/scss/abstracts/abstracts';
.schedule-visualization {
    .flight-data-upload{
        .description {
            height: auto;
            p {
                margin-left: 0;
            }
        }
        .dropzone__container {
            width: auto;
            margin: auto;
        }
        .files-list {
            h4 {
                margin-top: 48px;
            }
            .filename-upload {
                margin-top: 28px;
                font-size: 16px;
                &:before {
                    content: '';
                    width: 18px;
                    height: 24px;
                    background-image: url('../../../assets/images/icon-attachment.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    display: inline-block;
                    margin-right: 13px;
                    vertical-align: middle;
                
                }
            }
            .btn-delete-file {
                background: none;
                font-size: 16px;
            }
        }
       
    }
}