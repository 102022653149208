@import '../../../assets/scss/abstracts/abstracts';

.allotment-details {
  background: $white;
  border-radius: 12px;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.11);
  padding: 25px 0 10px 0;
  margin: 24px 0;
  @media (min-width: 992px) {
    padding: 25px 15px;
  }
  .alloment-stats-type {
    .form-item__label {
      white-space: nowrap;
    }
  }
  .section-sub-heading {
    padding: 0 24px;
    margin-bottom: 0;
    @media (min-width: 992px) {
      padding: 0;
      margin-bottom: 24px;
    }
  }
  .allotment-header_mobile {
    font-size: 16px;
    font-family: 'EtihadAltis-Medium';
  }
  .allotment-stats-summary {
    padding: 0 24px;
    @media (min-width: 992px) {
      padding-left: 9px;
    }
  }
.alloment-stats-week {
  margin: 22px 0;
  @media (min-width: 992px) {
    margin: 0;
  }
}
.allotment-table {
  .allotment-row_mobile {
    border-bottom: 1px solid rgb(220, 219, 219);
    padding: 24px;
    @media (min-width: 992px) {
      padding: 0;
      border: 0;
    }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}
  .p-progressbar {
    border-radius: 8px;
    height: 18px;
  }
  .p-progressbar-determinate .p-progressbar-label {
    display: none;
  }
  .progressbar-label {
    font-family: $font-medium;
    color: $textcolor;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 22px;
    margin-top: 8px;
    &.percent {
      float: right;
    }
  }
  @media (min-width: 1200px){
    .p-progressbar {
      margin-right: 78px;
    }
    .progressbar-label {
      &.percent {
        margin-right: 78px;
      }
    }
    .radio-btn-group {
      float: right;
      margin-right: 53px;
    }
  }
  

  .table-header:before {
    @media (min-width: 992px) {
      content: '';
          height: 1px;
          background: $dropdownBorderColor;
          display: block;
          width: 240%;
          margin-left: -35px;
          margin-top: 0;
    }
  }
  .table-header.allotment-row:before {
    content: '';
        height: 1px;
        background: $dropdownBorderColor;
        display: block;
        width: 240%;
        margin-left: 0px;
        margin-top: 0;
  }
  .table-header div, .table-header th {
    font-family: $font-medium;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 22px;
    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      border-right: 1px solid $dropdownBorderColor;
    }
  }

  .table-row {
    //height: 57px;
    &:after {
      @media (min-width: 992px) {
      content: '';
      width: 100%;
      height: 1px;
      background: $dropdownBorderColor;
      display: block;
      margin-right: -18px;
      margin-top: -0.5px;
    }
  }
  }
  .table__body-td {
    margin-bottom: 1rem;
    @media (min-width: 992px) { 
      margin-bottom: 0;
    }
  }
  .table-row div, .table-row td {
    font-family: $font-book;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 22px;
    border-bottom: none;
    padding: 0 1.2rem;
    @media (min-width: 992px) { 
      padding: 1.2rem 1.2rem 1.2rem 0;
    }
    &:first-child {
      @media (min-width: 992px) { 
        padding-left: 24px;
      }
    }
    &:last-child {
      @media (min-width: 992px) { 
        border-right: 1px solid $dropdownBorderColor;
      }
    }
  }

  .calendar-wrapper {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0px;
    .previous-btn, .next-btn {
      position: absolute;
      width: 15px;
      background: $white;
      top: 4px;
      z-index: 10000;
      padding: 0;
      @media (min-width: 992px) { 
        padding: 1px 6px;
        width: 38px;
        height: 50px;
      }
    }
    .previous-btn {
      left: -3px;
      @media (min-width: 992px) {
        left: -1px;
      }
      &:focus {
        outline: none!important;
      }
    }
    .next-btn {
      right: -3px;
      @media (min-width: 992px) {
        right: 0px;
      }
      &:focus {
        outline: none!important;
      }
    }
  }
  
  .calendar-data	{
    background: $white;
    box-sizing: border-box;
    padding: 0 1px;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    .calendar-row {
      border-bottom: none;
      @media (min-width: 992px) {
        padding: 0 24px 0 32px;
      }
      &:after {
        @media (min-width: 992px) { 
        content: '';
        width: 100%;
        height: 1px;
        background: $dropdownBorderColor;
        display: block;
        width: 250%;
        margin-left: -35px;
        margin-top: -0.6px;
      }
    }
    }
    .calendar-col {
      display: inline-block;
      height: 100%;
      box-sizing: border-box;
      text-align: center;
      width: 33.33%;
      border-bottom: none;
      padding: 0;
      @media (min-width: 992px) { 
        width: 14.5%;
        padding: 1.2rem 1.2rem 1.2rem 0;
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border-right: none;
        @media (min-width: 992px) { 
        padding-right: 1.2rem;
        }
      }
      .active {
        visibility: visible;
      }
      .inactive {
        visibility: hidden;
      }
    }
  }
  .val-green {
    color: $green;
    .p-progressbar-value {
      background-color: $green;
    }
  }
  .val-grey {
    color: $charcoalGrey;
    .p-progressbar-value {
      background-color: $charcoalGrey;
    }
  }
  .val-yellow {
    color: $yellow;
    .p-progressbar-value {
      background-color: $yellow;
    }
  }
  .val-red {
    color: $red;
    .p-progressbar-value {
      background-color: $red;
    }
  }
}