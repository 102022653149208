@import '../../assets/scss/abstracts/abstracts';


.page-heading-div{
    h1{
    font-size: 24px !important;
    font-family: "EtihadAltis-Medium", sans-serif;
    color: #0F0F41;
    margin-left: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
}

}
.p-tabview {
   
    .p-tabview-nav {
     
        margin-bottom: 0;
        border: none;
       
        li.p-highlight {
            .p-tabview-nav-link {
              
                background: #fff;
                font-size: 16px;
                letter-spacing: 0px;
                color: $textcolor;
                ;
                border-bottom: 5px solid #BD8B13;
                font-family: $font-medium;
                font-weight: medium;
            }
        }

        li {
            

            .p-tabview-nav-link {
              
                background: #fff;
                font-size: 16px;
                letter-spacing: 0px;
                color: $textcolor;
                ;
                border: none;
                padding: 25px 25px 26px 16px;
                text-decoration: none;
                font-family: $font-medium;
                font-weight: medium;

                &:first-child {
                    padding-left: 0;
                    padding-right: 0;
                    margin-right: 25px;

                    @media (min-width: 992px) {
                        padding-left: 16px;
                        padding-right: 25px;
                        margin-right: 0;
                    }
                }
            }

            &:not(.p-highlight):not(.p-disabled):hover {
                .p-tabview-nav-link {
                    border-bottom: 5px solid #BD8B13;
                }
            }

            .p-tabview-nav-link:not(.p-disabled):focus {
              
                -webkit-box-shadow: none;
                box-shadow: none;
                outline: none !important;
            }

            &.my-profile-tab {
                .p-tabview-nav-link {
                    &::before {
                        background-image: url('../../assets/images/icon-account.svg');
                    }
                }

            }

            &.no-icon {
                .p-tabview-nav-link {
                    font-family: $font-book;
                    font-weight: normal;
                    font-size: 16px;
                    padding: 17px 16px 17px 16px;

                    &::before {
                        content: none;
                        background-image: none;
                    }
                }

                &.p-tabview-selected {
                    .p-tabview-nav-link {
                        font-family: $font-medium;
                    }
                }
            }
        }

        .sub-user-tab {
            display: none;
        }
    }

    .p-tabview-panels {
        border: none;
        border-top: 1px solid #C7C6C6;
        padding: 0;
    }
    .data-table-empty {
        font-family: $font-medium;
        text-align: center;
        margin: 15px 0;
        font-size: 14px;
        border: 1px solid #d9d9d9;
        padding: 9px 10px;
    }
}

.cargo-portal-container {
    .csn-table {
        mt-5{
           
            margin-top: opx;
        }
        &.hawb-subtable {
            //margin-left: 16px !important;
            padding-left: 0px;

            .p-datatable {
                th {
                    padding: 0;
                    border: 0;
                }

                .p-datatable-tbody {
                    td {
                        border: 0;
                    }
                }
            }
        }
    }

    .csn-table {
        color: $textcolor;
        position: relative;
        .p-datatable {
            th.awbNum {
                width: 10.5%;
            }

            th.awbAckStatus {
                width: 14%;
            }

            th.awbOrigin {
                width: 6%;
            }

            th.awbDest {
                width: 6.5%;
            }

            th.awbBookingStatus{
                width: 11%;
            }

            th.awbCustomStatus{
                width: 14.5%;
            }

            th.fmaFnaStatus{
                width: 12.5%;
            }

            th.hsCodeStatus{
                width: 10.5%;
            }

            th.awbExpander {
                width: 3%;
            }
            th.notifStatus {
                width: 11%;
            }

            th,
            th.p-highlight {
                padding: 10px;
                background: #fff;
                color: $textcolor;
                font-size: 16px;
                font-weight: 700;
                font-family: "EtihadAltis-Book", sans-serif;
                border: 0;
                border-bottom: 1px solid #ddd;

                &:hover {
                    background: #fff;
                    color: $textcolor;

                    .p-sortable-column-icon {
                        color: $textcolor;
                    }
                }
            }

            .p-datatable-tbody {
                td {
                    padding: 10px;
                    border: 0;
                    border-top: 1px solid #dcdbdb;
                    font-family: "EtihadAltis-Book", sans-serif;
                    font-size: 16px;
                    line-height: 1.42857143;
                    &.awbExpander {
                        text-align: right;
                        padding: 10px 6px 10px 2px;
                    }
                }
            }
            
        }
        &.awb-status-table {
            .p-datatable {
                tr:not(.awb-row) {
                    td:first-child {
                        padding-left: 0;
                        padding-right: 0;
                    }
                    td.hawbNum {
                        padding: 10px 10px 10px 18px;
                    }
                }
            }
            
        }
    }
}

.last_updated_span{
    margin-top: 0px;
    margin-left: 65%;
    position: absolute;
    font-size: 16px;
    font-family: "EtihadAltis-Text", sans-serif;
    padding-bottom: 0px;
}
   


.booking-heading{
    margin-bottom: 24px !important;
    padding-bottom: 0px !important;
}

.get-quote{
    margin-top: 48px !important;
}
.csn-awb{
    pointer-events: none;
}
.refresh-icon{
    margin-left: 10px;
    cursor: pointer;
   
}
.csn-filter {
    &.filter-link {
        border: 1px solid #ddd;
        padding: 5px 15px 5px 5px;
        &.active {
            background: #BD8B13;
            color: #fff;
        }
    }
}