@import '../../assets/scss/abstracts/abstracts';
.fasttrack.border-expand{
                border-color: #BD8B13;
                border-radius: 4px;
               .parent-line{
               box-shadow: 0px 3px 6px #00000029;
               }
            }
.fasttrack{
            border: 1px solid #BD8B13;
        border-radius: 4px;
        &__flightNo{
            letter-spacing: -0.12px;
color: $black;
font-size: 21px;
text-transform: uppercase;
font-family: $font-medium;
opacity: 0.8;
        }
    .flight-details
    {

      &__list{
          padding: 5px 10px 10px 10px;
      }
      &__close{
              text-align: right;
    width: 10%;
    margin-top: -40px;
    font-size: 17px
      }
      &__arrowRight{
          display: flex;
    flex-direction: column;
    margin-left: 20px;
    line-height: 1;
    cursor: pointer;
          //color: #005F96;
    font-size: 19px;
      }

    }
    .parent-line{
        padding: 0;
     margin-top: 0;
     box-shadow: 0px 3px 6px #00000029;
                
    .flight-details
    {
        &__left{
               justify-content: flex-start;
                   padding-left: 15px;
                   width: 40%;
                           .logo{
                                   width: 25%;
      img{
        height: 37px;
        width: 86px;
      }
    }
        }
        &__no-rate{
            font-size: 16px;
        }
        &__right{
                background: #E8F1F4;
                    padding: 14px;
                    width: 60%;
               &__message{
                letter-spacing: -0.09px;
                padding-left: 36px;
                width: 90%;
color: #4E4C4A;
font-family: $font-book;
font-size: 20px;
            }
        }
        &__price{
            &__service{
                width: 40%;
            }
            &__sub{ 
                     width: 50%;
                        display: flex;
    padding: 0 5px;
    align-items: center;
    justify-content: space-between;
    padding-left: 46px;
    
                &__pricing{
                   
    width: 100%;
    font-family: $font-medium;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
                }
                &__amount{
                        line-height: 1;
                }
                &__amount > label{
                        font-size: 18px;
                        font-family: $font-medium;
                        margin-bottom: 0;
                        color: $primary;
            }
            &__rate > label{
               font-size: 18px;
               letter-spacing:0px;
               font-family: $font-medium;
               color: $primary;
            }
         
            }
        }

    }
    }
}