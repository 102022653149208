
@import '../../../assets/scss/abstracts/abstracts';
.doughnet-chart{
  padding: 24px 10px 10px 24px ;  
    .chart-main-wrapper{
      //position: relative;      
      width:100%; 
      height:auto !important;
      margin-top: 1rem;
      flex-direction: column-reverse;
      align-items: center;
      @media (min-width: 992px) { 
        flex-direction: row;
        align-items: flex-start;
        margin-top: 0;
      }
    }

    @media (min-width: 1024px) {
    .chart-container{
      // position: relative;   
      // position: absolute;
      // right: 0px;
      // top: 0px;
     
    }
  }
    &.product-chart {
    @media (min-width: 1024px) {
      .chart-container{
        padding-top: 10px;
      }
      .legend-wrapper{
        padding-top:30px;  
     }
   }
  }
   &.service-chart {
    @media (min-width: 1024px) {
      .legend-wrapper{
         padding-top:50px;  
         margin-top: 1rem;
         @media (min-width: 768px) { 
           margin-top: 0;
         }
      }
     }
   }
   .legend-wrapper{
   padding-right: 10px;
      ul {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        // li:before{
        //   display: list-item;
        //   list-style-type: disc;
        // }
        li{         
          display: flex;
          font-family: $font-book;
          font-size: 16px;
          padding-bottom: 23px;
          &:last-child {
            padding-bottom: 14px;
            @media (min-width: 768px) { 
              padding-bottom: 23px;
            }
          }
         
          p{
            width: calc(100% - 40px);
            margin: 0;
            padding: 0;
            font-weight: 300;
            line-height: 23px;
          }
          span{
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right:8px;
            border-radius: 100%;
          }
        }
      }
    }

}
.chart-container {position: relative; display: flex;align-items: center;}

.donut-val {position: absolute;left: 5px;right: 0;text-align: center;}