@import '../../assets/scss/abstracts/abstracts';

.custom-autocomplete-wrapper{
    position: relative;
    .input-text-wrapper{
        width: 250px;
        font-family:$font-book;

    }
    .arrow-wrapper{
        cursor: pointer;
        position:relative;
        // left:11px;
        width: 100%;
    }
    .custom-overlay-panel{
        position: absolute;
        width: 100%;
        @media (min-width: 768px) { 
         margin-bottom: 0;               
        }
        .p-overlaypanel{
            position: relative;
            // width: 360px;
            border-radius: 4px;
            margin-top: 5px;
            // margin-left: 10px;
            // max-height: 250px;
            left:0px !important;
            top: 0px !important;
            transform-origin: center bottom;
            box-sizing: border-box;
            overflow: visible;
            border: 1px solid #707070 !important;
        
                    
            &::before{
                width: 0px;
                height: 0px;
                border: 7px solid transparent;
                border-bottom-color: $grey;
                content: "";
                bottom: 0;
                position: absolute;
                left: 0;
                right: 50%;
                top: -14px;
                margin: 0 auto;
            }
            &::after{
                width: 0px;
                height: 0px;
                border: 6px solid transparent;
                border-bottom-color: $white;
                content: "";
                bottom: 0;
                position: absolute;
                left: 0;
                right: 50%;
                top: -12px;
                margin: 0 auto;
            }
        }
        
        .p-overlaypanel-content{
            padding: 0 !important;
        }
        .p-tabview-panels{
            padding: 0 !important;
        }
        .p-tabview {
            .p-tabview-nav {     
                    justify-content: space-between;
                li.p-highlight {                
                    .p-tabview-nav-link {                    
                        background: #fff !important;   
                        text-decoration: none;                
                        color:#4E4C4A;
                        font-weight: 400;
                        font-size: 16px; 
                        border: none;
                        font-family: $font-book;
                        border-bottom: 2px solid #4E4C4A;
                    }
                }
                li { 
                    margin-right: 10px;
                    .p-tabview-nav-link {
                        border: none;
                        background: #fff;
                        color: #4E4C4A;
                        font-weight: 400;
                        font-size: 16px; 
                        font-family: $font-book;                    
                    }
                    &:not(.p-highlight):not(.p-disabled):hover {
                        .p-tabview-nav-link {
                            border-bottom: 2px solid #4E4C4A;
                            background: #fff;
                            //border-color: #fff;
                        }
                    }
                    .p-tabview-nav-link:not(.p-disabled):focus {
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        outline: none!important;
                    }
                }  
            }
        }
        
        .list-box{
            overflow-y: auto;
            max-height: 210px;
            text-align: left;
            font-size: 16px;
            font-family: 'EtihadAltis-Book';
            font-weight: 400;
            .empty-list-wrapper{
                padding: 0.6rem 1rem;
             }
        }
        .list-item{
            cursor: pointer;
            padding: 0.6rem 1rem;
            white-space: normal;
            word-wrap:break-word;;
            position: relative;
            overflow: hidden;
            border-bottom: solid 1px #e4e4e4 !important;
            &:hover {
                color: $black;
                background-color: $bgcolor !important;
            }
        }
    
    }   
}
.seasonal-booking-custom_style{
    margin: 0 !important;
}
