@import '../../assets/scss/abstracts/abstracts';
.file-name{
    display: flex;
    flex-direction: column;

}
.box{
    :hover {
        // background-color: yellow;
      border-radius: 12px;
      background-color: $falconGrey;
    }
    
}
.dropzone {
    
    &__container {
        
           // background-color: yellow;
          
       
        // color: $black;
        // margin: 20px auto;
        // max-width: 720px;
        // border-radius: 10px;
        // border-color: grey;
        // border-width: 2px;
        // border-style: dashed; 
        
        width: 680px;
        height: 366px;
        border: 1px dashed rgb(78, 76, 74);
        border-radius: 12px;
        position: inside;
      //  opacity: 100%;
        
        margin-left: 10px;
        
    }
    &__main {
        
       
        min-height: 200px;
        position: relative;
        p {
             position: absolute;
            left: 50%;
            top: 110%;
            text-align: bottom;
            transform: translate(-50%,-50%);
            width: 50%; 
            width: 312px;
           

            height: 28px;
            color: rgb(78, 76, 74);
            font-size: 20px;
            font-family: EtihadAltis-Book;
            font-weight: 300;
            text-align: center;
            letter-spacing: 0px;
            line-height: 28px;
        }
        .excel_Upload_image{
           // background: rgb(78, 76, 74);
            height: 45px;
            width: 72px;
            position: absolute;
             //top: 90;
            left: 0;
            bottom: 0;
            right: 0;
            margin-top: 0 !important;
            // max-width: 100%; /* not to stand out from div */
            // max-height: 100%; /* not to stand out from div */
            margin: auto auto 0; /* position to bottom and center */

           // height: 160px;  
            // vertical-align: bottom;
            // display: table-cell;
        
        }
        
    }
    

}